import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import SPImage from "./SPImage";
import TitleTextCtaImage from "./TitleTextCtaImage";

const Relocate = ({ image1, image2, image3, title, cta, htmlText }) => (
  <div className="md:pb-24 bg-purple-light">
    <Container>
      <TitleTextCtaImage
        title={title}
        htmlText={htmlText}
        image={image1}
        cta={cta}
        layout={{
          imagePositionX: "right",
          imagePositionY: "top",
          imageOrientation: "vertical",
          imageSize: "normal",
        }}
        style={{
          isFirstTitle: false,
          titleSize: "big",
          textColor: "purple",
          cta: {
            backgroundColor: "red",
            titleColor: "white",
          },
          wrappers: {
            title: "pb-16",
            htmlText: "pb-16",
            image: "pb-16 md:pb-0",
            cta: "pb-16",
          },
        }}
      />
      <Grid className="grid w-full mt-16 md:mt-24">
        <div
          className="row-start-2 row-end-3 full-columns md:col-start-1 md:col-end-8 md:row-start-1 md:row-end-6"
          data-hax
        >
          <SPImage className="w-full" data-bax="-80, 80" {...image3} />
        </div>
        <div
          className="row-start-1 row-end-2 col-start-4 col-end-13 md:col-start-7 md:col-end-13 md:row-start-5 md:row-end-9 pb-6"
          data-hax
        >
          <SPImage data-bax="-80, 80" {...image2} />
        </div>
      </Grid>
    </Container>
  </div>
);

Relocate.propTypes = {
  image1: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  image2: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  image3: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }).isRequired,
};

Relocate.defaultProps = {};

export default Relocate;
