import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import CtaWithArrows from "./CtaWithArrows";
import SPImage from "./SPImage";
import BenefitsItem from "./BenefitsItem";
import TitleTextCtaImage from "./TitleTextCtaImage";

const Benefits = ({ image, title, cta, htmlText, advantages }) => {
  return (
    <div className="bg-purple">
      <Container>
        <TitleTextCtaImage
          title={title}
          htmlText={htmlText}
          image={image}
          cta={cta}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "horizontal",
            imageSize: "normal",
          }}
          style={{
            titleSize: "medium",
            textColor: "white",
            cta: {
              backgroundColor: "blue-light",
              titleColor: "purple",
            },
            wrappers: {
              title: "pb-12",
              image: "hidden md:block",
            },
          }}
        />
        <div data-hax>
          <Grid className="grid md:grid-md w-full mt-16 md:mt-24 md:grid-none">
            {advantages.items.map((item, index) => {
              return (
                <BenefitsItem
                  key={index}
                  layout="small"
                  item={item}
                  index={index}
                />
              );
            })}
            <div
              className="full-columns md:row-start-3 md:row-end-6 md:col-start-6 md:col-end-12"
              data-hax
            >
              <h3 className="mt-8 mb-12 md:mb-6 text-small-big font-ttNorms text-white font-bold leading-tight">
                {advantages.highlight.text}
              </h3>
              <CtaWithArrows
                title={advantages.highlight.cta.title}
                color="green"
                icon={advantages.highlight.cta.icon}
                hrefTo={advantages.highlight.cta.hrefTo}
                className="text-base-big"
              />
            </div>
          </Grid>
        </div>
        <SPImage className="md:hidden mt-12" src={image.src} alt={image.alt} />
      </Container>
    </div>
  );
};

Benefits.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string,
    hrefTo: PropTypes.string,
  }).isRequired,
  htmlText: PropTypes.string.isRequired,
  advantages: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }).isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    highlight: PropTypes.shape({
      text: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }).isRequired,
        hrefTo: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

Benefits.defaultProps = {};

export default Benefits;
