import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import htmlStyle from "../lib/htmlStyle";
import validateEmail from "../lib/validateEmail";

const Form = ({ fields, htmlText, cta, color, endpoint }) => {
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState({});
  const [ctaTitle, setCtaTitle] = useState(cta.title);

  useEffect(() => {
    fields.forEach((field) => {
      formState[field.label.toLowerCase()] = "";
      errors[field.label.toLowerCase()] = false;
    });
  }, []);

  const validate = (name, value) => {
    switch (fields.find((field) => field.label.toLowerCase() === name).type) {
      case "email":
        errors[name] = !validateEmail(value) || value === "";
        break;
      case "text":
        errors[name] = value === "" && true;
        break;
    }
    if (!errors[name]) delete errors[name];
    setErrors({ ...errors });
  };

  const inputCallback = (name, value) => {
    name = name.toLowerCase();
    formState[name] = value;
    setFormState({ ...formState });
    validate(name, value);
  };

  const submit = async (e) => {
    e.preventDefault();
    setCtaTitle(cta.loading);

    if (Object.keys(errors).length > 0) {
      Object.keys(formState).forEach((field) => {
        validate(field, formState[field]);
      });
      setCtaTitle(cta.resultError);
      return;
    }

    const options = {
      body: JSON.stringify(formState),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const request = await fetch(endpoint, options);
      const result = await request.json();
      if (result.statusCode === 200) {
        setCtaTitle(cta.resultSuccess);
      } else {
        throw result;
      }
    } catch (err) {
      if (err.statusCode === 500) {
        setCtaTitle(err.message);
        return;
      }
      setCtaTitle(cta.resultError);
    }
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="flex flex-col md:flex-row w-full pt-12 md:pt-24">
          {fields.map((field, index) => (
            <div className="w-full pt-12 md:pt-0 md:pr-10" key={index}>
              <Input
                {...field}
                name={field.label}
                color={color}
                onChange={inputCallback}
                hasError={errors[field.label]}
              />
            </div>
          ))}
        </div>
        <div
          className="pt-16"
          dangerouslySetInnerHTML={htmlStyle(htmlText, color)}
        />
        <div data-hax className="pt-16">
          <button
            type="submit"
            className={`
            border-2 border-transparent font-bold inline-block text-base-big cursor-pointer transition-all md:px-16 rounded font-ttNorms transition-all px-12
             ${
               cta.color === "red"
                 ? "text-white bg-red border-solid py-4 hover:text-red hover:bg-transparent hover:border-red"
                 : "text-white bg-green border-solid py-4 hover:text-green hover:bg-transparent hover:border-green"
             }
            `}
          >
            {ctaTitle}
          </button>
        </div>
      </form>
    </>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["email", "text"]),
    })
  ),
  cta: PropTypes.shape({
    color: PropTypes.string,
    title: PropTypes.string,
    resultSuccess: PropTypes.string.isRequired,
    resultError: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Form.defaultProps = {
  cta: {
    color: "red",
    title: "Submit",
  },
};

export default Form;
