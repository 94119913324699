import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import htmlStyle from "../lib/htmlStyle";
import Button from "./Button";
import SPImage from "./SPImage";
import Title2 from "./Title2";

const LifeAtSocialpointSimple = ({ image1, image2, cta, title, htmlText }) => (
  <div className="blue-light">
    <Container>
      <Grid className="grid">
        <div className="full-columns mb-10 md:mb-12 md:row-start-1 md:row-end-2 md:col-start-1 md:col-end-5">
          <Title2 text={title} color="purple" size="medium" />
        </div>
        <div
          data-hax
          className="mb-12 md:mb-16 full-columns md:row-start-2 md:row-end-3 md:col-start-1 md:col-end-6 text-purple"
          dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
        />
        <div
          data-hax
          className="mb-12 md:mb-16 full-columns md:row-start-3 md:row-end-4 md:col-start-1 md:col-end-5"
        >
          <Button
            {...cta}
            color="purple"
            backgroundColor="yellow"
            hover="purple"
            hoverColor="purple"
          />
        </div>
        <div
          className="mb-6 md:mb-0 col-start-4 col-end-13 md:pt-16 md:row-start-6 md:row-end-13 md:col-start-1 md:col-end-9"
          data-hax
        >
          <div data-bax="-60, 60">
            <SPImage className="w-full" {...image2} />
          </div>
        </div>
        <div
          className="full-columns md:row-start-3 md:row-end-7 md:col-start-7 md:col-end-13"
          data-hax
        >
          <div data-bax="-80, 80">
            <SPImage {...image1} />
          </div>
        </div>
      </Grid>
    </Container>
  </div>
);

LifeAtSocialpointSimple.propTypes = {
  image1: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  image2: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string,
    hrefTo: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
};

LifeAtSocialpointSimple.defaultProps = {
  image1: {
    alt: "",
  },
  image2: {
    alt: "",
  },
};

export default LifeAtSocialpointSimple;
