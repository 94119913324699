import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import Title2 from "./Title2";
import CtaWithArrows from "./CtaWithArrows";
import htmlStyle from "../lib/htmlStyle";
import SPImage from "./SPImage";
import TitleTextCtaImage from "./TitleTextCtaImage";

const LifeAtSocialpoint = ({ top, middle, bottom }) => (
  <div className="bg-green">
    <Container>
      <Grid className="grid md:grid-row-gap-medium">
        {/* top */}
        <div className="full-columns row-start-1 row-end-3">
          <Grid className="mb-8 md:grid">
            <Title2
              text={top.title}
              color="white"
              size="big"
              className="md:col-start-1 md:col-end-9 md:row-start-1 md:row-end-2"
            />
            <div
              data-hax
              className="pt-12 md:row-start-2 md:row-end-3 md:col-start-1 md:col-end-7"
            >
              <div
                dangerouslySetInnerHTML={htmlStyle(top.htmlText, "white")}
                className="pb-12"
              />
              <CtaWithArrows {...top.cta} color="purple" />
            </div>
          </Grid>
          <Grid className="mb-16 md:mb-32 md:grid">
            <div
              className="mb-6 md:row-start-1 md:row-end-4 md:col-start-6 md:col-end-13"
              data-hax
            >
              <SPImage {...top.image1} className="w-full" data-bax="90, -40" />
            </div>
            <div
              className="md:row-start-3 md:row-end-5 md:col-start-3 md:col-end-9"
              data-hax
            >
              <SPImage {...top.image2} className="w-3/4" data-bax="160, -40" />
            </div>
          </Grid>
        </div>
        {/* middle */}
        <div className="mb-16 md:mb-24 row-start-3 row-end-4 full-columns">
          <TitleTextCtaImage
            title={middle.title}
            htmlText={middle.htmlText}
            cta={middle.cta}
            image={middle.image}
            layout={{
              imagePositionX: "right",
              imagePositionY: "top",
              imageOrientation: "horizontal",
              imageSize: "normal",
            }}
            style={{
              titleSize: "tiny",
              textColor: "white",
              cta: {
                backgroundColor: "purple",
                titleColor: "white",
              },
              wrappers: {
                htmlText: "hidden md:block md:mb-12",
                cta: "hidden md:block",
                image: "hidden md:block",
              },
            }}
          />
          <div className="md:hidden mb-12" data-hax>
            <SPImage {...middle.image} data-bax="0, 60" />
          </div>
          <TitleTextCtaImage
            htmlText={middle.htmlText}
            cta={middle.cta}
            image={middle.image}
            layout={{
              imagePositionX: "right",
              imagePositionY: "top",
              imageOrientation: "horizontal",
              imageSize: "normal",
            }}
            style={{
              titleSize: "tiny",
              textColor: "white",
              cta: {
                backgroundColor: "purple",
                titleColor: "white",
              },
              wrappers: {
                htmlText: "md:hidden mb-8",
                cta: "md:hidden",
                image: "hidden",
              },
            }}
          />
        </div>
        <Grid className="mb-10 md:mb-10 full-columns md:col-start-7 md:col-end-13">
          <div className="mb-10 w-full" data-hax>
            <h4 className="text-white font-bold font-ttNorms text-medium md:text-medium-big leading-none">
              {bottom.titlePart1}
            </h4>
            <h3 className="text-white font-bold font-ttNorms text-medium-huge md:text-huge w-full leading-none">
              {bottom.titlePart2}
            </h3>
          </div>
          <div
            data-hax
            className="text-white text-base md:text-base-big"
            dangerouslySetInnerHTML={htmlStyle(bottom.htmlText, "white")}
          />
        </Grid>
        <div
          className="col-start-9 col-end-13 row-start-5 row-end-7 md:col-start-7 md:col-end-8 md:row-start-5 md:row-end-6"
          data-hax
        >
          <SPImage {...bottom.logo} />
        </div>
      </Grid>
    </Container>
  </div>
);

LifeAtSocialpoint.propTypes = {
  top: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      iconAltText: PropTypes.string,
      hrefTo: PropTypes.string,
    }).isRequired,
    image1: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
    image2: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  middle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      text: PropTypes.string,
      hrefTo: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }).isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }).isRequired,
  bottom: PropTypes.shape({
    titlePart1: PropTypes.string.isRequired,
    titlePart2: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

LifeAtSocialpoint.defaultProps = {};

export default LifeAtSocialpoint;
