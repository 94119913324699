const calculateWavePoints = (
  factor,
  wavePoints,
  waveWidth,
  waveHeight,
  waveDelta,
  speed
) => {
  const points = [];

  for (let i = 0; i <= wavePoints; i++) {
    const y = (i / wavePoints) * waveWidth;
    const sinSeed = (factor + (i + (i % wavePoints))) * speed * 100;
    const sinHeight = Math.sin(sinSeed / 100) * waveDelta;
    const xPos = Math.sin(sinSeed / 100) * sinHeight + waveHeight;
    points.push({ y: y, x: xPos });
  }

  return points;
};

const buildPath = ({ points, height }) => {
  var SVGString = "M " + points[0].y + " " + points[0].x;

  var cp0 = {
    y: (points[1].y - points[0].y) / 2,
    x: points[1].x - points[0].x + points[0].x + (points[1].x - points[0].x),
  };

  SVGString +=
    " C " +
    cp0.y +
    " " +
    cp0.x +
    " " +
    cp0.y +
    " " +
    cp0.x +
    " " +
    points[1].y +
    " " +
    points[1].x;

  var prevCp = cp0;

  for (var i = 1; i < points.length - 1; i++) {
    const cp1 = {
      y: points[i].y - prevCp.y + points[i].y,
      x: points[i].x - prevCp.x + points[i].x,
    };

    SVGString +=
      " C " +
      cp1.y +
      " " +
      cp1.x +
      " " +
      cp1.y +
      " " +
      cp1.x +
      " " +
      points[i + 1].y +
      " " +
      points[i + 1].x;
    prevCp = cp1;
  }

  SVGString += " L " + 3000 + " " + height;
  SVGString += " L 0 " + height + " Z";
  return SVGString;
};

const wavey = (width, height, wave, speed, wavePoints) => {
  return new Promise((resolve) => {
    const waveWidth = width;
    const waveDelta = 40;
    let waveHeight = 30;
    let lastUpdate;
    let totalTime = 0;
    let motionEnd = false;

    const tick = () => {
      var now = window.Date.now();

      if (lastUpdate) {
        const elapsed = (now - lastUpdate) / 1000;
        lastUpdate = now;

        totalTime += elapsed;

        const factor = totalTime * Math.PI;
        wave.setAttribute(
          "d",
          buildPath({
            points: calculateWavePoints(
              factor,
              wavePoints,
              waveWidth,
              waveHeight,
              waveDelta,
              speed
            ),
            height,
            width,
          })
        );
      } else {
        lastUpdate = now;
      }

      if (waveHeight > -waveWidth - 100) {
        waveHeight -= 15;
      } else {
        motionEnd = true;
        resolve(true);
      }

      if (!motionEnd) {
        return (
          window.requestAnimationFrame(tick) ||
          window.webkitRequestAnimationFrame(tick) ||
          window.mozRequestAnimationFrame(tick) ||
          window.oRequestAnimationFrame(tick) ||
          window.msRequestAnimationFrame(tick)
        );
      }
    };
    tick();
  });
};

export default wavey;
