import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CtaWithArrows from "./CtaWithArrows";
import SPLink from "./SPLink";
import useOnScreen from "../hooks/useOnScreen";

const Dot = () => (
  <div className="w-3 my-2 fill-gray">
    <svg viewBox="0 0 250 500" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.98122e-06 0C32.8305 3.91499e-07 65.3395 6.46644 95.6709 19.0301C126.002 31.5938 153.562 50.0087 176.777 73.2233C199.991 96.438 218.406 123.998 230.97 154.329C243.534 184.661 250 217.17 250 250C250 282.831 243.534 315.34 230.97 345.671C218.406 376.002 199.991 403.562 176.777 426.777C153.562 449.991 126.002 468.406 95.6708 480.97C65.3394 493.534 32.8304 500 -8.44416e-05 500L0 250L2.98122e-06 0Z" />
    </svg>
  </div>
);

const dots = [1, 2, 3, 4];

const CareersCard = ({
  name,
  offersNumber,
  hrefTo,
  icon,
  viewOffersText,
  offersText,
  index,
}) => {
  const main = useRef(null);
  const [isIn, setIsIn] = useState(false);

  useOnScreen(main, (intersecting) => {
    if (intersecting) setIsIn(intersecting);
  });

  useEffect(() => {
    if (isIn) {
      main.current.style.transform = "translate3d(0%, 0, 0)";
      main.current.style.opacity = 1;
    }
  }, [isIn]);

  return (
    <div
      ref={main}
      style={{
        transition: "all 0.5s",
        transitionDelay: `${index * 100}`,
        opacity: 0,
        transform: "translate3d(100%, 0, 0)",
        willChange: "transform",
      }}
      className="relative before:parallel-shadow-purple-light hover:scale03"
    >
      <SPLink to={hrefTo}>
        <div
          className="bg-white relative self-stretch"
          style={{
            display: "grid",
            gridTemplateColumns: "0.1fr 1fr",
            gridGap: "20px",
          }}
        >
          <div style={{ display: "grid" }} className="bg-white py-2">
            {dots.map((item, index) => (
              <Dot index={index} key={item} />
            ))}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "2.8fr 1fr",
            }}
          >
            <div className="pt-6 w-full">
              <h5
                className={`leading-tight font-ttNorms text-green font-bold text-small ${
                  name.length > 30 ? "pr-0 lg:pr-12" : "pr-24"
                }`}
              >
                <SPLink
                  title={name}
                  to={hrefTo}
                  className="border-solid border-green md:hover:border-b-2"
                >
                  {name}
                </SPLink>
              </h5>
              <p className="text-purple text-base mt-4">
                {offersNumber} {offersText}
              </p>
            </div>
            <div
              className={`
                p-1 w-full flex items-center relative
              `}
            >
              <CtaWithArrows
                icon={icon}
                color="purple"
                title={viewOffersText}
                fake={true}
              />
            </div>
          </div>
        </div>
        <hr
          style={{
            top: 130,
            left: 12,
            right: 0,
          }}
          className="border-dashed border-gray w-96p absolute"
        />
      </SPLink>
    </div>
  );
};

CareersCard.propTypes = {
  name: PropTypes.string.isRequired,
  offersNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  hrefTo: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  viewOffersText: PropTypes.string.isRequired,
  offersText: PropTypes.string.isRequired,
  index: PropTypes.string,
};

CareersCard.defaultProps = {
  index: 0,
};

export default CareersCard;
