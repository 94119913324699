import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import TransitionAware from "./TransitionAware";
import Menu from "./Menu";
import Bax from "./Bax";
import Hax from "./Hax";
import Breadcrumb from "./Breadcrumb";
import useIsMobile from "../hooks/useIsMobile";

const Layout = ({ menu, children, breadcrumb }) => {
  const [newMenu, setNewMenu] = useState({...menu});
  const isMobile = useIsMobile();
  useEffect(() => {
    if(isMobile) {
      setNewMenu({...newMenu, items: [...menu.items]})
    } else {
      const menuItems = [...menu.items];
      const removeItemList = menuItems.splice(0,1)
      menuItems.push(...removeItemList)
      setNewMenu({...newMenu, items: menuItems});
    }
  },[isMobile]);

  return (
    <div className="pt-16 md:pt-0 md:flex font-lato">
      {children && <Bax />}
      {children ? <Hax /> : ""}
      {children && <TransitionAware />}
      <div className="md:w-7p lg:w-5p md:h-screen md:flex-shrink-0">
        {menu && <Menu {...newMenu} />}
      </div>
      <div className="md:w-93p lg:w-95p relative bg-white">
        {breadcrumb && <Breadcrumb {...breadcrumb} />}
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.shape({
    title: PropTypes.string,
    logo: PropTypes.shape({
      mobile: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string,
    }),
    items: PropTypes.array,
  }),
  breadcrumb: PropTypes.shape({
    color: PropTypes.string,
    arrow: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        hrefTo: PropTypes.string.isRequired,
      })
    ),
  }),
};

Layout.defaultProps = {
  children: null,
  menu: null,
  breadcrumb: null,
};

export default Layout;
