import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import OfferGridSection from "./OfferGridSection";
import CtaWithArrows from "./CtaWithArrows";
import flat from "../lib/flat";
import Button from "./Button";

const OfferGrid = ({
  offers,
  teams,
  title,
  offerCard,
  activeTeams,
  bottomCta,
  noOffers,
}) => {
  if (!activeTeams) {
    activeTeams = [...new Set(flat(offers.map((item) => item.teams)))];
  }

  return (
    <div>
      <Container className="mt-12 md:mt-16">
        {offers.length > 0 ? (
          <>
            {activeTeams.map((team, index) => {
              return (
                <OfferGridSection
                  key={`${index}`}
                  team={team}
                  title={title || teams.find((t) => t.value === team).label}
                  offerCard={offerCard}
                  offers={offers.filter((offer) => offer.teams.includes(team))}
                />
              );
            })}
            {bottomCta && (
              <div className="pt-3 flex fill-purple">
                <CtaWithArrows {...bottomCta} color="purple" />
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col w-full py-24 md:pb-0">
            <h6
              data-hax
              className="font-bold font-ttNorms uppercase text-purple"
            >
              {noOffers && noOffers.title}
            </h6>
            <h3
              data-hax
              className="md:w-1/2 lg:w-1/3 py-12 text-medium font-ttNorms font-bold text-purple md:pr-24"
            >
              {noOffers && noOffers.text}
            </h3>
            <div data-hax>
              {noOffers && (
                <Button
                  backgroundColor="green"
                  title={noOffers.cta.title}
                  hrefTo={noOffers.cta.hrefTo}
                  color="white"
                />
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

OfferGrid.propTypes = {
  offerCard: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }),
  title: PropTypes.string,
  bottomCta: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    hrefTo: PropTypes.string,
  }),
  activeTeams: PropTypes.arrayOf(PropTypes.string),
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      hrefTo: PropTypes.string,
    })
  ),
  noOffers: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.shape({
      title: PropTypes.string,
      hrefTo: PropTypes.string,
    }),
  }),
};

OfferGrid.defaultProps = {
  offerCard: {
    image: {
      alt: "",
    },
    icon: {
      alt: "",
    },
  },
  bottomCta: null,
  title: null,
};

export default OfferGrid;
