import React, { useEffect, useState } from "react";
import useDidMount from "../hooks/useDidMount";
import PropTypes from "prop-types";

const CustomCursor = ({ mouseDown }) => {
  const [animationState, setAnimationState] = useState("out");
  const [spring, setSpring] = useState({
    alpha: 0,
    radius: 34,
  });
  const didMount = useDidMount();

  const animation = (state) => {
    if (state === "in") {
      if (spring.radius > 10) {
        setSpring({
          radius: spring.radius - 5,
          alpha: spring.alpha + 0.5,
        });
      }
    }

    if (state === "out") {
      if (spring.radius < 34) {
        setSpring({
          radius: spring.radius + 5,
          alpha: spring.alpha - 0.5,
        });
      }
    }
  };

  useEffect(() => {
    if (didMount) {
      window.requestAnimationFrame(() => animation(animationState));
    }
  }, [spring]);

  useEffect(() => {
    if (didMount) {
      setAnimationState(mouseDown ? "in" : "out");
    }
  }, [mouseDown]);

  useEffect(() => {
    if (didMount) {
      animation(animationState);
    }
  }, [animationState]);

  return (
    <div
      className={`w-24 h-12 flex items-center pointer-events-none`}
      style={{ pointerEvents: "none" }}
    >
      <div className="w-12 h-6 mr-2">
        <svg viewBox="0 0 477.175 477.175">
          <defs />
          <path d="M145.188 238.575l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1l-215.4-215.5z" />
        </svg>
      </div>
      <div className={`w-24 h-12`}>
        <svg viewBox="0 0 72 72">
          <defs />
          <circle
            cx="36"
            cy="36"
            r={spring.radius}
            fill={`rgb(27, 26, 46, ${spring.alpha})`}
            stroke="rgb(27,26,46)"
            strokeWidth="3"
          />
        </svg>
      </div>
      <div className="w-12 h-6 ml-2">
        <svg viewBox="0 0 477.175 477.175">
          <defs />
          <path d="M360.731 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z" />
        </svg>
      </div>
    </div>
  );
};

CustomCursor.propTypes = {
  mouseDown: PropTypes.bool.isRequired,
};

export default CustomCursor;
