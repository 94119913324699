import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";

const Input = ({
  name,
  icon,
  label,
  className,
  hasError,
  value,
  onChange,
  color = "purple",
  onBlur,
}) => {
  return (
    <div className={className}>
      {label && (
        <label
          className={`uppercase text-${color} font-bold tracking-wide text-tiny font-ttNorms`}
        >
          {label}
        </label>
      )}
      <div
        className={`flex border-b ${
          hasError ? "border-red" : `border-${color}`
        }`}
      >
        {icon && <ReactSVG className="fill-current self-center" {...icon} />}
        <input
          id={name}
          type="text"
          name={name}
          className={`${
            icon && "pl-2"
          } outline-none bg-transparent w-full py-2 h-12 text-${color}`}
          onChange={(e) => onChange(name, e.target.value)}
          value={value}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string,
};

Input.defaultValue = {
  label: "",
  className: "",
  icon: {
    alt: "",
  },
  hasError: false,
  value: "",
  onChange: () => {},
  color: "purple",
};

export default Input;
