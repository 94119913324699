import React from "react";
import PropTypes from "prop-types";
import Title1 from "./Title1";
import Title2 from "./Title2";
import Title3 from "./Title3";
import Button from "./Button";
import htmlStyle from "../lib/htmlStyle";
import Video from "./Video";
import Grid from "./Grid";

const TitleTextCtaVideo = ({
  subtitle,
  title,
  htmlText,
  highlight,
  video,
  cta,
  layout,
  style
}) => {
  const selectTitleSize = () => {
    switch (style.titleSize) {
      case "huge":
        return (
          <Title2
            text={title}
            color={style.textColor || "purple"}
            size="huge"
          />
        );
      case "big":
        if (style.isFirstTitle === true) {
          return (
            <Title1
              text={title}
              color={style.textColor || "purple"}
              className="w-3/4 md:w-full"
            />
          );
        } else {
          return (
            <Title2
              text={title}
              color={style.textColor || "purple"}
              size="medium"
            />
          );
        }
      case "medium":
        return (
          <Title2
            text={title}
            color={style.textColor || "purple"}
            size="small"
          />
        );
      case "small":
        return (
          <Title3
            text={title}
            color={style.textColor || "purple"}
            size="small"
          />
        );
      case "tiny":
        return (
          <Title3
            text={title}
            color={style.textColor || "purple"}
            size="tiny"
          />
        );
    }
  };

  const selectVideoSize = () => {
    switch (layout.videoPositionX) {
      case "right":
        switch (layout.videoSize) {
          case "maximum":
            return "md:col-start-7 md:col-end-13 lg:col-start-7 lg:col-end-13";
          case "huge":
            return "md:col-start-7 md:col-end-13 lg:col-start-7 lg:col-end-12";
          case "big":
            return "md:col-start-8 md:col-end-13 lg:col-start-8 lg:col-end-12";
          case "normal":
            return "md:col-start-8 md:col-end-12 lg:col-start-9 lg:col-end-12";
        }
        break;
      case "left":
        switch (layout.videoSize) {
          case "maximum":
            return "md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-7";
          case "huge":
            return "md:col-start-1 md:col-end-7 lg:col-start-2 lg:col-end-7";
          case "big":
            return "md:col-start-1 md:col-end-6 lg:col-start-2 lg:col-end-6";
          case "normal":
            return "md:col-start-1 md:col-end-5 lg:col-start-2 lg:col-end-5";
        }
        break;
    }
  };

  const renderTitles = () => (
    <div>
      {subtitle && (
        <div className={(style.wrappers && style.wrappers.subtitle) || "pb-4"}>
          <h4
            className={`text-base-big md:text-30px font-ttNorms font-bold leading-none text-${style.textColor ||
              "purple"}`}
          >
            {subtitle}
          </h4>
        </div>
      )}
      <div className={(style.wrappers && style.wrappers.title) || "pb-12"}>
        {selectTitleSize()}
      </div>
    </div>
  );
  const renderHtmlText = () => (
    <div
      data-hax
      className={(style.wrappers && style.wrappers.htmlText) || "pb-12"}
    >
      <div
        dangerouslySetInnerHTML={htmlStyle(
          htmlText,
          style.textColor || "purple"
        )}
      />
    </div>
  );
  const renderHighlight = () => (
    <div
      data-hax
      className={
        `${style.wrappers && style.wrappers.highlight} hidden md:block` ||
        "pb-12"
      }
    >
      <h4
        className={`leading-none font-bold font-ttNorms text-40px text-${style.textColor}`}
      >
        {highlight}
      </h4>
    </div>
  );
  const renderCta = () => (
    <div data-hax className={(style.wrappers && style.wrappers.cta) || ""}>
      <Button
        backgroundColor={style.cta.backgroundColor || "purple"}
        hoverBackgroundColor={style.cta.hover && style.cta.hover.borderColor}
        color={style.cta.titleColor || "white"}
        hoverColor={style.cta.hover && style.cta.hover.titleColor}
        title={cta.title}
        hrefTo={cta.hrefTo}
        size="normal"
      />
    </div>
  );

  const renderVideo = () => {
    if (layout.videoFreeze) {
      return (
        <div
          data-hax
          className={(style.wrappers && style.wrappers.image) || ""}
        >
          <Video
            src={video.src}
            poster={video.poster}
            player={{
              src: video.player.src,
              alt: video.player.alt,
              backgroundColor: "purple",
              color: "white"
            }}
            fullWidth={video.fullWidth}
          />
        </div>
      );
    } else {
      return (
        <div
          data-hax
          className={(style.wrappers && style.wrappers.image) || ""}
        >
          <Video
            src={video.src}
            poster={video.poster}
            player={{
              src: video.player.src,
              alt: video.player.alt,
              backgroundColor: "purple",
              color: "white"
            }}
            fullWidth={video.fullWidth}
          />
        </div>
      );
    }
  };

  const rows = [];
  title && rows.push(renderTitles);
  rows.push(renderHtmlText);
  highlight && rows.push(renderHighlight);
  cta && rows.push(renderCta);

  return (
    <Grid className="grid">
      <div
        className={`
          ${
            layout.videoPositionY === "top"
              ? `${
                  layout.videoSwitchMobile
                    ? "hidden"
                    : `${
                        layout.videoTinyMobile
                          ? `${
                              layout.videoTinyPosition === "left"
                                ? "col-start-1 col-end-10"
                                : "col-start-4 col-end-13"
                            }`
                          : `${
                              layout.videoSize === "huge" ||
                              layout.videoHugeMobile
                                ? "full-columns"
                                : "mobile-image-layout"
                            }`
                      } row-start-1 row-end-2 md:hidden`
                }`
              : `${
                  layout.videoSwitchMobile
                    ? `${
                        layout.videoTinyMobile
                          ? `${
                              layout.videoTinyPosition === "left"
                                ? "col-start-1 col-end-10"
                                : "col-start-4 col-end-13"
                            }`
                          : `${
                              layout.videoSize === "huge"
                                ? "full-columns"
                                : "mobile-image-layout"
                            }`
                      } row-start-1 row-end-2 md:hidden`
                    : "hidden"
                }
              `
          }
          `}
      >
        {renderVideo()}
      </div>
      {rows.map((row, index) => (
        <div
          key={index}
          className={`
            full-columns ${
              layout.videoTall
                ? `md:row-start-${index + 2} md:row-end-${index + 3}`
                : `md:row-start-${index + 1} md:row-end-${index + 2}`
            }
            ${
              layout.videoPositionX === "right"
                ? `${
                    layout.textSizeBig
                      ? "md:col-start-1 md:col-end-7"
                      : "md:col-start-1 md:col-end-6"
                  }`
                : `${
                    layout.textSizeBig
                      ? "md:col-start-7 md:col-end-13"
                      : "md:col-start-8 md:col-end-13"
                  }
              `
            }
            `}
        >
          {row()}
        </div>
      ))}
      <div
        className={`
          ${!layout.videoSendBack && "z-10"}
          ${
            layout.videoTall
              ? `md:row-start-1 md:row-end-${rows.length + 3}`
              : `md:row-start-1 md:row-end-${
                  rows.length === 4 ? rows.length + 1 : rows.length + 2
                }`
          }
          ${
            layout.videoTinyMobile
              ? `${
                  layout.videoTinyPosition === "left"
                    ? "col-start-1 col-end-10"
                    : "col-start-4 col-end-13"
                }`
              : `${
                  layout.videoSize === "huge" || layout.videoHugeMobile
                    ? "full-columns"
                    : "mobile-image-layout"
                }`
          }
          ${
            layout.videoPositionY === "top"
              ? `md:block md:self-start ${
                  layout.videoSwitchMobile ? "block" : "hidden md:block"
                }`
              : `row-start-${rows.length + 1} row-end-${rows.length +
                  2} md:self-end ${
                  layout.videoSwitchMobile ? "hidden md:block" : "block"
                }`
          }
          ${
            layout.videoPositionX === "left"
              ? `${
                  layout.videoOrientation === "vertical"
                    ? `${selectVideoSize()}`
                    : "md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-6"
                }`
              : `${
                  layout.videoOrientation === "vertical"
                    ? `${selectVideoSize()}`
                    : "md:col-start-7 md:col-end-13 lg:col-start-8"
                }
            `
          }
          `}
      >
        {renderVideo()}
      </div>
      <div className={`md:hidden block full-columns`}>
        {highlight && (
          <div
            data-hax
            className={(style.wrappers && style.wrappers.highlight) || "pt-12"}
          >
            <h4
              className={`leading-none font-bold font-ttNorms text-small text-${style.textColor}`}
            >
              {highlight}
            </h4>
          </div>
        )}
      </div>
    </Grid>
  );
};

TitleTextCtaVideo.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  htmlText: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  video: PropTypes.shape({
    src: PropTypes.string.isRequired,
    poster: PropTypes.string,
    player: {},
    fullWidth: PropTypes.bool
  }).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired
  }),
  layout: PropTypes.shape({
    videoPositionX: PropTypes.oneOf(["right", "left"]).isRequired,
    videoPositionY: PropTypes.oneOf(["top", "bottom"]).isRequired,
    videoOrientation: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
    videoSize: PropTypes.oneOf(["huge", "big", "normal"]).isRequired,
    videoSwitchMobile: PropTypes.bool,
    videoTinyMobile: PropTypes.bool,
    videoTinyPosition: PropTypes.oneOf(["right", "left"]),
    textSizeBig: PropTypes.bool,
    videoHugeMobile: PropTypes.bool,
    videoTall: PropTypes.bool,
    videoFreeze: PropTypes.bool,
    videoSendBack: PropTypes.bool
  }).isRequired,
  style: PropTypes.shape({
    isFirstTitle: PropTypes.bool,
    titleSize: PropTypes.oneOf(["tiny", "small", "medium", "big", "huge"]),
    textColor: PropTypes.string,
    cta: PropTypes.shape({
      backgroundColor: PropTypes.string,
      titleColor: PropTypes.string,
      hover: PropTypes.shape({
        titleColor: PropTypes.string,
        borderColor: PropTypes.string
      })
    }),
    wrappers: PropTypes.shape({
      subtitle: PropTypes.string,
      title: PropTypes.string,
      htmlText: PropTypes.string,
      highlight: PropTypes.string,
      cta: PropTypes.string,
      image: PropTypes.string
    })
  })
};

TitleTextCtaVideo.defaultProps = {};

export default TitleTextCtaVideo;
