import React from "react";
import PropTypes from "prop-types";

const SPImage = props => {
  if (!props.src) return <></>;
  return <img {...props} />;
};

SPImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

SPImage.defaultProps = {};

export default SPImage;
