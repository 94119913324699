import React from "react";
import PropTypes from "prop-types";
import ComponentIterator from "../components/ComponentIterator";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import meta from "../data/sections/Meta";



const Page = ({ name, data }) => {
  const { title, description, keywords, language, favicon } = {
    ...meta,
    ...data.meta
  };

  const otAutoBlockEnvSuffix = process.env.GATSBY_ACTIVE_ENV === "development" ? "-test" : ""

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="language" content={language} />
        <link rel="icon" type="image/gif" href={favicon} />
        <meta name="author" content="Fragment Agency, hello@fragment.agency" />
        <meta
          name="google-site-verification"
          content="Ra5YYcgK5PmgdLtr2LAhqzESlrlRoSEX2PllE21LDPQ"
        />
        <meta name="canonical" content="https://socialpoint.es/" />
        <meta
          name="inmobi-site-verification"
          content="a0cbc91e6a3225855cd64051680d6f9e"
        />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="app-mobile-web-app-capable" content="yes" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="Content-Language" content="en" />
        <meta httpEquiv="Content-Script-Type" content="text/javascript" />
        <meta httpEquiv="Content-Style-Type" content="text/css" />
        {/* OneTrust Cookies Consent Notice start for socialpoint.es */}
        {/* DEVTLS-402: This script is commented because is causing the website to randomly crash and display a blank screen*/}
        {/*<script type="text/javascript" src={`https://cdn.cookielaw.org/consent/c83c06b5-c015-4a75-9e59-b81e7e0b85c6${otAutoBlockEnvSuffix}/OtAutoBlock.js`} ></script>*/}
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="c83c06b5-c015-4a75-9e59-b81e7e0b85c6" ></script>
        {/* OneTrust Script. Use https://github.com/socialpoint/sp-tools-website-cookies-devtools to manage this */}
        <script type="text/javascript">
          {
            'function OptanonWrapper(){let e=e=>document.createElement(e),t=e("div");t.className="last-div",t.appendChild(e("div")),document.querySelector(".ot-tab-list").appendChild(t);let l=document.querySelectorAll(".ot-cat-grp > li > div"),n=document.documentElement,a=(e,t)=>n.style.setProperty(`--i-${e}-r`,`0px ${t}px 0px`),p=e=>l.item(e),c=e=>t=>{(e=>{p(e).dispatchEvent(new Event("click"))})(e),s(e),(e=>{for(let t=0;t<=5;++t)a(t,t==e?"0px 20":t==e+2?"20px 0":"0px  0")})(e)},s=e=>{for(let t=0;t<l.length;t++){let l=p(t),n=l.className.split(" ");l.className=e==t?l.className+" sp-am":n.filter(e=>"sp-am"!=e).join(" ")}},i=(e,t,l)=>p(e).addEventListener(t,l);for(let e=0;e<l.length;e++)i(e,"focus",c(e))}'
          }
        </script>
        {/* OneTrust Cookies Consent Notice end for socialpoint.es */}
      </Helmet>
      <Layout menu={data.menu} breadcrumb={data.breadcrumb}>
        <ComponentIterator pageName={name} components={data.components} />
      </Layout>
    </div>
  );
};

Page.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    menu: PropTypes.shape().isRequired,
    meta: PropTypes.shape().isRequired,
    breadcrumb: PropTypes.shape(),
    components: PropTypes.array.isRequired
  })
};

export default Page;
