import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import TitleTextCtaImage from "./TitleTextCtaImage";

// ---> join-us/art - refactor in progress
const JobOpportunities = ({ title, cta, htmlText, image, layout }) => (
  <div className="bg-red">
    <Container>
      <TitleTextCtaImage
        title={title}
        htmlText={htmlText}
        image={image}
        cta={cta}
        layout={{
          imagePositionX: "left",
          imagePositionY: "top",
          imageOrientation: "vertical",
          textSizeBig: true,
          imageSwitchMobile: true,
          imageSize: "normal",
        }}
        style={{
          isFirstTitle: false,
          titleSize: "big",
          textColor: "white",
          cta: {
            backgroundColor: "purple",
            titleColor: "white",
          },
          wrappers: {
            title: "pb-12",
            htmlText: "pb-10",
            image: "pt-24 md:pt-0",
          },
        }}
      />
    </Container>
  </div>
);

JobOpportunities.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  layout: PropTypes.shape({}),
};

JobOpportunities.defaultProps = {};

export default JobOpportunities;
