import React from "react";
import ReactSVG from "react-svg";
import PropTypes from "prop-types";

const SPSvg = ({ src, alt }) => (
  <ReactSVG className="w-full h-full svg-full" alt={alt} src={src} />
);

SPSvg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

SPSvg.defaultProps = {
  alt: "",
};

export default SPSvg;
