import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import TitleTextCtaImage from "./TitleTextCtaImage";

const JobOpenings = ({ title, htmlText, image }) => {
  return (
    <Container className="overflow-hidden">
      <TitleTextCtaImage
        title={title}
        htmlText={htmlText}
        image={image}
        layout={{
          imagePositionX: "right",
          imagePositionY: "top",
          imageOrientation: "vertical",
          imageSize: "huge",
          textSizeBig: true,
          imageSwitchMobile: true,
          imageTall: true,
        }}
        style={{
          isFirstTitle: true,
          titleSize: "big",
          textColor: "purple",
          wrappers: {
            subtitle: "pb-6",
            title: "pb-8 md:pb-16",
            htmlText: "pb-8 md:pb-16 md:pr-20",
          },
        }}
      />
    </Container>
  );
};

JobOpenings.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};

JobOpenings.defaultProps = {};

export default JobOpenings;
