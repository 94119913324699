import React, { useState } from "react";
import PropTypes from "prop-types";
import SPLink from "./SPLink";
import ReactSVG from "react-svg";
import * as easings from "d3-ease";
import { Keyframes } from "react-spring/renderprops";

const CtaWithArrows = ({
  hrefTo,
  icon,
  title,
  color,
  className,
  style,
  url,
  fake,
}) => {
  const [hover, setHover] = useState(false);

  const Wobble = Keyframes.Spring({
    show: { transform: `translateX(0px)` },
    wiggle: async (next) => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await next({
          transform: `translateX(4px)`,
          from: { transform: `translateX(0px)` },
          config: {
            duration: 400,
            easing: easings.easeQuadIn,
          },
        });
        await next({
          transform: `translateX(0px)`,
          from: { transform: `translateX(4px)` },
          config: {
            duration: 400,
            easing: easings.easeQuadIn,
          },
        });
      }
    },
  });

  const content = (
    <div
      className={`flex border-solid border-b-2 border-transparent hover:border-${color}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Wobble state={hover ? "wiggle" : "show"}>
        {(styles) => (
          <div style={styles}>
            <ReactSVG
              className={`fill-${color} flex items-center relative`}
              style={{ top: 3 }}
              {...icon}
            />
          </div>
        )}
      </Wobble>
      <span className={`pl-2 text-base leading-tight text-${color}`}>
        {title}
      </span>
    </div>
  );

  if (url.length > 0) {
    return (
      <a
        className={`${className} font-bold flex flex-row items-center`}
        href={url}
        style={style}
      >
        {content}
      </a>
    );
  }

  if (fake) {
    return (
      <div className="font-bold flex flex-row items-center select-none pointer-events-none">
        {content}
      </div>
    );
  }

  return (
    <SPLink
      className={`${className} font-bold flex flex-row items-center`}
      to={hrefTo}
      style={style}
    >
      {content}
    </SPLink>
  );
};

CtaWithArrows.propTypes = {
  hrefTo: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  fake: PropTypes.bool,
};

CtaWithArrows.defaultProps = {
  className: "",
  icon: {
    alt: "",
  },
  style: {},
  url: "",
  fake: false,
};

export default CtaWithArrows;
