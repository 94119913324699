import React from "react";
import PropTypes from "prop-types";

const Wrapper = ({ className, children, backgroundColor, style }) => {
  return (
    <div className={`${className} bg-${backgroundColor} w-full`} style={style}>
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};

Wrapper.defaultProps = {
  className: "",
  backgroundColor: "white",
  children: null,
  style: {},
};

export default Wrapper;
