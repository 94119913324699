import React, { useRef, useEffect } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import PropTypes from "prop-types";
import useDidMount from "../hooks/useDidMount";

const SPLink = props => {
  const link = useRef(null);
  const didMount = useDidMount();

  useEffect(() => {
    if (didMount) {
      if (link.current) {
        link.current.click();
      }
    }
  }, [props.trigger]);

  if (props.to.length > 0 && props.to.charAt(0) !== "/") {
    return (
      <a
        href={props.to}
        {...props}
        trigger={props.trigger ? props.trigger.toString() : undefined}
      >
        {props.children}
      </a>
    );
  }
  return (
    <TransitionLink
      ref={link}
      {...props}
      exit={{
        length: 0.6,
        delay: 0
      }}
      entry={{
        length: 0.6,
        delay: 0.6
      }}
    >
      {props.children}
    </TransitionLink>
  );
};

SPLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  trigger: PropTypes.bool
};

SPLink.defaultProps = {
  children: null,
  to: "",
  trigger: false
};

export default SPLink;
