import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title3 from "./Title3";
import htmlStyle from "../lib/htmlStyle";
import SPImage from "./SPImage";
import SocialNetworks from "./SocialNetworks";
import Grid from "./Grid";

const FollowOurLegends = ({ title, htmlText, image, socialNetworks }) => {
  return (
    <div className="gray">
      <Container>
        <Grid className="grid">
          <div className="full-columns md:col-start-1 md:col-end-8 row-start-1 row-end-2 md:row-start-3 md:row-end-4">
            <Title3
              size="small"
              text={title}
              color="purple"
              className="w-3/4 md:w-full"
            />
            <div
              data-hax
              className="py-12 md:w-2/3 lg:w-3/4"
              dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
            />
          </div>
          <div
            data-hax
            className="pb-12 full-columns md:col-start-1 md:col-end-8 row-start-3 row-end-4 md:row-start-4 md:row-end-5"
          >
            <p className="text-purple uppercase font-bold font-ttNorms text-base tracking-wide pb-4">
              {socialNetworks.title}
            </p>
            <SocialNetworks
              itemsArray={socialNetworks.items}
              svgColor="white"
              backgroundColor="purple"
              marginsDesktop={{ left: "0", right: "3" }}
              marginsMobile={{ left: "0", right: "3" }}
              className=""
            />
          </div>
          <div
            data-hax
            className="z-10 pb-12 md:pb-0 mobile-image-layout md:col-start-8 lg:col-start-9 md:col-end-13 row-start-2 row-end-3 md:row-start-1 md:row-end-6"
          >
            <SPImage data-bax="-80, 80" src={image.src} alt={image.alt} />
          </div>
        </Grid>
      </Container>
    </div>
  );
};

FollowOurLegends.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  socialNetworks: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

FollowOurLegends.defaultProps = {
  image: {
    alt: "",
  },
};

export default FollowOurLegends;
