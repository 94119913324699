import { useEffect } from "react";

const Hax = () => {
  let elements = [];

  useEffect(() => {
    elements = Array.from(document.querySelectorAll("[data-hax]"));

    if (elements.length > 0) {
      makeBreakpoints();
      document.addEventListener("scroll", handleScroll);
    }

    return function cleanup() {
      if (elements.length > 0) {
        document.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const makeBreakpoints = () => {
    elements.forEach((element) => {
      applyDefaultStyles(element);
    });
  };

  const applyDefaultStyles = (element) => {
    handleScroll(true);
    element.style["backface-visibility"] = "hidden";
    element.style["-webkit-backface-visibility"] = "hidden";
    // element.style['will-change'] = 'opacity'
    element.style["transition"] = "opacity 0.6s linear";
  };

  const handleScroll = (force = false) => {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    elements.forEach((element) => {
      if (element.getAttribute("data-no-hax")) {
        return;
      }
      const offsetHeight = element.offsetHeight;
      const offsetTop =
        element.offsetTop > 0
          ? element.offsetTop
          : element.parentNode.offsetTop;
      const t = offsetTop - window.scrollY;
      const yOffset = height * 0.15;
      const currentHaxValue = element.getAttribute("data-hax");
      if (t + offsetHeight < yOffset && (currentHaxValue === "v" || force)) {
        transitionForElement(element, false);
      } else if (height - t < yOffset && (currentHaxValue === "v" || force)) {
        transitionForElement(element, false);
      } else if (
        height - t > yOffset &&
        t + offsetHeight > yOffset &&
        (currentHaxValue === "h" || force)
      ) {
        transitionForElement(element, true);
      }
    });
  };

  const transitionForElement = (element, show) => {
    if (!element) return;
    element.setAttribute("data-hax", show ? "v" : "h");
    element.style["opacity"] = show ? 1 : 0;
  };

  return null;
};

export default Hax;
