import React from "react";
import PropTypes from "prop-types";
import SPLink from "./SPLink";

const MenuSecondaryLink = ({ title, hrefTo, active, color }) => {
  const className = `inline-block select-none text-base-big md:text-base text-white block mb-4 ml-8 ${
    active ? `text-${color} border-${color} border-b-2` : ""
  }`;
  return (
    <div>
      <SPLink className={className} to={hrefTo} title={title}>
        {title}
      </SPLink>
    </div>
  );
};

MenuSecondaryLink.propTypes = {
  title: PropTypes.string.isRequired,
  hrefTo: PropTypes.string.isRequired,
  color: PropTypes.string,
  active: PropTypes.bool,
};

MenuSecondaryLink.defaultProps = {
  title: "",
  hrefTo: "",
  color: "white",
  active: false,
};

export default MenuSecondaryLink;
