import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CountUp } from "countup.js";
import Multiline from "./Multiline";

const Counter = ({ number, text, numberColor, textColor, className }) => {
  const formatted = {
    number: number.replace(",", "").replace(".", "").match(/\d+/g),
    preText: number.match(/[+]+/g),
    postText: number.match(/[a-zA-Z]+/g),
  };

  const animatableNumber = useRef(null);
  let animated = false;
  let countUp = null;

  useEffect(() => {
    countUp = new CountUp(animatableNumber.current, formatted.number, {
      duration: 5,
    });
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const bounding = animatableNumber.current.getBoundingClientRect();
    if (bounding.top > 0 && bounding.bottom < height && !animated) {
      animated = true;
      countUp.start();
      document.removeEventListener("scroll", handleScroll);
    }
  };

  return (
    <div className={`${className}`}>
      <span className={`text-${numberColor} text-big font-bold font-ttNorms`}>
        <span ref={animatableNumber}>0</span>
        {formatted.postText}
        {formatted.preText}
      </span>
      <p className={`w-full text-${textColor}`}>
        <Multiline text={text} />
      </p>
      <div
        className={`
          max-w-25p pt-6 text-white
        `}
      >
        <hr />
      </div>
    </div>
  );
};

Counter.propTypes = {
  number: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  numberColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Counter.defaultProps = {
  className: "",
};

export default Counter;
