import React, { useState } from "react";
import PropTypes from "prop-types";
import { LinkedIn } from "react-linkedin-login-oauth2";
import Container from "./Container";
import Title1 from "./Title1";
import OfferApplicationForm from "./OfferApplicationForm";
import ButtonApplyLinkedin from "./ButtonApplyLinkedin";
import Grid from "./Grid";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";

const linkedinRedirectUri =
  typeof location !== "undefined"
    ? `${location.protocol + "//" + location.host}/linkedin/index.html`
    : "";

const OfferApplication = ({
  title,
  subtitle,
  htmlText1,
  cta,
  htmlText2,
  submitFormHrefTo,
  image,
  jobid,
  endpoint,
}) => {
  const [linkedinData, setLinkedinData] = useState({});

  const fetchLinkedinData = async (authToken) => {
    const res = await fetch(
      `${process.env.GATSBY_STRAPI_API_URL}/linkedin/profile`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          authToken,
          redirectUri: linkedinRedirectUri,
        }),
      }
    );
    res
      .json()
      .then((res) => {
        const { localizedFirstName, localizedLastName, email } = res.data;
        setLinkedinData({
          first_name: localizedFirstName,
          last_name: localizedLastName,
          email,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleSuccess = (data) => {
    fetchLinkedinData(data.code);
  };

  const handleFailure = (err) => {
    console.warn(err);
  };

  return (
    <div className="bg-blue-light">
      <Container>
        <Grid className="md:grid-md grid-column-gap-small">
          <div className="row-start-1 row-end-2 col-start-1 col-end-8 md:mt-16">
            <Title1 text={title} color="purple" />
          </div>
          <div className="col-start-1 col-end-13 md:col-start-1 md:col-end-8 row-start-2 row-end-3 my-12 md:mt-16 md:mb-12">
            <p
              data-hax
              className="uppercase text-purple font-bold text-base tracking-wide mb-8 font-ttNorms"
            >
              {subtitle}
            </p>
            <div
              data-hax
              className="mb-8 md:w-3/4 lg:w-full"
              dangerouslySetInnerHTML={htmlStyle(htmlText1, "purple")}
            />
            <div data-hax>
              <LinkedIn
                clientId={process.env.GATSBY_LINKEDIN_CLIENT_ID || ""}
                onFailure={handleFailure}
                onSuccess={handleSuccess}
                redirectUri={linkedinRedirectUri}
                scope="r_liteprofile,r_emailaddress"
                renderElement={({ onClick }) => (
                  <ButtonApplyLinkedin
                    desktopText="Apply with"
                    logo="//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/linkedin_logo.svg"
                    logoAltText="apply with LinkedIn"
                    onClick={onClick}
                  />
                )}
              />
            </div>
          </div>
          <div className="row-start-3 row-end-13 col-start-1 col-end-13">
            <OfferApplicationForm
              htmlText={htmlText2}
              hrefTo={submitFormHrefTo}
              cta={cta}
              formData={linkedinData}
              jobid={jobid}
              endpoint={endpoint}
            />
          </div>
          <div
            className="px-4 row-start-1 row-end-4 col-start-1 col-end-13 md:col-start-9 md:col-end-13 lg:col-start-10"
            data-hax
          >
            <SPImage {...image} />
          </div>
        </Grid>
      </Container>
    </div>
  );
};

OfferApplication.propTypes = {
  endpoint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  htmlText1: PropTypes.string.isRequired,
  linkedinUrl: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    attachment: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    noFiles: PropTypes.string.isRequired,
    resultSuccess: PropTypes.string.isRequired,
    resultError: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
  }),
  htmlText2: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  submitFormHrefTo: PropTypes.string.isRequired,
  jobid: PropTypes.string.isRequired,
};

OfferApplication.defaultProps = {};

export default OfferApplication;
