import React from "react";
import PropTypes from "prop-types";
import Title1 from "./Title1";
import Title2 from "./Title2";
import Title3 from "./Title3";
import Button from "./Button";
import htmlStyle from "../lib/htmlStyle";
import SPImage from "./SPImage";
import Grid from "./Grid";

const TitleTextCtaImage = ({
  subtitle,
  title,
  htmlText,
  highlight,
  image,
  cta,
  layout,
  style,
}) => {
  const selectTitleSize = () => {
    switch (style.titleSize) {
      case "huge":
        return (
          <Title2
            text={title}
            color={style.textColor || "purple"}
            size="huge"
          />
        );
      case "big":
        if (style.isFirstTitle === true) {
          return (
            <Title1
              text={title}
              color={style.textColor || "purple"}
              className="w-3/4 md:w-full"
            />
          );
        } else {
          return (
            <Title2
              text={title}
              color={style.textColor || "purple"}
              size="medium"
            />
          );
        }
      case "medium":
        return (
          <Title2
            text={title}
            color={style.textColor || "purple"}
            size="small"
          />
        );
      case "small":
        return (
          <Title3
            text={title}
            color={style.textColor || "purple"}
            size="small"
          />
        );
      case "tiny":
        return (
          <Title3
            text={title}
            color={style.textColor || "purple"}
            size="tiny"
          />
        );
    }
  };

  const selectImageSize = () => {
    switch (layout.imagePositionX) {
      case "right":
        switch (layout.imageSize) {
          case "maximum":
            return "md:col-start-7 md:col-end-13 lg:col-start-7 lg:col-end-13";
          case "huge":
            return "md:col-start-7 md:col-end-13 lg:col-start-7 lg:col-end-12";
          case "big":
            return "md:col-start-8 md:col-end-13 lg:col-start-8 lg:col-end-12";
          case "normal":
            return "md:col-start-8 md:col-end-12 lg:col-start-9 lg:col-end-12";
        }
        break;
      case "left":
        switch (layout.imageSize) {
          case "maximum":
            return "md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-7";
          case "huge":
            return "md:col-start-1 md:col-end-7 lg:col-start-2 lg:col-end-7";
          case "big":
            return "md:col-start-1 md:col-end-6 lg:col-start-2 lg:col-end-6";
          case "normal":
            return "md:col-start-1 md:col-end-5 lg:col-start-2 lg:col-end-5";
        }
        break;
    }
  };

  const renderTitles = () => (
    <div>
      {subtitle && (
        <div className={(style.wrappers && style.wrappers.subtitle) || "pb-4"}>
          <h4
            className={`text-base-big md:text-30px font-ttNorms font-bold leading-none text-${
              style.textColor || "purple"
            }`}
          >
            {subtitle}
          </h4>
        </div>
      )}
      <div className={(style.wrappers && style.wrappers.title) || "pb-12"}>
        {selectTitleSize()}
      </div>
    </div>
  );
  const renderHtmlText = () => (
    <div
      data-hax
      className={(style.wrappers && style.wrappers.htmlText) || "pb-12"}
    >
      <div
        dangerouslySetInnerHTML={htmlStyle(
          htmlText,
          style.textColor || "purple"
        )}
      />
    </div>
  );
  const renderHighlight = () => (
    <div
      data-hax
      className={
        `${style.wrappers && style.wrappers.highlight} hidden md:block` ||
        "pb-12"
      }
    >
      <h4
        className={`leading-none font-bold font-ttNorms text-40px text-${style.textColor}`}
      >
        {highlight}
      </h4>
    </div>
  );
  const renderCta = () => (
    <div data-hax className={(style.wrappers && style.wrappers.cta) || ""}>
      <Button
        backgroundColor={style.cta.backgroundColor || "purple"}
        hoverBackgroundColor={style.cta.hover && style.cta.hover.borderColor}
        color={style.cta.titleColor || "white"}
        hoverColor={style.cta.hover && style.cta.hover.titleColor}
        title={cta.title}
        hrefTo={cta.hrefTo}
        size="normal"
      />
    </div>
  );

  const renderImage = () => {
    if (layout.imageFreeze) {
      return (
        <div
          data-hax
          className={(style.wrappers && style.wrappers.image) || ""}
        >
          <SPImage src={image.src} alt={image.alt} className="w-full" />
        </div>
      );
    } else {
      return (
        <div
          data-hax
          className={(style.wrappers && style.wrappers.image) || ""}
        >
          <SPImage
            data-bax="-80, 80"
            src={image.src}
            alt={image.alt}
            className="w-full"
          />
        </div>
      );
    }
  };

  const rows = [];
  title && rows.push(renderTitles);
  rows.push(renderHtmlText);
  highlight && rows.push(renderHighlight);
  cta && rows.push(renderCta);

  return (
    <Grid className="grid">
      <div
        className={`
          ${
            layout.imagePositionY === "top"
              ? `${
                  layout.imageSwitchMobile
                    ? "hidden"
                    : `${
                        layout.imageTinyMobile
                          ? `${
                              layout.imageTinyPosition === "left"
                                ? "col-start-1 col-end-10"
                                : "col-start-4 col-end-13"
                            }`
                          : `${
                              layout.imageSize === "huge" ||
                              layout.imageHugeMobile
                                ? "full-columns"
                                : "mobile-image-layout"
                            }`
                      } row-start-1 row-end-2 md:hidden`
                }`
              : `${
                  layout.imageSwitchMobile
                    ? `${
                        layout.imageTinyMobile
                          ? `${
                              layout.imageTinyPosition === "left"
                                ? "col-start-1 col-end-10"
                                : "col-start-4 col-end-13"
                            }`
                          : `${
                              layout.imageSize === "huge"
                                ? "full-columns"
                                : "mobile-image-layout"
                            }`
                      } row-start-1 row-end-2 md:hidden`
                    : "hidden"
                }
              `
          }
          `}
      >
        {renderImage()}
      </div>
      {rows.map((row, index) => (
        <div
          key={index}
          className={`
            full-columns ${
              layout.imageTall
                ? `md:row-start-${index + 2} md:row-end-${index + 3}`
                : `md:row-start-${index + 1} md:row-end-${index + 2}`
            }
            ${
              layout.imagePositionX === "right"
                ? `${
                    layout.textSizeBig
                      ? "md:col-start-1 md:col-end-7"
                      : "md:col-start-1 md:col-end-6"
                  }`
                : `${
                    layout.textSizeBig
                      ? "md:col-start-7 md:col-end-13"
                      : "md:col-start-8 md:col-end-13"
                  }
              `
            }
            `}
        >
          {row()}
        </div>
      ))}
      <div
        className={`
          ${!layout.imageSendBack && "z-10"}
          ${
            layout.imageTall
              ? `md:row-start-1 md:row-end-${rows.length + 3}`
              : `md:row-start-1 md:row-end-${
                  rows.length === 4 ? rows.length + 1 : rows.length + 2
                }`
          }
          ${
            layout.imageTinyMobile
              ? `${
                  layout.imageTinyPosition === "left"
                    ? "col-start-1 col-end-10"
                    : "col-start-4 col-end-13"
                }`
              : `${
                  layout.imageSize === "huge" || layout.imageHugeMobile
                    ? "full-columns"
                    : "mobile-image-layout"
                }`
          }
          ${
            layout.imagePositionY === "top"
              ? `md:block md:self-start ${
                  layout.imageSwitchMobile ? "block" : "hidden md:block"
                }`
              : `row-start-${rows.length + 1} row-end-${
                  rows.length + 2
                } md:self-end ${
                  layout.imageSwitchMobile ? "hidden md:block" : "block"
                }`
          }
          ${
            layout.imagePositionX === "left"
              ? `${
                  layout.imageOrientation === "vertical"
                    ? `${selectImageSize()}`
                    : "md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-6"
                }`
              : `${
                  layout.imageOrientation === "vertical"
                    ? `${selectImageSize()}`
                    : "md:col-start-7 md:col-end-13 lg:col-start-8"
                }
            `
          }
          `}
      >
        {renderImage()}
      </div>
      <div className={`md:hidden block full-columns`}>
        {highlight && (
          <div
            data-hax
            className={(style.wrappers && style.wrappers.highlight) || "pt-12"}
          >
            <h4
              className={`leading-none font-bold font-ttNorms text-small text-${style.textColor}`}
            >
              {highlight}
            </h4>
          </div>
        )}
      </div>
    </Grid>
  );
};

TitleTextCtaImage.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  htmlText: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }),
  layout: PropTypes.shape({
    imagePositionX: PropTypes.oneOf(["right", "left"]).isRequired,
    imagePositionY: PropTypes.oneOf(["top", "bottom"]).isRequired,
    imageOrientation: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
    imageSize: PropTypes.oneOf(["huge", "big", "normal"]).isRequired,
    imageSwitchMobile: PropTypes.bool,
    imageTinyMobile: PropTypes.bool,
    imageTinyPosition: PropTypes.oneOf(["right", "left"]),
    textSizeBig: PropTypes.bool,
    imageHugeMobile: PropTypes.bool,
    imageTall: PropTypes.bool,
    imageFreeze: PropTypes.bool,
    imageSendBack: PropTypes.bool,
  }).isRequired,
  style: PropTypes.shape({
    isFirstTitle: PropTypes.bool,
    titleSize: PropTypes.oneOf(["tiny", "small", "medium", "big", "huge"]),
    textColor: PropTypes.string,
    cta: PropTypes.shape({
      backgroundColor: PropTypes.string,
      titleColor: PropTypes.string,
      hover: PropTypes.shape({
        titleColor: PropTypes.string,
        borderColor: PropTypes.string,
      }),
    }),
    wrappers: PropTypes.shape({
      subtitle: PropTypes.string,
      title: PropTypes.string,
      htmlText: PropTypes.string,
      highlight: PropTypes.string,
      cta: PropTypes.string,
      image: PropTypes.string,
    }),
  }),
};

TitleTextCtaImage.defaultProps = {};

export default TitleTextCtaImage;
