import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import Button from "./Button.js";
import VerticalSpacer from "./VerticalSpacer.js";
const defaultLang = "en";

// WARNING: This component is a strong coupled part of code to solve ad-hoc a temporal issue.
// This component is strongly coupled to `data/legal/router.js`
const compileUrl = (lang) => (url) => {
  if (lang && lang !== defaultLang) {
    let usedLang = (url.langMap && url.langMap[lang]) || lang;
    return url.buildingPattern
      .map((chunk) => (chunk === "lang" ? "/" + usedLang : chunk))
      .join("");
  }
  return url.buildingPattern.filter((chunk) => chunk !== "lang").join("");
};

const allowedLangs = [
  "en",
  "es",
  "fr",
  "de",
  "it",
  "ja",
  "ko",
  "pt",
  "ru",
  "tr",
  "zh-Hans",
  "zh-Hant",
  "es-MX",
  "fi",
  "ar",
  "nl",
];
const LegalRouting = ({ title, routes }) => {
  const titleStyle = {
    fontSize: "60px",
    fontFamily: "TT Norms",
  };
  const [lang, setLang] = useState(defaultLang);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(document.location.search);
      const potentialNewLang = searchParams.get("lang");
      setLang(
        allowedLangs.includes(potentialNewLang) ? potentialNewLang : defaultLang
      );
    }
  }, []);
  const urlCompiler = compileUrl(lang);

  return (
    <div className="bg-purple">
      <Container>
        <Grid className="grid">
          <VerticalSpacer height="50px" />
          <div className="pt-16 md:row-start-1 md:row-end-11 md:col-start-2 md:col-end-11 full-columns row-start-1 row-end-11">
            <div className="text-center text-base py-12 md:py-16">
              <h1 className="white font-bold" style={titleStyle}>
                {title[lang]}
              </h1>
              <VerticalSpacer height="50px" />
              {routes.map((route, i, arr) => (
                <div key={`${title[lang]}-${i}-Button`}>
                  <Button
                    backgroundColor="yellow"
                    hoverBackgroundColor="white"
                    color="black"
                    hoverColor="white"
                    title={`${route.caption[lang]}`}
                    hrefTo={urlCompiler(route.url)}
                    size="normal"
                    target=""
                  />
                  {i !== arr.length - 1 ? (
                    <VerticalSpacer height="20px" />
                  ) : undefined}
                </div>
              ))}
              <VerticalSpacer height="300px" />
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

LegalRouting.propTypes = {
  title: PropTypes.instanceOf(Object),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.instanceOf(Object),
      url: PropTypes.instanceOf(Object),
    })
  ),
};

LegalRouting.defaultProps = {
  title: {},
  routes: {
    caption: {},
    url: {},
  },
};

export default LegalRouting;
