import React from "react";
import PropTypes from "prop-types";
import Gallery from "./Gallery";
import OfficeTourItem from "./OfficeTourItem";

const OfficeTour = ({ title, items }) => {
  return (
    <div className="bg-gray">
      <div data-hax>
        <Gallery title={title} items={items}>
          {items.map((item, index) => (
            <OfficeTourItem
              key={index}
              {...item}
              last={index === items.length - 1}
            />
          ))}
        </Gallery>
      </div>
    </div>
  );
};

OfficeTour.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["small", "big"]),
    color: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
    })
  ),
};

export default OfficeTour;
