import React from "react";
import PropTypes from "prop-types";
import Quote from "./Quote";
import Container from "./Container";
import SPImage from "./SPImage";
import Grid from "./Grid";

const QuoteWithImage = ({ image, quote, name, position, city }) => {
  return (
    <Container>
      <Grid className="grid pt-8 md:pt-0">
        <div
          className="full-columns md:pt-24 md:col-start-1 md:col-end-7 md:row-start-2 md:row-end-3"
          data-hax
        >
          <Quote {...quote} name={name} position={position} city={city} />
        </div>
        <div
          className="pt-12 col-start-4 col-end-10 md:col-start-9 md:col-end-12 md:pt-0 md:row-start-1 md:row-end-5"
          data-hax
        >
          <SPImage data-bax="100, -100" {...image} />
        </div>
      </Grid>
    </Container>
  );
};

QuoteWithImage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  quote: PropTypes.shape({
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    htmlText: PropTypes.string.isRequired,
  }),
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

QuoteWithImage.defaultProps = {};

export default QuoteWithImage;
