import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import CtaWithArrows from "./CtaWithArrows";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";
import TitleTextCtaImage from "./TitleTextCtaImage";

const WhySocialpoint = ({ top, middle, bottom }) => (
  <div className="bg-gray">
    <Container>
      <Grid className="grid grid-column-gap-small">
        <div className="full-columns row-start-1 row-end-2 mb-16 md:mb-0">
          <TitleTextCtaImage
            title={top.title}
            htmlText={top.htmlText}
            image={top.image}
            cta={top.cta}
            layout={{
              imagePositionX: "right",
              imagePositionY: "top",
              imageOrientation: "vertical",
              imageSize: "huge",
            }}
            style={{
              titleSize: "small",
              textColor: "purple",
              cta: {
                backgroundColor: "green",
                titleColor: "white",
              },
              wrappers: {
                title: "pb-8 md:pb-16 w-2/3",
                htmlText: "pb-8 md:pb-16",
                image: "hidden md:block",
              },
            }}
          />
        </div>
        <Grid className="mb-24 md:mb-40 md:-mt-32 full-columns row-start-3 row-end-4 grid">
          <div
            className="mb-6 md:mb-0 col-start-1 col-end-10 row-start-1 row-end-2 md:row-end-3 md:col-end-6"
            data-hax
          >
            <SPImage
              src={middle.image1.src}
              alt={middle.image1.alt}
              className="w-full"
              data-bax="-80, 80"
            />
          </div>
          <div
            className="mb-6 md:mb-0 full-columns row-start-2 row-end-3 md:row-start-4 md:row-end-12 md:col-start-3 md:col-end-11"
            data-hax
          >
            <SPImage
              src={middle.image2.src}
              alt={middle.image2.alt}
              className="w-full"
              data-bax="-80, 80"
            />
          </div>
          <div
            className="col-start-4 col-end-13 row-start-3 row-end-4 md:row-start-12 md:row-end-13 md:col-start-8 md:col-end-13"
            data-hax
          >
            <SPImage
              src={middle.image3.src}
              alt={middle.image3.alt}
              className="w-full"
              data-bax="-80, 80"
            />
          </div>
        </Grid>
        <Grid className="full-columns row-start-5 row-end-6 md:col-start-7 md:col-end-13">
          <p
            className="mb-12 font-ttNorms font-bold leading-none text-purple text-base uppercase md:normal-case md:text-50px"
            data-hax
          >
            {bottom.title}
          </p>
          <div
            data-hax
            className="mb-10"
            dangerouslySetInnerHTML={htmlStyle(bottom.htmlText, "purple")}
          />
          <div data-hax>
            <CtaWithArrows
              hrefTo={bottom.cta.hrefTo}
              icon={bottom.cta.icon}
              title={bottom.cta.title}
              color="green"
            />
          </div>
        </Grid>
        <div
          className="px-4 pb-24 md:pb-0 md:pr-10 full-columns row-start-2 row-end-3 md:row-start-4 md:row-end-7 md:col-start-1 md:col-end-7"
          data-hax
        >
          <SPImage
            src={bottom.image.src}
            alt={bottom.image.alt}
            data-bax="-80, 80"
          />
        </div>
        <div
          className="md:hidden px-4 pt-16 full-columns row-start-6 row-end-7"
          data-hax
        >
          <SPImage src={top.image.src} alt={top.image.alt} data-bax="-80, 80" />
        </div>
      </Grid>
    </Container>
  </div>
);

WhySocialpoint.propTypes = {
  top: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired,
    }),
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  }),
  middle: PropTypes.shape({
    image1: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    image2: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    image3: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
  bottom: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
      hrefTo: PropTypes.string.isRequired,
    }),
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
};

WhySocialpoint.defaultProps = {};

export default WhySocialpoint;
