import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SocialNetworks from "./SocialNetworks";

const Share = ({ text, titleColor, svgColor, backgroundColor, className }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(window.location.href);
  });

  return (
    <div className={`${className} bg-transparent`}>
      <p
        className={`text-${titleColor} font-ttNorms font-bold text-base-big mb-3`}
      >
        {text}
      </p>
      <SocialNetworks
        itemsArray={[
          {
            name: "Facebook",
            icon:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
            url: "#",
            onClick: () =>
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${url}`,
                "Share",
                `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=440,
                                    height=300`
              ),
          },
          {
            name: "Twitter",
            icon:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
            url: "#",
            onClick: () =>
              window.open(
                `https://twitter.com/intent/tweet?text=${url}`,
                "Share",
                `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=440,
                                    height=300`
              ),
          },
          {
            name: "LinkedIn",
            icon:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/linkedin.svg",
            url: "#",
            onClick: () =>
              window.open(
                `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
                "Share",
                `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=440,
                                    height=300`
              ),
          },
        ]}
        svgColor={svgColor}
        backgroundColor={backgroundColor}
        marginsDesktop={{ right: "6", left: "0" }}
        marginsMobile={{ right: "6", left: "0" }}
      />
    </div>
  );
};

Share.propTypes = {
  text: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  svgColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

Share.defaultProps = {
  titleColor: "purple-medium",
  svgColor: "white",
  backgroundColor: "purple-medium",
  className: "",
};

export default Share;
