import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";

const Select = ({ name, items, placeholder, textColor, icon, callback }) => (
  <div className="relative">
    <select
      onChange={(e) => callback(name, e.target.value ? e.target.value : false)}
      defaultValue="placeholder"
      className={`
          outline-none
          w-full webkit-appearance-none bg-transparent
          flex block w-full border border-${textColor} 
          text-${textColor} rounded-n py-3 px-6
        `}
    >
      <option value="all">{placeholder}</option>
      {items.map((i, index) => (
        <option key={index} value={i.value}>
          {i.label}
        </option>
      ))}
    </select>
    <SPImage
      className={`fill-current pointer-events-none absolute inset-y-0 right-0 flex items-center p-5`}
      {...icon}
    />
  </div>
);

Select.defaultValue = {
  callback: () => {},
  icon: {
    alt: "",
  },
};

Select.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  callback: PropTypes.func,
};

export default Select;
