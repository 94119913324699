import React from "react";
import PropTypes from "prop-types";
import Title2 from "./Title2";
import Container from "./Container";
import SPImage from "./SPImage";
import Grid from "./Grid";
import Form from "./Form";

const JobAlerts = ({ title, image, form }) => {
  return (
    <div className="bg-yellow">
      <Container>
        <Grid className="grid">
          <div
            data-hax
            className="col-start-1 col-end-11 row-start-1 row-end-2 md:col-end-7 self-end"
          >
            <Title2 color={form.color} text={title} size="medium" />
          </div>
          <div className="full-columns row-start-2 row-end-3 md:col-start-1 md:col-end-7">
            <div>
              <Form {...form} />
            </div>
          </div>
          <div
            data-bax="-100, 100"
            className="full-columns mobile-image-layout pt-12 md:pt-0 md:row-start-1 md:row-end-4 md:col-start-8 md:col-end-13"
          >
            <div data-hax>
              <SPImage {...image} />
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

JobAlerts.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["email", "text"]),
      })
    ),
    color: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
  }),
};

JobAlerts.defaultProps = {
  form: null,
};

export default JobAlerts;
