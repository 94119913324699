import React, { useState } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import DragInstructions from "./DragInstructions";
import SPImage from "./SPImage";
import Grid from "./Grid";
import Multiline from "./Multiline";
import TitleTextCtaImage from "./TitleTextCtaImage";
import DragGallery from "./DragGallery";

const StepItem = ({ title, text }, index) => {
  return (
    <div key={index}>
      <div className="relative pb-6 flex flex-row flex-start fill-white">
        <hr
          className={`absolute border-white`}
          style={{ top: 6, left: 13, right: 0 }}
        />
        <svg
          width="14"
          height="14"
          xmlns="http://www.w3.org/2000/svg"
          className="z-10"
        >
          <path
            d="M8.813 1.742l3.445 3.445c.63.63.793.925.9 1.278.108.354.108.716 0 1.07-.107.353-.27.648-.9 1.278l-3.445 3.445c-.63.63-.925.793-1.278.9a1.817 1.817 0 01-1.07 0c-.353-.107-.648-.27-1.278-.9L1.742 8.813c-.63-.63-.793-.925-.9-1.278a1.817 1.817 0 010-1.07c.107-.353.27-.648.9-1.278l3.445-3.445c.63-.63.925-.793 1.278-.9a1.817 1.817 0 011.07 0c.353.107.648.27 1.278.9z"
            fillRule="evenodd"
          />
        </svg>
      </div>
      <div className="mr-16 w-55 md:w-76">
        <h4 className="text-purple font-bold max-w-25p">
          <Multiline text={title} />
        </h4>
        <p className="text-white pt-12 text-base md:max-w-60p">{text}</p>
      </div>
    </div>
  );
};

const ApplicationProcess = ({
  steps,
  dragInstructions,
  image,
  title,
  htmlText,
  cta,
}) => {
  const [triggerForward, setTriggerForward] = useState(0);
  return (
    <div className="bg-green">
      <Container>
        <TitleTextCtaImage
          title={title}
          htmlText={htmlText}
          image={image}
          cta={cta}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "vertical",
            imageSizeBig: true,
            imageSize: "big",
          }}
          style={{
            titleSize: "medium",
            textColor: "white",
            cta: {
              backgroundColor: "purple",
              titleColor: "white",
            },
            wrappers: {
              htmlText: "pb-16",
              image: "hidden md:block",
            },
          }}
        />
        <div className="relative pt-16" data-hax>
          <div className="pb-8">
            <div
              onClick={() => {
                const next = triggerForward + 1;
                setTriggerForward(next);
              }}
            >
              <DragInstructions
                icon={dragInstructions.icon}
                text={dragInstructions.text}
                color="white"
                className="justify-end mt-4"
              />
            </div>
          </div>
          <DragGallery triggerForward={triggerForward}>
            {steps.map((step, index) => StepItem(step, index, steps.length))}
          </DragGallery>
        </div>
        <div data-hax>
          <Grid className="grid md:hidden pt-12">
            <SPImage className="mobile-image-layout" {...image} />
          </Grid>
        </div>
      </Container>
    </div>
  );
};

StepItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ApplicationProcess.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string,
    hrefTo: PropTypes.string,
  }).isRequired,
  dragInstructions: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
};

ApplicationProcess.defaultProps = {};

export default ApplicationProcess;
