import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import SPLink from "./SPLink";
import ReactSVG from "react-svg";

const Breadcrumb = ({ color, arrow, items }) => {
  return (
    <div className="absolute top-0 left-0 right-0">
      <Container
        className={`mt-8 relative fill-${color} text-${color} flex flex-row md:justify-end`}
      >
        <div className="flex flex-row items-center flex-wrap">
          {items.map((item, index) => {
            return (
              <div
                key={`${item.hrefTo}-${index}`}
                className="flex flex-row items-center"
              >
                <SPLink
                  title={item.title}
                  to={item.hrefTo}
                  className={`border-b border-solid hover:border-${color} border-transparent`}
                >
                  {item.title}
                </SPLink>
                {index !== items.length - 1 && (
                  <ReactSVG
                    src={arrow}
                    className={`relative mx-2 fill-${color}`}
                    style={{ top: 1 }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

Breadcrumb.propTypes = {
  color: PropTypes.string,
  arrow: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired,
    })
  ),
};

Breadcrumb.defaultProps = {
  color: "purple",
};

export default Breadcrumb;
