import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Wrapper from "./Wrapper";
import Filter from "./Filter";
import Container from "./Container";
import OfferGrid from "./OfferGrid";
import flat from "../lib/flat";

const Openings = ({
  filterTitle,
  filters,
  offers,
  activeOffersText,
  noResultsText,
}) => {
  const everyTeam = [...new Set(flat(offers.items.map((item) => item.teams)))];
  const [activeTeams, setActiveTeams] = useState(everyTeam);
  const [activeItems, setActiveItems] = useState(offers.items);
  const [activeOffers, setActiveOffers] = useState(offers.items.length);

  const [lists, setLists] = useState({
    teams: offers.teams,
    roles: offers.items.map((item) => ({
      label: item.title,
      value: item.title,
    })),
  });

  const callback = (criteria) => {
    if (criteria.teams) {
      setActiveTeams(everyTeam.filter((n) => n === criteria.teams));
      setLists({
        ...lists,
        roles: offers.items
          .filter((offer) => offer.teams.includes(criteria.teams))
          .map((item) => ({ label: item.title, value: item.title })),
      });
      setActiveOffers(
        activeItems.filter((offer) => offer.teams.includes(criteria.teams))
          .length
      );
      return;
    }
    if (criteria.search && criteria.search !== "") {
      const exp = new RegExp(criteria.search, "gi");
      setActiveItems(offers.items.filter((offer) => exp.test(offer.title)));
      setActiveOffers(
        offers.items.filter((offer) => exp.test(offer.title)).length
      );
      return;
    }
    if (criteria.roles) {
      setActiveItems(
        offers.items.filter((offer) => offer.title === criteria.roles)
      );
      setActiveOffers(
        offers.items.filter((offer) => offer.title === criteria.roles).length
      );
      return;
    }
    setActiveTeams(everyTeam);
    setActiveItems(offers.items);
    setActiveOffers(offers.items.length);
    setLists({
      teams: offers.teams,
      roles: offers.items.map((item) => ({
        label: item.title,
        value: item.title,
      })),
    });
  };

  useEffect(() => {
    setActiveTeams([...new Set(activeItems.map((item) => item.teams).flat())]);
  }, [activeItems]);
  return (
    <>
      <Wrapper backgroundColor="blue-light">
        <div>
          <Filter
            title={filterTitle}
            titleColor="purple"
            activeOffers={activeOffers}
            activeOffersText={activeOffersText}
            activeOffersColor="white"
            filters={filters}
            filterCallback={callback}
            lists={lists}
          />
        </div>
      </Wrapper>
      <div className="pt-6 md:pt-0">
        {activeItems.length > 0 && activeTeams.length > 0 ? (
          <OfferGrid
            offers={activeItems}
            teams={offers.teams}
            offerCard={{
              image: offers.meta.image,
              cta: offers.meta.cta,
            }}
            activeTeams={activeTeams}
          />
        ) : (
          <Container>
            <p className="text-small font-ttNorms text-purple font-bold pt-24">
              {noResultsText}
            </p>
          </Container>
        )}
      </div>
    </>
  );
};

Openings.propTypes = {
  activeOffersText: PropTypes.string.isRequired,
  filterTitle: PropTypes.string.isRequired,
  offers: PropTypes.shape({
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      })
    ).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        hrefTo: PropTypes.string,
      })
    ).isRequired,
    meta: PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      cta: PropTypes.shape({
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }).isRequired,
        title: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    teams: PropTypes.shape({
      placeholder: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
      items: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    roles: PropTypes.shape({
      placeholder: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      items: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    search: PropTypes.shape({
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  noResultsText: PropTypes.string.isRequired,
};

export default Openings;
