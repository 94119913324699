import React, { useState } from "react";
import PropTypes from "prop-types";
import SPLink from "./SPLink";
import SPImage from "./SPImage";
import Button from "./Button";
import * as easings from "d3-ease";
import { useSpring, animated } from "react-spring";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const GamesItem = ({
  background,
  logo,
  title,
  hrefTo,
  last,
  button,
  index,
  isIn,
}) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [hover, setHover] = useState(false);

  const backgroundProps = useSpring({
    transform: hover ? `scale(1.07)` : `scale(1)`,
    config: {
      duration: 600,
      easing: easings.easeQuadOut,
    },
  });
  backgroundProps["backgroundImage"] = `url(${background})`;

  const transformX = 500 + index * index * 100;

  const cardProps = useSpring(
    isIn && !hasAnimated
      ? {
          to: { transform: `translateX(0px) scale(1)`, opacity: 1 },
          from: {
            transform: `translateX(${transformX}px) scale(0.9)`,
            opacity: 0,
          },
          delay: 150 * index,
          config: {
            duration: 800,
            easing: easings.easeSinOut,
          },
          onRest: () => setHasAnimated(true),
        }
      : {
          transform: hasAnimated
            ? `translateX(0px) scale(1)`
            : `translateX(${transformX}px) scale(0.9)`,
          opacity: hasAnimated ? 1 : 0,
        }
  );

  const handleLink = () => {
    trackCustomEvent({
      category: "Games Slider",
      action: window.location.pathname,
      label: hrefTo,
    });
  };

  return (
    <animated.div
      style={cardProps}
      className={`relative w-40vh h-full flex-shrink-0 ${
        !last && "mr-8"
      } border-2 border-purple border-solid ${
        hover
          ? "border-yellow before:parallel-shadow-yellow"
          : " before:parallel-shadow-yellow-ready"
      }`}
      onMouseEnter={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onBlur={() => setHover(false)}
    >
      <div className="absolute inset-0 overflow-hidden">
        <animated.div
          style={backgroundProps}
          className="absolute inset-0 bg-no-repeat bg-cover bg-center"
        />
        <div className="absolute inset-0">
          <SPLink
            title={title}
            to={hrefTo}
            onClick={handleLink}
            className="h-full w-full block flex flex-col justify-end items-center"
          >
            {button ? (
              <div className="max-w-90p mb-4 text-center">
                <SPImage
                  alt={title}
                  src={logo}
                  className="inline w-3/4 md:w-2/3"
                />
                {
                  <Button
                    {...button}
                    className="mt-6 mb-2"
                    size="medium"
                    noLink={true}
                  />
                }
              </div>
            ) : (
              <div className="max-w-90p mb-4 text-center">
                <SPImage
                  alt={title}
                  src={logo}
                  className="inline"
                  style={{ maxHeight: 140, maxWidth: "80%" }}
                />
              </div>
            )}
          </SPLink>
        </div>
      </div>
    </animated.div>
  );
};

GamesItem.propTypes = {
  background: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hrefTo: PropTypes.string.isRequired,
  last: PropTypes.bool,
  isIn: PropTypes.bool,
  index: PropTypes.number,
  button: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
    hoverColor: PropTypes.string,
    className: PropTypes.string,
  }),
};

GamesItem.defaultProps = {
  isIn: false,
  last: false,
  index: false,
};

export default GamesItem;
