import React from "react";
import PropTypes from "prop-types";

const Grid = ({ children, className, dataBax, style }) => (
  <div className={`${className} w-full`} data-bax={dataBax} style={style}>
    {children}
  </div>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataBax: PropTypes.string,
  style: PropTypes.object,
};

Grid.defaultProps = {
  className: "",
  dataBax: null,
  style: {},
};

export default Grid;
