import React, { useState } from "react";
import PropTypes from "prop-types";
import SPLink from "./SPLink";
import * as easings from "d3-ease";
import { useSpring, animated } from "react-spring";

const MenuSecondaryImageLink = ({ title, image, hrefTo }) => {
  const [hover, setHover] = useState(false);

  const backgroundProps = useSpring({
    transform: hover ? `scale(1.07)` : `scale(1)`,
    config: {
      duration: 600,
      easing: easings.easeQuadOut,
    },
  });
  backgroundProps["backgroundImage"] = `url(${image})`;

  return (
    <SPLink
      className="select-none text-md text-white block mb-4 relative before:parallel-shadow-yellow-ready hover:before:parallel-shadow-yellow "
      to={hrefTo}
      title={title}
      style={{ paddingTop: "78%" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="absolute inset-0 bg-no-repeat bg-cover bg-center overflow-hidden">
        <animated.div
          style={backgroundProps}
          className="absolute inset-0 bg-no-repeat bg-cover bg-center"
        />
      </div>
    </SPLink>
  );
};

MenuSecondaryImageLink.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  hrefTo: PropTypes.string.isRequired,
};

MenuSecondaryImageLink.defaultProps = {
  image: "",
  title: "",
  hrefTo: "",
};

export default MenuSecondaryImageLink;
