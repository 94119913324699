import React from "react";
import PropTypes from "prop-types";

const Container = ({ className, style, children, fullWidth }) => {
  return (
    <div
      style={style}
      className={`${className} mx-auto ${
        fullWidth ? "w-[100%]" : "max-w-1480 w-90p"
      }`}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.any,
  fullWidth: PropTypes.bool
};

Container.defaultProps = {
  className: "",
  children: null,
  style: null,
  fullWidth: false
};

export default Container;
