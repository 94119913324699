import React from "react";
import PropTypes from "prop-types";
import Multiline from "./Multiline";

// H1 - desktop: 80px - mobile: 40px

const Title1 = ({ className, text, color }) => {
  return (
    <h1
      className={`
        text-${color} font-ttNorms font-bold leading-none
        text-40px md:text-80px
        ${className}
      `}
    >
      <Multiline text={text} />
    </h1>
  );
};

Title1.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Title1.defaultProps = {
  className: "",
};

export default Title1;
