import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import htmlStyle from "../lib/htmlStyle";
import Input from "./Input";
import TextArea from "./TextArea";
import Attachment from "./Attachment";
import Grid from "./Grid";
import validateEmail from "../lib/validateEmail";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const validations = {
  first_name: ["required"],
  last_name: ["required"],
  email: ["required", "email"],
};

const OfferApplicationForm = ({ htmlText, cta, jobid, endpoint, formData }) => {
  const form = useRef(null);
  const [data, setData] = useState({});
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setData({ ...data, ...formData });
  }, [formData]);

  const processForm = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    const updatedErrors = {};

    for (var pair of formData.entries()) {
      if (typeof validations[pair[0]] !== "undefined") {
        if (validations[pair[0]].includes("required")) {
          if (pair[1].length === 0) {
            updatedErrors[pair[0]] = true;
          }
        }
        if (validations[pair[0]].includes("email")) {
          if (!validateEmail(pair[1])) {
            updatedErrors[pair[0]] = true;
          }
        }
      }
    }

    setErrors(updatedErrors);

    if (Object.keys(updatedErrors).length === 0) {
      setLoading(true);
      formData.append("jobid", jobid);
      const req = new XMLHttpRequest();
      req.onload = () => {
        setLoading(false);
        const valid = req.status === 200;
        if (valid) {
          setData({});
          trackCustomEvent({
            category: "Offer Form",
            action: "Submit",
            label: "SUCCESS",
          });
        } else {
          trackCustomEvent({
            category: "Offer Form",
            action: "Submit",
            label: "ERROR",
          });
        }
        setStatus(valid);
        if (valid) setTimeout(() => setStatus(null), 3000);
      };
      req.onerror = () => {
        setLoading(false);
        setStatus(false);
        trackCustomEvent({
          category: "Offer Form",
          action: "Submit",
          label: "ERROR",
        });
      };
      req.open("POST", endpoint);
      req.send(formData);
    } else {
      trackCustomEvent({
        category: "Offer Form",
        action: "Submit",
        label: "VALIDATION ERRORS",
      });
    }
  };

  const handleBlur = (field) => {
    trackCustomEvent({
      category: "Offer Form",
      action: "Input",
      label: field,
    });
  };

  return (
    <form ref={form} onSubmit={(e) => processForm(e)}>
      <div data-hax className="mb-8 md:mb-16">
        <Attachment
          name="files.resume"
          label="Resume"
          cta={cta}
          text="Upload your resume in those formats PDF, DOC, DOCX, JPG, 2MB max."
          onChange={(name, value) => setData({ ...data, ...{ [name]: value } })}
          onBlur={handleBlur.bind(this, "RESUME")}
          value={data["files.resume"] || ""}
        />
      </div>
      <div data-hax className="mb-8 md:mb-16">
        <Grid className="md:grid-column-gap-small md:grid-md">
          <Input
            name="first_name"
            label="Name*"
            className="mb-6 md:col-start-1 md:col-end-4"
            hasError={errors["first_name"] === true}
            onChange={(name, value) =>
              setData({ ...data, ...{ [name]: value } })
            }
            onBlur={handleBlur.bind(this, "NAME")}
            value={data["first_name"] || "" || ""}
          />
          <Input
            name="last_name"
            label="Surname*"
            className="mb-6 md:col-start-4 md:col-end-7"
            hasError={errors["last_name"] === true}
            onChange={(name, value) =>
              setData({ ...data, ...{ [name]: value } })
            }
            onBlur={handleBlur.bind(this, "SURNAME")}
            value={data["last_name"] || ""}
          />
          <Input
            name="email"
            label="Email*"
            className="mb-6 md:col-start-7 md:col-end-10"
            hasError={errors["email"] === true}
            onChange={(name, value) =>
              setData({ ...data, ...{ [name]: value } })
            }
            onBlur={handleBlur.bind(this, "EMAIL")}
            value={data["email"] || ""}
          />
          <Input
            name="phone"
            label="Phone"
            className="mb-8 md:col-start-10 md:col-end-13"
            onChange={(name, value) =>
              setData({ ...data, ...{ [name]: value } })
            }
            onBlur={handleBlur.bind(this, "PHONE")}
            value={data["phone"] || ""}
          />
          <TextArea
            name="cover_letter"
            label="Cover letter"
            className="md:row-start-2 md:col-start-1 md:col-end-13"
            onChange={(name, value) =>
              setData({ ...data, ...{ [name]: value } })
            }
            onBlur={handleBlur.bind(this, "COVER LETTER")}
            value={data["cover_letter"] || ""}
          />
        </Grid>
      </div>
      <div data-hax className="mb-8 md:mb-0">
        <Attachment
          name="files.resumeadditional1"
          label="Additional attachment"
          cta={cta}
          text="Upload your resume in those formats PDF, DOC, DOCX, JPG, 2MB max."
          onChange={(name, value) => setData({ ...data, ...{ [name]: value } })}
          onBlur={handleBlur.bind(this, "ATTACHMENT")}
          value={data["files.resumeadditional1"] || ""}
        />
      </div>
      <div className="mb-12">
        <div
          data-hax
          dangerouslySetInnerHTML={htmlStyle(htmlText, "purple text-small")}
          className="w-full mb-8 italic"
        />
        <button
          data-hax
          type="submit"
          className={`text-purple bg-yellow border-solid border-2 border-transparent font-bold font-lato inline-block text-base-big cursor-pointer transition-all py-4 px-12 md:px-16 rounded font-ttNorms ${
            status === null
              ? "hover:border-yellow hover:text-yellow hover:bg-transparent"
              : ""
          }`}
        >
          {loading && cta.loading}
          {status === null && !loading && cta.submit}
          {status === true && !loading && cta.resultSuccess}
          {status === false && !loading && cta.resultError}
        </button>
      </div>
    </form>
  );
};

OfferApplicationForm.propTypes = {
  jobid: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    attachment: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    noFiles: PropTypes.string.isRequired,
    resultSuccess: PropTypes.string.isRequired,
    resultError: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired,
  }),
  formData: PropTypes.object,
};

OfferApplicationForm.defaultProps = {
  formData: {},
};

export default OfferApplicationForm;
