import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Title3 from "./Title3";
import Container from "./Container";
import DragGallery from "./DragGallery";
import OfferCard from "./OfferCard";
import CtaWithArrows from "./CtaWithArrows";
import DragInstructions from "./DragInstructions";
import useOnScreen from "../hooks/useOnScreen";

const CurrentOpenPositions = ({
  title,
  items,
  itemsTitle,
  cta,
  dragInstructions,
}) => {
  const [sliding, setSliding] = useState(false);
  const [triggerForward, setTriggerForward] = useState(0);

  const slidingCallback = (value) => {
    setSliding(value);
  };

  const main = useRef(null);
  const [isIn, setIsIn] = useState(false);

  useOnScreen(main, (intersecting) => {
    if (intersecting) setIsIn(intersecting);
  });

  return (
    <div className="bg-gray">
      <Container>
        <Title3
          text={title}
          color="purple"
          size="small"
          className="w-2/3 md:w-full mb-10 md:mb-12"
        />
        <p
          className="uppercase text-purple text-tiny md:text-base font-bold mb-6 md:mb-8 font-ttNorms"
          data-hax
        >
          {itemsTitle}
        </p>
        <div data-hax>
          <CtaWithArrows
            hrefTo={cta.hrefTo}
            icon={cta.icon}
            title={cta.title}
            color="green"
            className="md:hidden font-bold text-base pb-8"
          />
        </div>
        {items.length > 0 && (
          <div className="mb-6 md:mb-8" ref={main} data-hax>
            <DragGallery
              triggerForward={triggerForward}
              slidingCallback={slidingCallback}
            >
              {items.map((item, index) => (
                <OfferCard
                  sliding={sliding}
                  key={item.id}
                  isIn={isIn}
                  {...item}
                  index={index}
                  inGallery={true}
                  className="h-full"
                  layout="small"
                />
              ))}
            </DragGallery>
          </div>
        )}
        <div
          className="flex justify-between"
          data-hax
          onClick={() => {
            const next = triggerForward + 1;
            setTriggerForward(next);
          }}
        >
          <CtaWithArrows
            hrefTo={cta.hrefTo}
            icon={cta.icon}
            title={cta.title}
            color="green"
            className="hidden md:block"
          />
          <DragInstructions
            icon={dragInstructions.icon}
            text={dragInstructions.text}
            color="purple"
          />
        </div>
      </Container>
    </div>
  );
};

CurrentOpenPositions.propTypes = {
  title: PropTypes.string.isRequired,
  itemsTitle: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      title: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }).isRequired,
        hrefTo: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  dragInstructions: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
};

CurrentOpenPositions.defaultProps = {
  cta: {
    icon: {
      alt: "",
    },
  },
  items: [
    {
      image: {
        alt: "",
      },
      cta: {
        icon: {
          alt: "",
        },
      },
    },
  ],
  dragInstructions: {
    icon: {
      alt: "",
    },
  },
};

export default CurrentOpenPositions;
