import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const SocialNetworks = ({
  itemsArray,
  svgColor,
  backgroundColor,
  marginsDesktop,
  marginsMobile,
  className,
}) => {
  const handleClick = (item) => {
    trackCustomEvent({
      category: "Social Network",
      action: window.location.pathname,
      label: item.name,
    });
    if (item.onClick) {
      item.onClick();
    }
  };

  return (
    <div className={`${className} flex flex-row`}>
      {itemsArray.map((item, index) => (
        <a
          key={index}
          title={item.name}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          onClick={handleClick.bind(this, item)}
          href={item.url}
          className="cursor-pointer"
        >
          <div
            className={`transition-all bg-${backgroundColor} fill-${svgColor} hover:fill-${backgroundColor} hover:bg-transparent w-10 h-10 flex flex-row justify-center items-center border-solid border-${backgroundColor} border-2 rounded-icon mr-${marginsMobile.right} ml-${marginsMobile.left} md:mr-${marginsDesktop.right} md:ml-${marginsDesktop.left}`}
          >
            <ReactSVG src={item.icon} alt={item.name} />
          </div>
        </a>
      ))}
    </div>
  );
};

SocialNetworks.propTypes = {
  itemsArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      url: PropTypes.string,
      onClick: PropTypes.string,
    })
  ),
  svgColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  marginsDesktop: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  marginsMobile: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  className: PropTypes.string,
};

SocialNetworks.defaultProps = {
  svgColor: "white",
  backgroundColor: "purple-medium",
  marginsDesktop: {
    left: "0",
    right: "4",
  },
  marginsMobile: {
    left: "0",
    right: "4",
  },
  className: "",
};

export default SocialNetworks;
