import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import MenuPrimaryLink from "./MenuPrimaryLink";
import MenuSecondaryLink from "./MenuSecondaryLink";
import SPLink from "./SPLink";
import SPImage from "./SPImage";
import * as animationData from "../lib/animation-header.json";
import Lottie from "react-lottie";
import colors from "../lib/colors";

const animationDuration = 200;
let scrollPosition;
let body;

const Menu = ({ logo, title, items }) => {
  const slidingBar = useRef(null);
  const [slidingBarReady, setSlidingBarReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [logoAnimating, setLogoAnimating] = useState(false);
  let resetSlidingBarTimeout = null;

  useEffect(() => {
    body = document.getElementsByTagName("body")[0];
    shouldMoveSlidingBar(true);
    window.addEventListener("resize", shouldMoveSlidingBar);
    return () => window.removeEventListener("resize", shouldMoveSlidingBar);
  }, []);

  const topArrowProps = useSpring({
    transform: open ? `rotate(-45deg)` : `rotate(0deg)`,
    marginBottom: open ? -1 : 4,
    config: {
      duration: animationDuration / 2,
    },
  });
  const bottomArrowProps = useSpring({
    transform: open ? `rotate(45deg)` : `rotate(0deg)`,
    marginTop: open ? -1 : 4,
    config: {
      duration: animationDuration / 2,
    },
  });
  const menuItemsProps = useSpring({
    transform: open ? `translateY(0)` : `translateY(-100%)`,
    display: open ? `block` : `none`,
    delay: (key) => {
      if (!open && key === "display") {
        return animationDuration / 2;
      }
      return 0;
    },
    config: {
      duration: animationDuration / 2,
    },
  });

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData.default,
    rendererSettings: {
      // preserveAspectRatio: 'xMidYMid slice'
    },
  };

  const moveSlidingBar = (id) => {
    if (resetSlidingBarTimeout) {
      clearTimeout(resetSlidingBarTimeout);
      resetSlidingBarTimeout = null;
    }
    if (!slidingBar.current) return;

    const activeItem = items.find((item) => item.id === id);

    let fillColor = null;
    if (activeItem.color.includes("-")) {
      const parts = activeItem.color.split("-");
      if (parts.length > 1) {
        fillColor = colors[parts[0]][parts[1]];
      } else {
        fillColor = colors[parts[0]];
      }
    } else {
      fillColor = colors[activeItem.color];
      if (typeof fillColor === "object") {
        fillColor = fillColor["default"];
      }
    }

    const element = document.querySelector(`[data-link="${id}"]`);
    slidingBar.current.style.opacity = 1;
    slidingBar.current.style.height = `${element.offsetHeight}px`;
    slidingBar.current.style.transform = `translate3d(0, ${element.offsetTop}px, 0)`;
    slidingBar.current.style.backgroundColor = fillColor;
    if (!slidingBarReady) {
      setSlidingBarReady(true);
    }
  };

  const shouldMoveSlidingBar = (firstTime = false) => {
    if (!slidingBar.current) return;
    let hasActiveItem = false;
    items.forEach((item) => {
      if (item.active) {
        hasActiveItem = true;
        moveSlidingBar(item.id);
      }
    });
    if (!hasActiveItem) {
      slidingBar.current.style.opacity = 0;
    } else {
      slidingBar.current.style.opacity = 1;
      setSlidingBarReady(true);
    }
    if (firstTime) setTimeout(() => shouldMoveSlidingBar(), 600);
  };

  const resetMoveSlidingBar = () => {
    resetSlidingBarTimeout = setTimeout(() => shouldMoveSlidingBar(), 600);
  };

  return (
    <div
      className={`fixed top-0 left-0 right-0 w-full ${
        open && "h-full"
      } md:h-full md:w-7p lg:w-5p md:flex md:flex-col z-50 font-ttNorms bg-blue-dark`}
    >
      <div className="h-20 w-full bg-blue-dark flex justify-between z-30 relative md:hidden">
        <div className="bg-blue-dark py-6 px-4 flex-1 md:flex-auto md:px-0 md:flex md:items-center">
          <SPLink to="/">
            <SPImage
              className="h-8 md:h-10 block md:mx-auto"
              src={logo.mobile}
              alt={title}
            />
          </SPLink>
        </div>
        <div
          className="w-16 flex-shrink-0 bg-blue-dark flex flex-col items-center justify-center md:hidden"
          onClick={() => {
            if (!open) {
              scrollPosition = window.scrollY;
              // body.style.cssText = 'top: 0; bottom: 0; position: fixed;'
            } else {
              // body.style.cssText = ''
              window.scrollTo(0, scrollPosition);
            }
            setOpen(!open);
          }}
        >
          <animated.span
            style={topArrowProps}
            className="h-2px w-6 inline-block bg-white"
          />
          <animated.span
            style={bottomArrowProps}
            className="h-2px w-6 inline-block bg-white"
          />
        </div>
      </div>
      <SPLink
        to="/"
        className="hidden w-55 bg-blue-dark h-20 z-20 relative md:flex items-center justify-center rounded-br"
      >
        <div
          onMouseEnter={() => (!logoAnimating ? setLogoAnimating(true) : null)}
        >
          <Lottie
            options={lottieOptions}
            height={50}
            isStopped={!logoAnimating}
            speed={1.5}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => setLogoAnimating(false),
              },
            ]}
          />
        </div>
      </SPLink>
      <animated.div
        style={menuItemsProps}
        className={`hidden overflow-scroll md:overflow-hidden md:flex-important h-full bg-blue-dark p-10 md:h-auto md:flex-1 md:bg-white md:p-0 md:items-end md:justify-center md:no-translatey z-20 relative md:menu-shadow`}
      >
        <div className="md:flex md:flex-col-reverse md:items-center md:mb-12">
          <div
            ref={slidingBar}
            className={`hidden md:block absolute top-0 opacity-0 ${
              slidingBarReady ? "transition-all" : ""
            }`}
            style={{
              height: 30,
              width: 2,
              right: "50%",
              marginRight: -15,
            }}
          />
          {items.map((item) => (
            <React.Fragment key={item.id}>
              <MenuPrimaryLink
                {...item}
                active={item.active}
                onMouseEnterCallback={moveSlidingBar}
                onMouseLeaveCallback={resetMoveSlidingBar}
              />
              <div className="md:hidden">
                {item.subitems &&
                  item.subitems.map((subitem) => (
                    <MenuSecondaryLink
                      key={subitem.id}
                      color={item.color}
                      {...subitem}
                    />
                  ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </animated.div>
    </div>
  );
};

Menu.propTypes = {
  logo: PropTypes.shape({
    mobile: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      hrefTo: PropTypes.string,
      color: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

Menu.defaultProps = {
  items: [],
};

export default Menu;
