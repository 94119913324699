/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import htmlStyle from "../lib/htmlStyle";
import CtaWithArrows from "./CtaWithArrows";

const NewsItem = ({ icon, htmlText, cta }) => {
  return (
    <div className="relative w-full before:transition-all before:parallel-shadow-yellow-ready hover:before:parallel-shadow-yellow before:rounded-br">
      <div className="relative w-full mb-8 bg-white rounded-br flex p-8">
        <div className="bg-red fill-white w-8 h-8 flex justify-center items-center rounded-icon">
          <ReactSVG {...icon} />
        </div>
        <div className="flex-1 ml-8">
          <span
            className=""
            dangerouslySetInnerHTML={htmlStyle(htmlText, "blue-dark")}
          />
          <CtaWithArrows {...cta} color="red" className="mt-8" />
        </div>
      </div>
    </div>
  );
};

NewsItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }),
};

NewsItem.defaultProps = {};

export default NewsItem;
