import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";

const OfficeTourItem = ({ image, last }) => (
  <div className={`h-full ${last ? "" : "mr-8"}`}>
    <SPImage {...image} className="block h-full max-w-none" />
  </div>
);

OfficeTourItem.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  last: PropTypes.bool,
};

OfficeTourItem.defaultProps = {
  last: false,
};

export default OfficeTourItem;
