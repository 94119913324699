import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import * as easings from "d3-ease";
import { Keyframes } from "react-spring/renderprops";

const DragInstructions = ({ icon, text, color, className }) => {
  const Wobble = Keyframes.Spring({
    wiggle: async (next) => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        await next({
          transform: `translateX(8px)`,
          from: { transform: `translateX(0px)` },
          config: {
            duration: 400,
            easing: easings.easeQuadIn,
          },
        });
        await next({
          transform: `translateX(0px)`,
          from: { transform: `translateX(8px)` },
          config: {
            duration: 400,
            easing: easings.easeQuadIn,
          },
        });
      }
    },
  });

  return (
    <div
      className={`${className} flex flex-row items-center cursor-pointer outline-none no-tap`}
    >
      <span className={`pr-4 text-base-tiny font-ttNorms text-${color}`}>
        {text}
      </span>
      <Wobble state="wiggle">
        {(styles) => (
          <div style={styles}>
            <ReactSVG className={`fill-${color} flex items-center`} {...icon} />
          </div>
        )}
      </Wobble>
    </div>
  );
};

DragInstructions.propTypes = {
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DragInstructions.defaultProps = {
  className: "",
  icon: {
    alt: "",
  },
};

export default DragInstructions;
