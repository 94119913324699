import React from "react";
import PropTypes from "prop-types";
import Title3 from "./Title3";
import Button from "./Button";
import Container from "./Container";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";
import Grid from "./Grid";
import Form from "./Form";

const StayUpdated = ({ title, htmlText, cta, image, form }) => {
  return (
    <Container>
      <Grid className="grid">
        <div className="col-start-1 col-end-11 row-start-1 row-end-2 md:col-end-7 self-end">
          <Title3 color="green" text={title} size="small" />
        </div>
        <div className="full-columns row-start-2 row-end-3 md:col-start-1 md:col-end-7">
          {!form ? (
            <>
              <div
                className="my-10 md:my-12"
                dangerouslySetInnerHTML={htmlStyle(htmlText, "green")}
                data-hax
              />
              <div data-hax className="mb-16 md:mb-0">
                <Button {...cta} backgroundColor="purple" color="white" />
              </div>
            </>
          ) : (
            <div className="pb-16">
              <Form {...form} />
            </div>
          )}
        </div>
        <div className="full-columns row-start-3 row-end-4 md:row-start-1 md:row-end-3 md:col-start-7 md:col-end-13 flex justify-center">
          <div data-hax>
            <SPImage
              className={`${
                !form
                  ? "-bottom-last-image md:-bottom-last-image-md lg:-bottom-last-image-lg"
                  : "md:ml-12 lg:ml-64 -bottom-last-image-with-form md:-bottom-last-image-md-with-form lg:-bottom-last-image-lg-with-form"
              } relative right-0 w-300px md:w-500px lg:w-600px`}
              {...image}
            />
          </div>
        </div>
      </Grid>
    </Container>
  );
};

StayUpdated.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["email", "text"]),
      })
    ),
    color: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
  }),
};

StayUpdated.defaultProps = {
  form: null,
};

export default StayUpdated;
