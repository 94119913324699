import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CtaWithArrows from "./CtaWithArrows";
import SPImage from "./SPImage";
import SPLink from "./SPLink";
import Grid from "./Grid";
import * as easings from "d3-ease";
import { useSpring, animated } from "react-spring";
import useOnScreen from "../hooks/useOnScreen";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

let isScrolling = false;
let timeout;

const toggleScrolling = async (e) => {
  if (e.type === "touchend") isScrolling = false;
  if (e.type === "scroll") {
    clearTimeout(timeout);
    isScrolling = true;
    timeout = setTimeout(() => {
      isScrolling = false;
    }, 100);
  }
};

const OfferCard = ({
  title,
  cta,
  image,
  layout = "w-full",
  index,
  inGallery,
  isIn,
  sliding,
}) => {
  const main = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [meIsIn, setMeIsIn] = useState(isIn);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", toggleScrolling, { passive: true });
    window.addEventListener("touchend", toggleScrolling);
  }, []);

  if (!inGallery) {
    useOnScreen(main, (intersecting) => {
      if (intersecting) setMeIsIn(intersecting);
    });
  }

  let cardProps = {};

  if (inGallery) {
    const transformX = 300 + index * index * 100;
    cardProps = useSpring(
      isIn && !hasAnimated
        ? {
            to: { transform: `translateX(0px) scale(1)`, opacity: 1 },
            from: {
              transform: `translateX(${transformX}px) scale(0.9)`,
              opacity: 0,
            },
            delay: 150 * index,
            config: {
              duration: 800,
              easing: easings.easeSinOut,
            },
            onRest: () => setHasAnimated(true),
          }
        : {
            transform: hasAnimated
              ? `translateX(0px) scale(1)`
              : `translateX(${transformX}px) scale(0.9)`,
            opacity: hasAnimated ? 1 : 0,
          }
    );
  } else {
    cardProps = useSpring(
      meIsIn
        ? {
            to: { opacity: 1, transform: `translate3d(0vw, 0, 0)` },
            from: { opacity: 0, transform: `translate3d(30vw, 0, 0)` },
            delay: 50 * index,
            reset: true,
            config: {
              duration: 800,
              easing: easings.easeSinOut,
            },
          }
        : {
            opacity: 0,
            transform: `translate3d(30vw, 0, 0)`,
          }
    );
  }

  const handleClick = () => {
    trackCustomEvent({
      category: "Offer Card",
      action: window.location.pathname,
      label: cta.hrefTo,
    });
  };

  const shouldTrigger = () => {
    if (!sliding && !isScrolling) {
      setTrigger(!trigger);
    }
  };

  return (
    <animated.div
      onMouseUp={() => {
        shouldTrigger();
      }}
      onTouchEnd={() => {
        shouldTrigger();
      }}
      ref={main}
      style={cardProps}
      className="mr-4 pb-2 will-change-transform transition-all hover:scale03 cursor-pointer"
    >
      <div>
        <SPLink trigger={trigger} title={title} to={cta.hrefTo} />
      </div>
      <div
        className={`before:parallel-shadow-purple-light h-full relative ${
          layout === "small" && "w-72"
        }`}
      >
        <Grid className="grid-row-gap-small h-full relative grid bg-white p-6">
          <div className="w-full row-start-1 row-end-2 col-start-1 col-end-2">
            <SPImage
              style={{ width: 20, height: 20 }}
              {...image}
              className="w-full"
            />
          </div>
          <div
            className={`
            ${
              layout === "small"
                ? "md:row-start-1 row-start-2 md:row-end-2 row-end-3 md:col-start-3 col-start-1 col-end-13 pb-4"
                : "row-start-1 md:col-start-2 col-start-3 col-end-10 pb-4 md:pb-0"
            }
          `}
          >
            <h6
              className={`font-ttNorms leading-tight w-full text-green text-base-big font-bold block`}
            >
              {title}
            </h6>
          </div>
          <div
            className={
              layout === "small"
                ? "self-end md:col-start-3 col-start-1 col-end-13 md:row-start-2 row-start-3 md:row-end-3 row-end-4"
                : "row-start-2 col-start-2 col-end-13 md:col-start-11 md:col-end-13 md:row-start-1 pl-6 pt-1 pb-4 md:pb-0"
            }
          >
            <CtaWithArrows
              {...cta}
              className="events-none select-none"
              onClick={handleClick}
              color="purple"
              fake={true}
            />
          </div>
        </Grid>
      </div>
    </animated.div>
  );
};

OfferCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  cta: PropTypes.shape({
    hrefTo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number,
  inGallery: PropTypes.bool,
  isIn: PropTypes.bool,
  layout: PropTypes.oneOf(["small", "w-full"]),
  sliding: PropTypes.bool,
};

OfferCard.defaultProps = {
  index: 0,
  inGallery: false,
  isIn: false,
  layout: "small",
  sliding: false,
};

export default OfferCard;
