module.exports = {
  en: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/index.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "en",
              title: "Socialpoint Ad Partners",
              partner: "Partner",
              privacyPolicy: "Privacy Policy",
              userChoice: "User's Choice",
            },
          },
        ],
      },
    ],
  },
  fr: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/fr.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "fr",
              title: "Partenaires publicitaires Socialpoint",
              partner: "Partenaire",
              privacyPolicy: "Politique de confidentialité",
              userChoice: "Choix de l'utilisateur",
            },
          },
        ],
      },
    ],
  },
  nl: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/nl.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "nl",
              title: "Socialpoint Reclamepartners",
              partner: "Partner",
              privacyPolicy: "Privacybeleid",
              userChoice: "Gebruikerskeuze",
            },
          },
        ],
      },
    ],
  },
  de: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/de.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "de",
              title: "Socialpoint-Werbepartner",
              partner: "Partner",
              privacyPolicy: "Datenschutzrichtlinie",
              userChoice: "Nutzerentscheidung",
            },
          },
        ],
      },
    ],
  },
  es: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/es.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "es",
              title: "Socios publicitarios de Socialpoint",
              partner: "Socio",
              privacyPolicy: "Política de privacidad",
              userChoice: "Elección del usuario",
            },
          },
        ],
      },
    ],
  },
  mx: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/mx.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "mx",
              title: "Socios publicitarios de Socialpoint",
              partner: "Socio",
              privacyPolicy: "Política de privacidad",
              userChoice: "Elección del usuario",
            },
          },
        ],
      },
    ],
  },
  gr: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/gr.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "gr",
              title: "Συνεργάτες διαφήμισης Socialpoint",
              partner: "Συνεργάτης",
              privacyPolicy: "Πολιτική απορρήτου",
              userChoice: "Επιλογή του χρήστη",
            },
          },
        ],
      },
    ],
  },
  pl: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/pl.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "pl",
              title: "Partnerzy reklamowi Socialpoint",
              partner: "Partner",
              privacyPolicy: "Polityka prywatności",
              userChoice: "Wybór użytkownika",
            },
          },
        ],
      },
    ],
  },
  cz: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/cz.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "cz",
              title: "Reklamní partneři Socialpoint",
              partner: "Partner",
              privacyPolicy: "Zásady ochrany soukromí",
              userChoice: "Volba uživatelů",
            },
          },
        ],
      },
    ],
  },
  ru: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/ru.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "ru",
              title: "Рекламные партнеры Socialpoint",
              partner: "Партнер",
              privacyPolicy: "Политика конфиденциальности",
              userChoice: "Выбор пользователя",
            },
          },
        ],
      },
    ],
  },
  pt: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/pt.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "pt",
              title: "Parceiros publicitários Socialpoint",
              partner: "Parceiro",
              privacyPolicy: "Política de Privacidade",
              userChoice: "Escolha dos utilizadores",
            },
          },
        ],
      },
    ],
  },
  it: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/it.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "it",
              title: "Partner pubblicitari di Socialpoint",
              partner: "Partner",
              privacyPolicy: "Informativa sulla privacy",
              userChoice: "Preferenze dell'utente",
            },
          },
        ],
      },
    ],
  },
  tu: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/tu.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "tu",
              title: "Socialpoint Reklam Ortakları",
              partner: "İş Ortağı",
              privacyPolicy: "Gizlilik Politikası",
              userChoice: "Kullanıcı Seçimi",
            },
          },
        ],
      },
    ],
  },
  ar: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/ar.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "ar",
              title: "شركاء إعلانات Socialpoint",
              partner: "شريك",
              privacyPolicy: "سياسة الخصوصية",
              userChoice: "اختيار المستخدم",
            },
          },
        ],
      },
    ],
  },
  tc: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/tc.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "tc",
              title: "Socialpoint廣告夥伴",
              partner: "夥伴",
              privacyPolicy: "隱私權原則",
              userChoice: "使用者選擇",
            },
          },
        ],
      },
    ],
  },
  kr: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/kr.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "kr",
              title: "Socialpoint 광고 파트너",
              partner: "파트너",
              privacyPolicy: "개인정보 처리방침",
              userChoice: "사용자 지정",
            },
          },
        ],
      },
    ],
  },
  jp: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/jp.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "jp",
              title: "Socialpoint 広告パートナー",
              partner: "パートナー",
              privacyPolicy: "プライバシー ポリシー",
              userChoice: "ユーザーの選択",
            },
          },
        ],
      },
    ],
  },
  sc: {
    breadcrumb: { items: [undefined, { hrefTo: "/ad-partners/sc.html" }] },
    components: [
      {
        components: [
          {
            props: {
              selectedLang: "sc",
              title: "Socialpoint广告伙伴",
              partner: "伙伴",
              privacyPolicy: "隐私政策",
              userChoice: "使用者选择",
            },
          },
        ],
      },
    ],
  },
};
