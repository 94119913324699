/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import useDidMount from "../hooks/useDidMount";
import * as easings from "d3-ease";

let lastX;
let isScrolling = false;

const timer = (ms) => {
  return new Promise((res) => setTimeout(res, ms));
};

const toggleScrolling = async (e) => {
  if (e.type === "touchend") isScrolling = false;
  if (e.type === "touchstart") {
    isScrolling = true;
    await timer();
    isScrolling = false;
  }
  if (e.type === "scroll") isScrolling = true;
};

const TimeLineDrag = ({
  children,
  activeSlideCallback,
  activeSlide,
  animationConfig,
}) => {
  const didMount = useDidMount();
  const gallery = useRef(null);
  const galleryContent = useRef(null);
  const [props, set] = useSpring(() => ({
    transform: `translate3d(0%, 0px, 0px)`,
  }));

  const setSpring = (p) => {
    set({
      transform: `translate3d(${p}%, 0px, 0px)`,
      config: {
        duration: animationConfig.duration,
        easing: easings.easePolyOut,
        delay: 3000,
      },
    });
  };

  useEffect(() => {
    if (didMount) {
      const p = -(100 / galleryContent.current.children.length) * activeSlide;
      setSpring(p);
      const prev = parseInt(gallery.current.getAttribute("data-current"));
      gallery.current.setAttribute("data-current", activeSlide);
      activeSlideCallback(prev, activeSlide);
    }
  }, [activeSlide]);

  useEffect(() => {
    window.addEventListener("scroll", toggleScrolling, { passive: true });
    window.addEventListener("touchend", toggleScrolling);
    window.addEventListener("touchstart", toggleScrolling);

    gallery.current.setAttribute("data-current", activeSlide);
    document.addEventListener("resize", handleResize);
    const Hammer = require("hammerjs");

    const h = new Hammer(gallery.current, {
      inputClass: Hammer.TouchMouseInput,
    });

    h.on("panend", async (e) => {
      if (e.pointerType === "touch" && isScrolling) return;
      let current = parseInt(gallery.current.getAttribute("data-current"));
      lastX = parseFloat(galleryContent.current.getAttribute("data-x"));
      const percentage =
        ((100 / children.length) * e.deltaX) / window.innerWidth;
      if (percentage < 0) goToSlide(current + 1);
      else if (percentage > 0) goToSlide(current - 1);
      else goToSlide(current);
    });
    const goToSlide = (number) => {
      if (number < 0 || number === children.length) return;
      activeSlide = number;
      const p = -(100 / children.length) * activeSlide;
      setSpring(p);
      const prev = parseInt(gallery.current.getAttribute("data-current"));
      activeSlideCallback(prev, activeSlide);
    };

    handleResize();

    setTimeout(() => {
      handleResize();
    }, 1000);
    return function cleanup() {
      document.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", toggleScrolling);
      window.removeEventListener("touchend", toggleScrolling);
      window.addEventListener("touchstart", toggleScrolling);
    };
  }, []);

  const handleResize = () => {
    gallery.current.style.height = `${galleryContent.current.offsetHeight}px`;
  };

  return (
    <div className="relative" ref={gallery}>
      <div className="relative overflow-visible h-full">
        <animated.div
          ref={galleryContent}
          className="inline-flex cursor-ew-resize active:cursor-grab"
          data-x="0"
          style={props}
        >
          {children}
        </animated.div>
      </div>
    </div>
  );
};

TimeLineDrag.propTypes = {
  children: PropTypes.node.isRequired,
  activeSlideCallback: PropTypes.func,
  activeSlide: PropTypes.number,
  animationConfig: PropTypes.shape({
    duration: PropTypes.number,
    delay: PropTypes.number,
  }),
  changeSlideFromOutside: PropTypes.bool,
};

TimeLineDrag.defaultProps = {
  activeSlideCallback: () => {},
  animationConfig: {
    duration: 1000,
    delay: 0,
  },
  keepContent: true,
};

export default TimeLineDrag;
