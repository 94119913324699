import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import OfferCard from "./OfferCard";
import * as easings from "d3-ease";
import { useSpring, animated } from "react-spring";
import useOnScreen from "../hooks/useOnScreen";

const OfferGridSection = ({ title, offers, offerCard }) => {
  const main = useRef(null);
  const [isIn, setIsIn] = useState(false);

  useOnScreen(main, (intersecting) => {
    if (intersecting) setIsIn(intersecting);
  });

  // const titleProps = useSpring(isIn ? {
  //   to: { opacity: 1, transform: `translate3d(0vw, 0, 0)`, willChange: 'auto', },
  //   from: { opacity: 0, transform: `translate3d(30vw, 0, 0)` },
  //   config: {
  //     duration: 800,
  //     easing: easings.easeSinOut,
  //   }
  // } : {
  //   opacity: 0,
  //   transform: `translate3d(30vw, 0, 0)`,
  //   willChange: 'transform, opacity',
  // })

  return (
    <div className="mb-8 flex flex-col w-full">
      <animated.h3
        ref={main}
        className="py-4 text-tiny md:text-base uppercase text-purple font-bold font-ttNorms"
        // style={}
      >
        {title}
      </animated.h3>
      {offers.map((item, index) => {
        return (
          <div className="flex flex-col my-2" key={`${item.title}-${index}`}>
            <OfferCard
              index={index}
              layout="w-full"
              title={item.title}
              image={offerCard.image}
              cta={{
                title: offerCard.cta.title,
                hrefTo: item.hrefTo,
                icon: offerCard.cta.icon,
              }}
              layoutClass={{
                wrapper: "md:flex md:items-center pl-24",
                cta:
                  "md:mx-4 md:flex-1 md:flex md:items-center md:justify-between",
                hole: "absolute md:mt-3",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

OfferGridSection.propTypes = {
  offerCard: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }),
  title: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      hrefTo: PropTypes.string,
    })
  ),
};

OfferGridSection.defaultProps = {
  offerCard: {
    image: {
      alt: "",
    },
    icon: {
      alt: "",
    },
  },
  bottomCta: null,
  title: null,
};

export default OfferGridSection;
