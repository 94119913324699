import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Wrapper from "./Wrapper";
import { useSpring, animated } from "react-spring";
import useDidMount from "../hooks/useDidMount";
import colors from "../lib/colors";
import DragInstructions from "./DragInstructions";
import htmlStyle from "../lib/htmlStyle";
import SPImage from "./SPImage";
import Grid from "./Grid";
import Title2 from "./Title2";
import globalVar from "../lib/globalVar";
import TimeLineDrag from "./TimeLineDrag";

const StepItem = ({ year, image, text }, index, activeSlide, count) => {
  const didMount = useDidMount();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (index + 1 === count && document.getElementById("reference")) {
      setWidth(document.getElementById("reference").offsetWidth);
    }
  }, []);

  const lineEffect = {
    to: {
      width: index + 1 === count ? width : 450,
      height: 15,
      top: 6,
      left: 13,
    },
    from: {
      width: 0,
      height: 15,
      left: 13,
      top: 6,
    },
    config: {
      duration: 1000,
    },
  };
  const svgEffect = {
    to: {
      fill: colors.purple.default,
      color: colors.purple.default,
    },
    from: {
      fill: "white",
      color: colors.white,
    },
    config: {
      duration: 1000,
    },
  };
  const contentEffect = {
    to: {
      opacity: 1,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 5000,
    },
  };

  const yearEffect = {
    to: {
      opacity: 0.6,
    },
    from: {
      opacity: 0,
    },
    config: {
      duration: 1000,
    },
  };

  const [yearProps, setYearProps] = useSpring(() => ({ opacity: 0 }));
  const [textProps, setTextProps] = useSpring(() => ({ opacity: 0 }));
  const [imageProps, setImageProps] = useSpring(() => ({ opacity: 0 }));

  const [lineProps, setLineProps] = useSpring(() => ({
    width: 0,
    top: 6,
    left: 13,
    height: 15,
  }));

  const [svgProps, setSvgProps] = useSpring(() => ({
    fill: "white",
  }));

  useEffect(() => {
    if (index === 0) {
      setSvgProps(svgEffect);
      setContentSpring("forwards");
    }
  }, []);

  const setContentSpring = (direction) => {
    if (direction === "forwards") {
      contentEffect.config.duration = 1000;
      setYearProps({ ...yearEffect, delay: 900 });
      setTextProps({ ...contentEffect, delay: 700 });
      setImageProps(contentEffect);
      return;
    }
    if (direction === "backwards") {
      const rev = {
        to: {
          opacity: 0,
        },
        from: {
          opacity: 1,
        },
        config: {
          duration: 100,
        },
      };
      setYearProps(rev);
      setTextProps(rev);
      setImageProps(rev);
      return;
    }
  };

  useEffect(() => {
    if (didMount) {
      if (activeSlide.prev === 0 && activeSlide.current === 0 && index !== 0) {
        lineEffect.reverse = true;
        svgEffect.reverse = true;
        svgEffect.delay = index;
        svgEffect.config.duration = index;
        setSvgProps(svgEffect);
        setLineProps(lineEffect);
      }
      // forwards
      if (activeSlide.current === index && activeSlide.prev !== index) {
        lineEffect.reverse = false;
        svgEffect.reverse = false;
        contentEffect.reverse = false;
        svgEffect.delay = 0;
        contentEffect.delay = 500;
        setSvgProps(svgEffect);
        setLineProps(lineEffect);
        setContentSpring("forwards");
      }
      // backwards
      if (index === activeSlide.prev && activeSlide.current < index) {
        lineEffect.reverse = true;
        svgEffect.reverse = true;
        svgEffect.delay = 1000;
        svgEffect.config.duration = 1500;
        setSvgProps(svgEffect);
        setLineProps(lineEffect);
      }
      if (
        index + 1 <= activeSlide.current ||
        index - 1 >= activeSlide.current
      ) {
        setContentSpring("backwards");
      }
    }
  }, [activeSlide]);

  return (
    <div key={index} className="w-68 md:w-48 mb-12">
      <animated.p
        className={`pb-6 font-bold ${
          activeSlide.current >= index ? "text-purple" : "text-white md:block"
        } font-ttNorms`}
        style={svgProps}
      >
        {year}
      </animated.p>
      <animated.div
        className="relative flex flex-row flex-start md:w-80vh"
        style={svgProps}
      >
        <hr
          className={`absolute ${
            index === 0 ? "border-purple" : "border-white"
          }`}
          style={{
            top: 6,
            left: 13,
            right: 0,
            width: index + 1 === count && width,
          }}
        />
        {index !== 0 && (
          <animated.hr className="absolute border-purple" style={lineProps} />
        )}
        <svg
          width="14"
          height="14"
          xmlns="http://www.w3.org/2000/svg"
          className="z-10"
        >
          <path
            d="M8.813 1.742l3.445 3.445c.63.63.793.925.9 1.278.108.354.108.716 0 1.07-.107.353-.27.648-.9 1.278l-3.445 3.445c-.63.63-.925.793-1.278.9a1.817 1.817 0 01-1.07 0c-.353-.107-.648-.27-1.278-.9L1.742 8.813c-.63-.63-.793-.925-.9-1.278a1.817 1.817 0 010-1.07c.107-.353.27-.648.9-1.278l3.445-3.445c.63-.63.925-.793 1.278-.9a1.817 1.817 0 011.07 0c.353.107.648.27 1.278.9z"
            fillRule="evenodd"
          />
        </svg>
      </animated.div>
      <div
        className={`pt-8 md:w-80vh ${
          activeSlide.current === index ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex flex-col md:flex-row">
          <animated.div style={imageProps}>
            <SPImage
              className="max-w-120p md:max-w-none max-w-lg bg-white pointer-events-none select-none"
              {...image}
            />
          </animated.div>
          <div className="flex flex-col justify-end md:pl-12">
            <animated.span
              style={yearProps}
              className="hidden opacity-50 text-purple md:block md:text-big font-ttNorms font-bold"
            >
              {year}
            </animated.span>
            <animated.span
              style={textProps}
              className="pt-8 md:pt-0 text-purple md:w-300px"
            >
              {text}
            </animated.span>
          </div>
        </div>
      </div>
    </div>
  );
};

let timeout;

const OurStory = ({ steps, dragInstructions, image, title, htmlText }) => {
  const [activeSlide, setActiveSlide] = useState({ prev: 0, current: 0 });

  const activeSlideCallback = (prev, current) => {
    if (activeSlide.current === steps.length - 1) {
      timeout = setTimeout(() => {
        // setActiveSlide({ prev: 0, current: 0 })
      }, globalVar.galleryTimeout);
      return;
    }
    clearTimeout(timeout);
    setActiveSlide({ prev, current });
  };

  return (
    <Wrapper backgroundColor="purple-light">
      <Container>
        <Grid className="grid mb-20 md:mb-32">
          <div className="full-columns pb-8 md:pb-0 md:row-start-1 md:row-end-2 md:col-start-1 md:col-end-6 self-center">
            <Title2 text={title} size="medium" color="purple" />
            <div
              data-hax
              className="pt-10"
              dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
            />
          </div>
          <div
            data-hax
            className="mobile-image-layout md:row-start-1 md:row-end-2 md:col-start-7 lg:col-start-8 md:col-end-12 md:-mt-20rem z-10"
          >
            <SPImage className="w-full" {...image} />
          </div>
        </Grid>
        <div className="relative">
          <div
            className="outline-none"
            onClick={() => {
              if (activeSlide.current !== steps.length - 1) {
                setActiveSlide({
                  current: activeSlide.current + 1,
                  prev: activeSlide.current,
                });
                return;
              }
              setActiveSlide({ prev: 0, current: 0 });
            }}
          >
            <div className="pb-8" id="reference">
              <DragInstructions
                icon={dragInstructions.icon}
                text={dragInstructions.text}
                color="purple"
                className="justify-end mt-4"
              />
            </div>
          </div>
          <div>
            <TimeLineDrag
              pagination={true}
              activeSlideCallback={activeSlideCallback}
              activeSlide={activeSlide.current}
              animationConfig={{
                duration: 1500,
                delay: 1000,
              }}
            >
              {steps.map((step, index) =>
                StepItem(step, index, activeSlide, steps.length)
              )}
            </TimeLineDrag>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

StepItem.propTypes = {
  year: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

OurStory.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  dragInstructions: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
};

OurStory.defaultProps = {
  dragInstructions: {
    icon: {
      alt: "",
    },
  },
  imageAltText: "",
};

export default OurStory;
