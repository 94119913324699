const menu = (activeId = 1, activeGameId = 0) => ({
  title: "Socialpoint",
  logo: {
    mobile:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logo/socialpoint-mobile.svg",
    icon:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logo/triangle.svg",
    text:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logo/socialpoint.svg"
  },
  items: [
    {
      id: "4",
      title: "Join us",
      hrefTo: "/join-us",
      active: activeId === 4,
      color: "green"
    },
    {
      id: "1",
      title: "Games",
      hrefTo: "/games",
      active: activeId === 1,
      color: "yellow",
      subitems: [
        {
          id: "7",
          title: "Top Troops",
          image: "",
          hrefTo: "/games/top-troops",
          active: activeGameId === 7
        },
        {
          id: "2",
          title: "Dragon City",
          image:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/menu/square_cards/Square_Card_DC.jpg",
          hrefTo: "/games/dragon-city",
          active: activeGameId === 2
        },
        {
          id: "6",
          title: "Two Dots",
          image: "",
          hrefTo: "/games/two-dots",
          active: activeGameId === 6
        },
        {
          id: "1",
          title: "Monster Legends",
          image:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/menu/square_cards/Square_Card_ML.jpg",
          hrefTo: "/games/monster-legends",
          active: activeGameId === 1
        },
        {
          id: "3",
          title: "Word Life",
          image:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/menu/square_cards/Square_Card_WL.jpg",
          hrefTo: "/games/word-life",
          active: activeGameId === 3
        },
        {
          id: "4",
          title: "Tasty Town",
          image:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/menu/square_cards/Square_Card_TT.jpg",
          hrefTo: "/games/tasty-town",
          active: activeGameId === 4
        },
        {
          id: "5",
          title: "World Chef",
          image:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/menu/square_cards/Square_Card_WC.jpg",
          hrefTo: "/games/world-chef",
          active: activeGameId === 5
        },
      ]
    },
    {
      id: "2",
      title: "Support",
      hrefTo: "/support",
      active: activeId === 2,
      color: "blue-light"
    },
    {
      id: "3",
      title: "About",
      hrefTo: "/about",
      active: activeId === 3,
      color: "red"
    }
  ]
});
export default menu;
