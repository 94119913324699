import React from "react";
import PropTypes from "prop-types";

const Multiline = ({ text }) => {
  const parts = typeof text === "string" ? text.split("\n") : ["", ""];
  return parts.map((part, index) => {
    return (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && <br />}
      </React.Fragment>
    );
  });
};

Multiline.propTypes = {
  text: PropTypes.string.isRequired,
};

Multiline.defaultProps = {};

export default Multiline;
