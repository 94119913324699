import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Title3 from "./Title3";
import Button from "./Button";
import Container from "./Container";
import DragGallery from "./DragGallery";
import OfferCard from "./OfferCard";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";
import DragInstructions from "./DragInstructions";
import useOnScreen from "../hooks/useOnScreen";
import Grid from "./Grid";

const JumpAboard = ({
  title,
  htmlText,
  cta,
  image,
  items,
  itemsTitle,
  dragInstructions,
}) => {
  const main = useRef(null);
  const scale = useRef(null);
  const [isIn, setIsIn] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [triggerForward, setTriggerForward] = useState(0);

  const slidingCallback = (value) => {
    setSliding(value);
  };

  useOnScreen(main, (intersecting) => {
    if (intersecting) setIsIn(intersecting);
  });

  return (
    <div className="overflow-visible z-10">
      <Container>
        <Grid className="grid">
          <div className="md:mt-64 full-columns row-start-1 row-end-2 md:col-start-1 md:col-end-7">
            <div className="md:w-2/3 w-full">
              <Title3 color="purple" text={title} size="medium" />
            </div>
            <div
              data-hax
              className="w-full my-12"
              dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
            />
            <div data-hax>
              <Button {...cta} backgroundColor="green" color="white" />
            </div>
          </div>
          <div className="pt-16 md:pb-32 md:pt-0 full-columns row-start-3 row-end-4 md:row-start-1 md:row-end-3 md:col-start-7 md:col-end-13">
            <div data-hax>
              <SPImage data-bax="-80, 80" {...image} />
            </div>
          </div>
        </Grid>
        <div>
          {items.length > 0 && (
            <div
              className="relative z-10 mt-12 md:-mt-24 lg:-mt-64"
              ref={main}
              data-hax
            >
              <span className="block uppercase text-purple text-base font-bold font-ttNorms pb-6 md:pb-8">
                {itemsTitle}
              </span>
              <DragGallery
                slidingCallback={slidingCallback}
                triggerForward={triggerForward}
              >
                {items.map((item, index) => (
                  <OfferCard
                    sliding={sliding}
                    useRef={scale}
                    key={item.id}
                    isIn={isIn}
                    {...item}
                    index={index}
                    inGallery={true}
                    className="h-full"
                    layout="small"
                  />
                ))}
              </DragGallery>
              <div
                className="pt-8"
                onClick={() => {
                  const next = triggerForward + 1;
                  setTriggerForward(next);
                }}
              >
                <DragInstructions
                  icon={dragInstructions.icon}
                  text={dragInstructions.text}
                  color="purple"
                  className="justify-start md:justify-end"
                />
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

JumpAboard.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string,
    hrefTo: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        hrefTo: PropTypes.string.isRequired,
      }),
      layout: PropTypes.string,
    })
  ),
  itemsTitle: PropTypes.string.isRequired,
  dragInstructions: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }),
};

JumpAboard.defaultProps = {
  items: [],
};

export default JumpAboard;
