import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useDidMount from "../hooks/useDidMount";
import CustomCursor from "../components/CustomCursor";
import useIsMobile from "../hooks/useIsMobile";

let isScrolling = false;
let offsetX = 50;
let offsetY = 25;

const outerWidth = (el) => {
  let width = el.offsetWidth;
  const style = getComputedStyle(el);
  const marginRight = style.marginRight.split("p")[0];
  const marginLeft = style.marginLeft.split("p")[0];
  width += parseInt(marginRight) + parseInt(marginLeft);
  return width;
};

const timer = (ms) => {
  return new Promise((res) => setTimeout(res, ms));
};

const toggleScrolling = async (e) => {
  if (e.type === "touchend") isScrolling = false;
  if (e.type === "touchstart") {
    isScrolling = true;
    await timer(100);
    isScrolling = false;
  }
  if (e.type === "scroll") isScrolling = true;
};

const DragGrallery = ({
  children,
  slidingCallback,
  triggerForward,
  childIsAbsolute,
}) => {
  const isMobile = useIsMobile();
  let translateX = 0;
  const gallery = useRef(null);
  const galleryContent = useRef(null);
  const didMount = useDidMount();
  const [mousePosition, setMousePosition] = useState({
    x: null,
    y: null,
    opacity: 0,
    state: "out",
  });
  const [mouseDown, setMouseDown] = useState(false);

  const handleClick = ({ type }) => {
    if (type === "mousedown") setMouseDown(true);
    if (type === "mouseup") setMouseDown(false);
  };

  const handleHover = (e) => {
    const { top, left } = gallery.current.getBoundingClientRect();
    if (e.type === "mousemove" || e.type === "mouseenter") {
      setMousePosition({
        ...mousePosition,
        x: e.clientX - left - offsetX,
        y: e.clientY - top - offsetY,
        state: "in",
      });
    }

    if (e.type === "mouseleave") {
      setMousePosition({
        ...mousePosition,
        state: "out",
      });
    }
  };

  const fadeAnimation = (state) => {
    if (state === "in") {
      if (mousePosition.opacity < 1) {
        setMousePosition({
          ...mousePosition,
          opacity: mousePosition.opacity + 0.1,
        });
      }
    }
    if (state === "out") {
      if (mousePosition.opacity > 0) {
        setMousePosition({
          ...mousePosition,
          opacity: mousePosition.opacity - 0.1,
        });
      }
    }
  };

  useEffect(() => {
    if (didMount) {
      window.requestAnimationFrame(() => fadeAnimation(mousePosition.state));
    }
  }, [mousePosition]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClick);
    window.addEventListener("mouseup", handleClick);

    window.addEventListener("scroll", toggleScrolling, { passive: true });

    window.addEventListener("touchend", toggleScrolling);
    window.addEventListener("touchstart", toggleScrolling);

    document.addEventListener("resize", handleResize);
    const Hammer = require("hammerjs");

    const h = new Hammer(gallery.current, {
      inputClass: Hammer.TouchMouseInput,
    });

    h.on("pan", (e) => {
      const mX =
        galleryContent.current.offsetWidth -
        galleryContent.current.parentNode.offsetWidth;
      if (e.pointerType === "touch") {
        if (isScrolling) return;
      }
      let totalDeltaX = parseInt(
        galleryContent.current.getAttribute("delta-x")
      );
      slidingCallback(true);
      galleryContent.current.style.transition = "none";
      translateX = totalDeltaX + e.deltaX;
      if (translateX < 0 && Math.abs(translateX) < mX) {
        galleryContent.current.style.transform = `translate3d(${translateX}px, 0, 0)`;
        return;
      }
      if (Math.abs(totalDeltaX) >= mX && Math.abs(totalDeltaX) > mX) {
        translateX = -mX;
        galleryContent.current.style.transform = `translate3d(-${translateX}px, 0, 0)`;
      }
    });
    h.on("panend", triggerSlide);

    handleResize();

    setTimeout(() => {
      handleResize();
    }, 1000);

    return function cleanup() {
      document.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", toggleScrolling);
      window.removeEventListener("touchend", toggleScrolling);
      window.removeEventListener("mousedown", handleClick);
      window.removeEventListener("mouseup", handleClick);
    };
  }, []);

  useEffect(() => {
    if (didMount) {
      triggerSlide();
    }
  }, [triggerForward]);

  const triggerSlide = (e) => {
    setTimeout(() => {
      slidingCallback(false);
    }, 300);
    const cWidth = outerWidth(galleryContent.current.children[0]);
    const mX =
      galleryContent.current.offsetWidth -
      galleryContent.current.parentNode.offsetWidth;
    let totalDeltaX = parseInt(galleryContent.current.getAttribute("delta-x"));
    let deltaX = 0;
    let forced;
    if (typeof e === "object") {
      deltaX = e.deltaX;
      forced = false;
    } else {
      deltaX = -cWidth;
      forced = true;
    }
    galleryContent.current.style.transition = "transform 0.5s";

    if (Math.abs(deltaX + totalDeltaX) > mX && deltaX < 0) {
      galleryContent.current.style.transform = `translate3d(-${mX}px, 0, 0)`;
      totalDeltaX = -mX;
      galleryContent.current.setAttribute("delta-x", totalDeltaX);
      return;
    }
    if (totalDeltaX >= 0) {
      totalDeltaX = 0;
    }
    if (
      Math.abs(totalDeltaX) < mX ||
      (deltaX > 0 && Math.abs(totalDeltaX) - deltaX > 0) ||
      forced
    ) {
      deltaX < 0
        ? (totalDeltaX += deltaX - 100)
        : (totalDeltaX += deltaX + 100);
      let nearest = Math.round(totalDeltaX / cWidth) * cWidth;
      if (
        (deltaX > 0 && totalDeltaX >= 0) ||
        deltaX > cWidth * children.length
      ) {
        galleryContent.current.setAttribute("delta-x", 0);
        galleryContent.current.style.transform = `translate3d(0px, 0, 0)`;
        return;
      }
      if (Math.abs(nearest) > mX) {
        galleryContent.current.style.transform = `translate3d(-${mX}px, 0, 0)`;
        galleryContent.current.setAttribute("delta-x", nearest);
        return;
      }
      galleryContent.current.setAttribute("delta-x", nearest);
      galleryContent.current.style.transform = `translate3d(${nearest}px, 0, 0)`;
      return;
    }
    galleryContent.current.setAttribute("delta-x", 0);
    galleryContent.current.style.transform = `translate3d(0px, 0, 0)`;
  };

  const handleResize = () => {
    gallery.current.style.height = `${galleryContent.current.offsetHeight}px`;
    galleryContent.current.setAttribute("delta-x", 0);
    galleryContent.current.setAttribute("current", 0);
  };

  return (
    <div
      className={`relative ${childIsAbsolute && "h-80vh"}`}
      ref={gallery}
      onMouseMove={handleHover}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onMouseDown={handleClick}
      onMouseUp={handleClick}
    >
      {!isMobile && (
        <div
          style={{
            transform: `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0)`,
            position: "absolute",
            zIndex: 99,
            opacity: mousePosition.opacity,
            pointerEvents: "none",
          }}
        >
          <CustomCursor mouseDown={mouseDown} />
        </div>
      )}
      <div className="relative overflow-visible h-full">
        <div
          ref={galleryContent}
          className="gallery-content relative inline-flex will-change-transform h-full"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

DragGrallery.propTypes = {
  children: PropTypes.node.isRequired,
  pagination: PropTypes.bool,
  activeSlideCallback: PropTypes.func,
  activeSlide: PropTypes.number,
  animationConfig: PropTypes.shape({
    duration: PropTypes.number,
    delay: PropTypes.number,
  }),
  changeSlideFromOutside: PropTypes.bool,
  slidingCallback: PropTypes.func,
  triggerForward: PropTypes.any,
  childIsAbsolute: PropTypes.bool,
};

DragGrallery.defaultProps = {
  pagination: false,
  activeSlideCallback: () => {},
  animationConfig: {
    duration: 1000,
    delay: 0,
  },
  slidingCallback: () => {},
  childIsAbsolute: false,
};

export default DragGrallery;
