import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import Counter from "./Counter";
import SPImage from "./SPImage";
import Title3 from "./Title3";

const CounterWithImage = ({ highlight, image, items }) => {
  return (
    <div className="bg-blue-light">
      <Container>
        <Grid className="grid">
          <div className="full-columns mb-16 md:mt-12 md:mb-32 md:col-start-1 md:col-end-8 md:row-start-1 md:row-end-2">
            <Title3 size="medium" text={highlight} color="purple" />
          </div>
          <Grid className="col-start-4 col-end-13 mb-16 md:mb-0 md:col-start-1 md:col-end-7 md:row-start-2 md:row-end-5 md:grid md:grid-gap-small">
            {items.map((items, index) => {
              if (index === 0 || index % 2 === 0) {
                return (
                  <div
                    data-hax
                    key={index}
                    className={`md:col-start-1 md:col-end-7 md:row-start-${
                      index + 1
                    } md:row-end-${index + 2}`}
                  >
                    <Counter
                      number={items.number}
                      text={items.text}
                      numberColor="white"
                      textColor="purple"
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    data-hax
                    key={index}
                    className={`md:col-start-7 md:col-end-13 md:row-start-${index} md:row-end-${
                      index + 1
                    }`}
                  >
                    <Counter
                      number={items.number}
                      text={items.text}
                      numberColor="white"
                      textColor="purple"
                    />
                  </div>
                );
              }
            })}
          </Grid>
          <div
            className="z-10 full-columns md:col-start-7 col-end-13 md:row-start-1 md:row-end-5"
            data-hax
          >
            <div data-bax="-80, 100">
              <SPImage src={image.src} alt={image.alt} />
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

CounterWithImage.propTypes = {
  highlight: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

CounterWithImage.defaultProps = {
  image: {
    alt: "",
  },
  items: [],
};

export default CounterWithImage;
