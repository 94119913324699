import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import SPImage from "./SPImage";
import Title2 from "./Title2";
import htmlStyle from "../lib/htmlStyle";
import Grid from "./Grid";

const TheFounders = ({ title, htmlText, image1, image2 }) => {
  return (
    <div className="bg-purple md:pb-48">
      <Container>
        <Grid className="grid grid-row-gap-small">
          <div className="full-columns row-start-1 row-end-2 md:col-start-1 md:col-end-7 lg:col-end-6">
            <Title2
              text={title}
              color="white"
              size="small"
              className="mb-10 md:mb-20"
            />
          </div>
          <div
            className="full-columns row-start-2 row-end-3 md:col-start-1 md:col-end-7 lg:col-end-6 md:row-start-2 md:row-end-6 pb-12 md:pb-0"
            data-hax
            dangerouslySetInnerHTML={htmlStyle(htmlText, "white")}
          />
          <div
            className="full-columns row-start-4 row-end-5 md:col-start-1 md:col-end-10 md:row-start-7 md:row-end-10"
            data-hax
          >
            <SPImage
              data-bax="-10, 120"
              className="w-full"
              src={image2.src}
              alt={image2.alt}
            />
          </div>
          <div
            className="col-start-1 col-end-10 row-start-3 row-end-4 md:col-start-8 md:col-end-13 md:row-start-5 md:row-end-8"
            data-hax
          >
            <SPImage
              data-bax="-100, 100"
              className="w-full pb-2 md:pb-0"
              src={image1.src}
              alt={image1.alt}
            />
          </div>
        </Grid>
      </Container>
    </div>
  );
};

TheFounders.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image1: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  image2: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};

TheFounders.defaultProps = {
  image1: {
    alt: "",
  },
  image2: {
    alt: "",
  },
};

export default TheFounders;
