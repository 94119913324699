import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import ReactSVG from "react-svg";
import SPImage from "./SPImage";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
const Plyr = React.lazy(() => import("react-plyr"));

const Video = ({ src, poster, player, fullWidth, videoAsHeader }) => {
  const SSR = typeof window === "undefined";
  const videoRef = useRef(null);
  const container = useRef(null);
  const [playing, setPlaying] = useState(false);

  const handleDynamicHeaderVideoLoad = () => {
    if (videoRef.current) {
      videoRef.current?.play();
    }
  };

  const handlePlayPause = () => {
    if (videoRef.current.isPaused()) {
      videoRef.current.play();
      setPlaying(true);
      trackCustomEvent({
        category: "Video",
        action: window.location.pathname,
        label: src
      });
      return;
    }
    setPlaying(false);
    videoRef.current.pause();
  };

  const handleFull = () => {
    container.current.setAttribute("data-no-hax", true);
  };

  const handleExit = () => {
    container.current.removeAttribute("data-no-hax");
  };

  if (videoAsHeader) {
    return (
      <section className="relative w-full md:h-80vh">
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          onCanPlayThrough={handleDynamicHeaderVideoLoad}
          onLoadedData={handleDynamicHeaderVideoLoad}
          className={`w-full h-full object-cover`}
          poster={poster}
        >
          <source src={src} type="video/mp4" className="w-full" />
        </video>
      </section>
    );
  } else {
    return (
      <div data-hax ref={container}>
        <Container className={`relative`} fullWidth={fullWidth}>
          <div className="h-full">
            {!SSR && (
              <React.Suspense fallback={<div />}>
                <Plyr
                  onEnterFullscreen={handleFull}
                  onExitFullscreen={handleExit}
                  onEnd={() => {
                    setPlaying(false);
                    if (videoRef.current.player.fullscreen.active) {
                      videoRef.current.player.fullscreen.exit();
                    }
                  }}
                  type="vimeo"
                  videoId={src}
                  ref={videoRef}
                />
              </React.Suspense>
            )}
          </div>
          {!playing && (
            <>
              <div
                className="cursor-pointer absolute inset-0 w-full h-full flex items-center justify-center z-10"
                onClick={handlePlayPause}
              >
                <div className="absolute inset-0">
                  <SPImage className="w-full h-full" src={poster} alt="" />
                </div>
                <div
                  className={`cursor-pointer flex items-center justify-center w-24 h-24 rounded-full bg-${player.backgroundColor} hover:bg-transparent border-solid border-2 border-${player.backgroundColor} fill-${player.color} hover:fill-${player.backgroundColor} md:transition-all z-10`}
                >
                  <ReactSVG src={player.src} alt="" className="ml-2" />
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
    );
  }
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  player: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string
  }).isRequired,
  fullWidth: PropTypes.bool,
  videoAsHeader: PropTypes.bool
};

Video.defaultProps = {
  player: {
    color: "white",
    alt: ""
  }
};

export default Video;
