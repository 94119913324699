import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";

const FullWidthImage = ({ className, src, alt }) => (
  <div>
    <div className="hidden md:block overflow-hidden relative">
      <div data-bax="-100, 100">
        <SPImage
          className={`${className} block w-full`}
          src={src}
          alt={alt}
          style={{ transform: `scale(1.2)` }}
        />
      </div>
    </div>
    <div className="md:hidden">
      <SPImage
        className={`${className} block w-full`}
        src={src}
        alt={alt}
        style={{ transform: `scale(1.2)` }}
      />
    </div>
  </div>
);

FullWidthImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

FullWidthImage.defaultProps = {
  alt: "",
  className: "",
};

export default FullWidthImage;
