import React from "react";
import PropTypes from "prop-types";

const CWrapper = ({
  children,
  name,
  pageName,
  // iterator,
}) => {
  /*

  How does this works? Easy!

  Each object defines the components (by name) that have either an extraPaddingTop, a paddingTop, or a paddingBottom.

  What's the array next to the object key?

  The array defines the pages where the padding IS NOT APPLIED

  Easy, right?

  */

  const extraPaddingTop = {
    About: [],
    WeWillHelpYou: [],
    BenefitsTwoImagesTop: [],
    CreatingFun: [],
    MeetTheTeamDetail: [],
    OfferApplication: [],
    CareerOpenings: [],
    JobAlerts: [],
    StayUpdated: ["home"],
    JobOpenings: [],
    JumpAboard: ["home", "lifeinbarcelona", "relocate", "benefits"],
  };
  const hasExtraPaddingTop =
    Object.keys(extraPaddingTop).indexOf(name) > -1 &&
    extraPaddingTop[name].indexOf(pageName) === -1;

  const paddingTop = {
    CurrentOpenPositions: [],
    FollowOurLegends: [],
    Stores: [],
    WhySocialpoint: [],
    Benefits: ["joinus", "relocate", "lifeinbarcelona"],
    MeetTheTeam: [],
    LifeAtSocialpoint: ["home", "job"],
    Relocate: ["lifeinbarcelona"],
    StayUpdated: ["home"],
    DigitalTalentHub: [],
    LifeAtSocialpointSimple: ["about"],
    CareersGrid: [],
    Video: [],
    WhatWeDo: [],
    Error404: [],
    CareersOpenings: [],
    OfficeTour: ["joinus"],
    Games: ["home"],
  };
  const hasPaddingTop =
    Object.keys(paddingTop).indexOf(name) > -1 &&
    paddingTop[name].indexOf(pageName) === -1;

  const paddingBottom = {
    JobAlerts: [],
    CurrentOpenPositions: [],
    FollowOurLegends: [],
    SignUpForAlerts: [],
    LifeAtSocialpointSimple: ["relocate", "about"],
    Relocate: ["benefits", "lifeinbarcelona"],
    LifeAtSocialpoint: ["home", "job"],
    CounterWithImage: ["about"],
    News: ["home"],
    WhatWeDo: [],
    DigitalTalentHub: [],
    BenefitsTwoImages: [],
    MeetTheTeamDetail: [],
    WhySocialpoint: [],
    ApplicationProcess: ["joinus"],
    MeetTheTeam: ["joinus"],
    GameReel: [],
    GameDetailGallery: [],
    JumpAboard: ["home"],
    SectionHeader: ["joinus", "careers", "lifeinbarcelona"],
    Error404: [],
    OfferGrid: ["job"],
    CareersGrid: [],
  };
  const hasPaddingBottom =
    Object.keys(paddingBottom).indexOf(name) > -1 &&
    paddingBottom[name].indexOf(pageName) === -1;

  const extraPaddingBottom = {
    LifeAtSocialpointSimple: ["relocate"],
    DigitalTalentHub: [],
  };
  const hasExtraPaddingBottom =
    Object.keys(extraPaddingBottom).indexOf(name) > -1 &&
    extraPaddingBottom[name].indexOf(pageName) === -1;

  return (
    <div
      page-name={pageName}
      data-wrapper={name}
      className={`${hasPaddingTop && "pt-12 md:pt-24 lg:pt-32"} ${
        hasExtraPaddingTop && "pt-40 md:pt-64"
      } ${hasPaddingBottom && "pb-12 md:pb-24 lg:pb-32"} ${
        hasExtraPaddingBottom && "pb-16 md:pb-450 lg:pb-500"
      }`}
    >
      {/* {iterator.isFirst && '---- IS FIRST ----'} */}
      {children}
      {/* {iterator.isLast && '---- IS LAST ----'} */}
    </div>
  );
};

CWrapper.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  pageName: PropTypes.string,
  iterator: PropTypes.shape({
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
  }).isRequired,
};

CWrapper.defaultProps = {
  children: null,
  name: "",
  pageName: "",
};

export default CWrapper;
