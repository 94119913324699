import React from "react";
import PropTypes from "prop-types";
import Multiline from "./Multiline";

// H2 -
// (huge) desktop: 130px - mobile: 70px
// (big) desktop: 130px - mobile: 50px
// (medium) desktop: 80px - mobile: 40px
// (small) desktop: 70px - mobile: 40px

const Title2 = ({ className, text, color, size }) => {
  let textSize = "";

  switch (size) {
    case "huge":
      textSize = "text-70px md:text-130px";
      break;
    case "big":
      textSize = "text-50px md:text-130px";
      break;
    case "medium":
      textSize = "text-40px md:text-80px";
      break;
    case "small":
      textSize = "text-40px md:text-70px";
      break;
  }

  return (
    <h2
      data-hax
      className={`
        text-${color} font-ttNorms font-bold leading-none
        ${textSize}
        ${className}
      `}
    >
      <Multiline text={text} />
    </h2>
  );
};

Title2.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["huge", "big", "medium", "small"]),
};

Title2.defaultProps = {
  className: "",
};

export default Title2;
