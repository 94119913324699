import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import colors from "../lib/colors";
import throttle from "../lib/throttle";
import SPImage from "./SPImage";
import Container from "./Container";
import useDidMount from "../hooks/useDidMount";
import useIsMobile from "../hooks/useIsMobile";

const separatorHeight = 200;
const Separator = ({ topColor, bottomColor, image }) => {
  const isMobile = useIsMobile();
  const didMount = useDidMount();
  const main = useRef(null);
  const [canvasId, setCanvasId] = useState(false);

  useEffect(() => {
    if (!canvasId) {
      setCanvasId("_" + Math.random().toString(36).substr(2, 9));
    }
    if (didMount) {
      let resize;
      if (!isMobile) {
        const { onResizeEvent } = beWavey();
        resize = onResizeEvent;
      }
      return () => window.removeEventListener("resize", resize);
    }
  }, [canvasId]);

  const beWavey = () => {
    const paper = typeof window !== `undefined` ? require("paper") : null;
    if (!paper) return;

    const canvas = main.current.querySelector("canvas");

    var values = {
      friction: 0.1,
      timeStep: 0.5,
      amount: 10,
      mass: 0.1,
      count: 0,
    };
    values.invMass = 1 / values.mass;

    var path;
    var springs;
    var shouldBeMoving = false;
    // eslint-disable-next-line no-unused-vars
    let height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    var size = [0, 0];

    var Spring = function (a, b, strength, restLength) {
      this.a = a;
      this.b = b;
      this.restLength = restLength || 80;
      this.strength = strength ? strength : 0.55;
      this.mamb = values.invMass * values.invMass;
      this.invMass = values.invMass;
    };

    Spring.prototype.update = function () {
      var delta = new paper.Point(this.b.x - this.a.x, this.b.y - this.a.y);
      var dist = delta.length;
      var normDistStrength =
        ((dist - this.restLength) / (dist * this.mamb)) * this.strength;
      delta.y *= normDistStrength * values.invMass * 0.2;
      if (!this.a.fixed) this.a.y += delta.y;
      if (!this.b.fixed) this.b.y -= delta.y;
    };

    const updateHeight = () => {
      height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    };
    const createPath = (strength) => {
      let fillColor = null;
      if (bottomColor.includes("-")) {
        const parts = bottomColor.split("-");
        if (parts.length > 1) {
          fillColor = colors[parts[0]][parts[1]];
        } else {
          fillColor = colors[parts[0]];
        }
      } else {
        fillColor = colors[bottomColor];
        if (typeof fillColor === "object") {
          fillColor = fillColor["default"];
        }
      }
      let path = new paper.Path({
        fillColor,
      });
      springs = [];
      for (let i = 0; i <= values.amount; i++) {
        const p = new paper.Point((i / values.amount) * size[0] || 0, 1);
        let segment = path.add(p);
        let point = segment.point;
        point.y =
          size[1] / 2 + (size[1] / 6) * Math.sin((i / values.amount) * size[0]);
        if (i == 0 || i == values.amount) {
          point.y += size[1];
        }
        point.px = point.x || 0;
        point.py = point.y || 0;
        point.fixed = i < 2 || i > values.amount - 2;
        if (i > 0) {
          let spring = new Spring(segment.previous.point, point, strength);
          springs.push(spring);
        }
      }
      path.position.x -= size[0] / 4;
      return path;
    };

    const onResize = () => {
      if (!main.current) return;
      updateHeight();
      paper.setup(canvas);
      if (path) path.remove();
      paper.view.setViewSize(
        new paper.Size(main.current.offsetWidth * 2, separatorHeight)
      );
      size = [paper.view.bounds.size.width * 2, separatorHeight];
      path = createPath();
    };

    const updateWaveForPoint = (eventPoint) => {
      var location = path.getNearestLocation(eventPoint);
      var segment = location.segment;
      var point = segment.point;

      if (!point.fixed && location.distance < size[1] / 4) {
        var y = eventPoint.y;
        point.y += (y - point.y) / 6;
        if (segment.previous && !segment.previous.fixed) {
          var previous = segment.previous.point;
          previous.y += (y - previous.y) / 24;
        }
        if (segment.next && !segment.next.fixed) {
          var next = segment.next.point;
          next.y += (y - next.y) / 24;
        }
      }
    };

    const onFrame = () => {
      if (shouldBeMoving) {
        updateWave(path);
      }
    };

    const updateWave = (path) => {
      var force = 0.01;
      for (var i = 0, l = path.segments.length; i < l; i++) {
        var point = path.segments[i].point;
        if (isNaN(point.y) || isNaN(point.py)) {
          point.y = point.py;
        }
        var dy = (point.y - point.py) * force;
        point.py = point.y;
        point.y = Math.max(point.y + dy, 0);
      }
      for (var j = 0; j < springs.length; j++) {
        springs[j].update();
      }
      // path.smooth({ type: 'continuous' })
      path.smooth({ type: "asymmetric" });
    };

    const onMouseEnter = () => {
      shouldBeMoving = true;
      main.current.removeEventListener("mouseenter", onMouseEnter);
    };

    const onResizeEvent = throttle(onResize, 100);
    window.addEventListener("resize", onResizeEvent.bind(this));
    main.current.addEventListener("mouseenter", onMouseEnter);
    const threshold = 100;
    document.addEventListener("mousemove", (e) => {
      if (main.current && shouldBeMoving) {
        const x = e.pageX - main.current.offsetLeft;
        const y = e.pageY - main.current.offsetTop;
        const height = main.current.offsetHeight;
        if (y >= -threshold && y < height + threshold) {
          const pX = x;
          const pY =
            ((y + threshold) / (height + threshold * 2)) * separatorHeight;
          const point = new paper.Point(pX, pY);
          updateWaveForPoint(point);
        }
      }
    });

    onResize();
    updateWave(path);

    paper.view.onFrame = onFrame;
    paper.view.onResize = onResize;

    return {
      onResizeEvent: onResizeEvent.bind(this),
    };
  };

  return (
    <div
      ref={main}
      className={`bg-${topColor} relative`}
      style={{
        height: () => {
          if (isMobile && image) return 300;
          if (isMobile) return 200;
          return 300;
        },
      }}
    >
      <div
        className={`bg-${topColor}`}
        style={{
          height: (() => {
            if (isMobile && image) return 150;
            if (isMobile) return 100;
            return 50;
          })(),
        }}
      />
      {image && (
        <Container className="pointer-events-none relative">
          <div
            className={`pointer-events-none absolute z-10 h-${separatorHeight} flex flex-col justify-center items-center`}
          >
            <SPImage
              className="relative pointer-events-none max-w-80p max-h-50vh md:max-w-40vw lg:max-w-50vw md:max-h-700 md:w-full md:object-scale-down"
              style={{ top: "-50%" }}
              {...image}
            />
          </div>
        </Container>
      )}
      {!isMobile && (
        <canvas
          style={{ width: "100%", height: separatorHeight }}
          resize="true"
          hidpi="off"
          id={canvasId}
        />
      )}
      <div
        className={`bg-${bottomColor.replace("-default", "")}`}
        style={{
          height: (() => {
            if (isMobile && image) return 150;
            if (isMobile) return 100;
            return 50;
          })(),
        }}
      />
    </div>
  );

  //PREVIOUS:

  // return (
  //   <div
  //     ref={main}
  //     className={`bg-${topColor} relative`}
  //     style={{ height: isMobile ? 190 : 300 }}
  //   >
  //     <div
  //       className={`bg-${topColor}`}
  //       style={{
  //         height: (() => {
  //           if (isMobile && image) return 130
  //           if (isMobile) return 95
  //           return 50
  //         })()
  //       }}
  //     />
  //     {image &&
  //       <Container>
  //         <Grid className={`grid absolute pointer-events-none ${image.top}`}>
  //           <div
  //             className={
  //               `${image.position === 'left'
  //                 ? `${image.align !== 'center' ? 'col-start-2 col-end-8 max-w-40vh' : 'col-start-2 col-end-11 max-w-60vh'} md:col-start-1 md:col-end-6 lg:col-start-1 lg:col-end-7`
  //                 : 'md:col-start-9 md:col-end-13'
  //               }
  //                 pointer-events-none`}
  //           >
  //             <SPImage
  //               className="pointer-events-none max-w-80p"
  //               {...image}
  //             />
  //           </div>
  //         </Grid>
  //       </Container>
  //     }
  //     <canvas
  //       style={{ width: '100%', height: isMobile ? 0 : separatorHeight }}
  //       resize="true"
  //       hidpi="off"
  //       id={canvasId}
  //     />
  //     <div
  //       className={`bg-${bottomColor.replace('-default', '')}`}
  //       style={{
  //         height: (() => {
  //           if (isMobile && image) return 130
  //           if (isMobile) return 95
  //           return 50
  //         })()
  //       }}
  //     />
  //   </div>
  // )
};

Separator.propTypes = {
  topColor: PropTypes.string.isRequired,
  bottomColor: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.string,
    align: PropTypes.string,
  }),
  htmlText: PropTypes.string,
};

Separator.defaultProps = {
  image: null,
  htmlText: null,
};

export default Separator;
