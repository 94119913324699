import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title1 from "./Title1";
import Video from "./Video";
import TitleTextCtaImage from "./TitleTextCtaImage";
import TitleTextCtaVideo from "./TitleTextCtaVideo";

const About = ({ top, middle, bottom, video }) => (
  <div className="bg-gray">
    <Container>
      <div className="w-90p pb-12 md:pb-16 md:w-2/3">
        <Title1 text={top.title} color="purple" />
      </div>
      <div className="pb-12 md:pb-40 lg:pb-48">
        <TitleTextCtaImage
          htmlText={top.htmlText}
          image={top.image}
          cta={top.cta}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "horizontal",
            imageSwitchMobile: true,
            imageSize: "normal",
            imageHugeMobile: true
          }}
          style={{
            textColor: "purple",
            cta: {
              backgroundColor: "blue-light",
              titleColor: "purple"
            },
            wrappers: {
              htmlText: "pb-16",
              cta: "pb-16 md:pb-0"
            }
          }}
        />
      </div>
      <div className="pb-16 md:pb-24 lg:pb-48">
        <TitleTextCtaImage
          htmlText={middle.htmlText}
          image={middle.image}
          layout={{
            imagePositionX: "left",
            imagePositionY: "top",
            imageOrientation: "vertical",
            imageSwitchMobile: true,
            imageSize: "normal"
          }}
          style={{
            textColor: "purple",
            wrappers: {
              htmlText: "pb-16"
            }
          }}
        />
      </div>
      <div className="pb-12 md:pb-24">
        <TitleTextCtaVideo
          htmlText={bottom.htmlText}
          video={bottom.video}
          layout={{
            videoPositionX: "right",
            videoPositionY: "top",
            videoOrientation: "horizontal",
            videoSwitchMobile: true,
            videoSize: "normal",
            videoHugeMobile: true
          }}
          style={{
            textColor: "purple"
          }}
        ></TitleTextCtaVideo>
      </div>
    </Container>
    <Video
      src={video.src}
      poster={video.poster}
      player={{
        src: video.player.src,
        alt: video.player.alt,
        backgroundColor: "purple",
        color: "white"
      }}
    />
  </div>
);

About.propTypes = {
  top: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired,
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  middle: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired,
    htmlText: PropTypes.string.isRequired
  }).isRequired,
  bottom: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired,
    htmlText: PropTypes.string.isRequired
  }).isRequired,
  video: PropTypes.shape({
    src: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    player: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired
  }).isRequired
};

About.defaultProps = {};

export default About;
