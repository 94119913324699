import React, { useRef } from "react";
import PropTypes from "prop-types";

const Attachment = ({ label, cta, text, name, value, onChange, onBlur }) => {
  const input = useRef(null);
  return (
    <div>
      <label className="uppercase text-purple font-bold text-base tracking-wide font-ttNorms mb-4">
        {label}
      </label>
      <div className="md:flex md:flex-row items-center my-4 relative">
        <input
          ref={input}
          id={name}
          name={name}
          type="file"
          className="w-40 h-10 absolute opacity-0"
          multiple
          accept=".pdf, .doc, .docx, .jpg"
          onChange={(e) => onChange(name, e.target.value)}
          onBlur={onBlur}
        />
        <div
          className={`
            text-purple hover:text-blue-light font-bold font-lato text-tiny
            bg-transparent hover:bg-purple
            border-solid border border-purple rounded-n
            w-40 h-10 flex flex-row justify-center items-center
            cursor-pointer transition-all
          `}
        >
          <p>{cta.attachment}</p>
        </div>
        <p className="text-purple text-tiny italic my-4 md:my-0 md:ml-4">
          {value !== ""
            ? `${cta.selected} ${input.current.value.replace(/.*[\/\\]/, "")}`
            : cta.noFiles}
        </p>
      </div>
      <p className="text-tiny text-purple w-full">{text}</p>
    </div>
  );
};

Attachment.propTypes = {
  label: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    attachment: PropTypes.string,
    submit: PropTypes.string,
    noFiles: PropTypes.string,
    selected: PropTypes.string,
  }).isRequired,
  text: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Attachment.defaultValue = {
  text: "",
  value: "",
  onChange: () => {},
};
export default Attachment;
