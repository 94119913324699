import React from "react";
import PropTypes from "prop-types";
import CtaWithArrows from "./CtaWithArrows";
import SPImage from "./SPImage";

const MeetTheTeamItem = ({ image, name, position, cta, last }) => (
  <div className={`w-72 md:w-76 h-full ${last ? "" : "md:mr-8 mr-6"}`}>
    <div
      className="mb-6 bg-cover md:h-300px min-h-20rem"
      // src={image}
      style={{ backgroundImage: `url(${image})` }}
    />
    <p className="text-purple font-ttNorms font-bold text-small mb-1">{name}</p>
    <p className="text-purple text-base mb-4">{position}</p>
    <div className="flex flex-row text-white font-bold fill-white">
      <CtaWithArrows
        hrefTo={cta.hrefTo}
        icon={cta.icon}
        title={cta.title}
        color="white"
        size="small"
      />
    </div>
  </div>
);

MeetTheTeamItem.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  last: PropTypes.bool,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
    hrefTo: PropTypes.string.isRequired,
  }),
};

MeetTheTeamItem.defaultProps = {
  last: false,
  cta: {
    icon: {
      alt: "",
    },
  },
};

export default MeetTheTeamItem;
