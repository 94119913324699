var htmlEnDeCode = (function () {
  var charToEntityRegex, entityToCharRegex, charToEntity, entityToChar;

  function resetCharacterEntities() {
    charToEntity = {};
    entityToChar = {};
    // add the default set
    addCharacterEntities({
      "&amp;": "&",
      "&gt;": ">",
      "&lt;": "<",
      "&quot;": '"',
      "&#39;": "'",
    });
  }

  function addCharacterEntities(newEntities) {
    var charKeys = [],
      entityKeys = [],
      key,
      echar;
    for (key in newEntities) {
      echar = newEntities[key];
      entityToChar[key] = echar;
      charToEntity[echar] = key;
      charKeys.push(echar);
      entityKeys.push(key);
    }
    charToEntityRegex = new RegExp("(" + charKeys.join("|") + ")", "g");
    entityToCharRegex = new RegExp(
      "(" + entityKeys.join("|") + "|&#[0-9]{1,5};" + ")",
      "g"
    );
  }

  function htmlEncode(value) {
    var htmlEncodeReplaceFn = function (match, capture) {
      return charToEntity[capture];
    };

    return !value
      ? value
      : String(value).replace(charToEntityRegex, htmlEncodeReplaceFn);
  }

  function htmlDecode(value) {
    var htmlDecodeReplaceFn = function (match, capture) {
      return capture in entityToChar
        ? entityToChar[capture]
        : String.fromCharCode(parseInt(capture.substr(2), 10));
    };

    return !value
      ? value
      : String(value).replace(entityToCharRegex, htmlDecodeReplaceFn);
  }

  resetCharacterEntities();

  return {
    htmlEncode: htmlEncode,
    htmlDecode: htmlDecode,
  };
})();

const htmlStyle = (text, textColor) => {
  return {
    __html: `<div class="${textColor} _html">${htmlEnDeCode.htmlDecode(
      text
    )}</div>`,
  };
};

export default htmlStyle;
