import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";
import Button from "./Button";
import Container from "./Container";
import useIsMobile from "../hooks/useIsMobile";

const GameHeader = ({ image, imageMobile, cta, logo }) => {
  const isMobile = useIsMobile();
  let style = {};
  if (isMobile) {
    style = { backgroundImage: `url(${imageMobile.src})` };
  } else if (!isMobile) {
    style = { backgroundImage: `url(${image.src})` };
  }
  return (
    <div
      style={style}
      className="h-full w-full bg-no-repeat bg-center bg-cover"
    >
      <Container className="flex flex-col justify-end h-80vh">
        <div className="h-full w-full relative flex items-center">
          <SPImage
            className="block mx-auto"
            style={{ maxHeight: "calc(40vh - 100px)" }}
            src={logo.src}
            alt={logo.alt}
          />
          <Button
            className="absolute bottom-0 right-0 mb-8"
            backgroundColor={cta.style.backgroundColor}
            color={cta.style.titleColor}
            hoverColor={cta.style.hover.titleColor}
            hoverBackgroundColor={cta.style.hover.borderColor}
            title={cta.title}
            hrefTo={cta.hrefTo}
          />
        </div>
      </Container>
    </div>
  );
};

GameHeader.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  imageMobile: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
      titleColor: PropTypes.string.isRequired,
      hover: PropTypes.shape({
        borderColor: PropTypes.string,
        titleColor: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};

GameHeader.defaultProps = {
  image: {
    alt: "",
  },
  imageMobile: {
    alt: "",
  },
  logo: {
    alt: "",
  },
};

export default GameHeader;
