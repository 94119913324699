import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title1 from "./Title1";
import TitleTextCtaImage from "./TitleTextCtaImage";

const CareerOpenings = ({ title, htmlText, image }) => {
  return (
    <Container>
      <Title1 text={title} color="purple" className="md:pr-32" />
      <div className="pt-12 md:pt-16 md:pb-12">
        <TitleTextCtaImage
          htmlText={htmlText}
          image={image}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "horizontal",
            imageSize: "normal",
          }}
          style={{
            textColor: "purple",
            wrappers: {
              htmlText: "pb-0",
              image: "hidden md:block",
            },
          }}
        />
      </div>
    </Container>
  );
};

CareerOpenings.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};

CareerOpenings.defaultProps = {};

export default CareerOpenings;
