import React from "react";
import PropTypes from "prop-types";
import htmlStyle from "../lib/htmlStyle";
import SPSvg from "./SPSvg";

const Benefits = ({ layout, item, index }) => {
  if (layout === "small") {
    return (
      <div
        className={`mb-4 full-columns flex items-center md:row-start-${
          index < 4 ? index + 1 : index - 4
        } md:col-start-${index < 4 ? 1 : 6} md:col-end-${index < 4 ? 5 : 12} `}
      >
        <div
          className="fill-white border-solid border-white border-2 w-16 p-3 h-16 flex flex-row items-center justify-center"
          style={{
            borderRadius: "35%",
          }}
        >
          <SPSvg src={item.icon.src} alt={item.icon.alt} />
        </div>
        <span
          className={`w-3/4 text-base pl-8 leading-tight text-white font-bold`}
        >
          {item.title}
        </span>
      </div>
    );
  }
  if (layout === "big") {
    return (
      <div>
        <div
          className="fill-purple border-solid border-purple border-2 w-16 p-3 h-16 flex flex-row items-center justify-center"
          style={{
            borderRadius: "35%",
          }}
        >
          <SPSvg src={item.icon.src} alt={item.icon.alt} />
        </div>
        <h3 className="py-8 text-small font-bold text-purple max-w-80p font-ttNorms font-bold leading-none">
          {item.title}
        </h3>
        <div dangerouslySetInnerHTML={htmlStyle(item.htmlText, "purple")} />
      </div>
    );
  }
};

Benefits.propTypes = {
  layout: PropTypes.oneOf(["big", "small"]).isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  }),
  index: PropTypes.number,
};

Benefits.defaultProps = {
  item: {
    icon: {
      alt: "",
    },
  },
};

export default Benefits;
