import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import HeaderSliderArrow from "./HeaderSliderArrow";
import Button from "./Button";
import { useSpring, animated } from "react-spring";
import SPImage from "./SPImage";
import useDidMount from "../hooks/useDidMount";
import wavey from "../lib/wavey";
import globalVar from "../lib/globalVar";
import useIsMobile from "../hooks/useIsMobile";

const checkSupport = () => {
  if (typeof navigator === "undefined") return;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (
    navigator.userAgent.indexOf("Chrome") !== -1 &&
    navigator.userAgent.indexOf("Android") === -1
  )
    return true;
  return (
    width > 1024 &&
    navigator.userAgent.indexOf("Android") === -1 &&
    navigator.userAgent.indexOf("iPhone") === -1 &&
    navigator.userAgent.indexOf("Safari") === -1
  );
};

let supported;

const AnimatedSlide = ({
  slide,
  arrows,
  navigate,
  direction,
  callback,
  current,
  support,
  isMobile,
}) => {
  const container = useRef(null);
  const wave = useRef(null);
  useEffect(() => {
    if (support) {
      const width = container.current.offsetWidth;
      const height = container.current.offsetHeight;
      // speed && wave points
      wavey(width, height, wave.current, 1.1, 3).then(() => {
        callback(current + direction);
      });
    } else {
      setTimeout(() => {
        callback(current + direction);
      }, 1000);
    }
  }, []);

  let style = {
    clipPath: support && "url(#wave-mask)",
  };
  if (isMobile) {
    style.backgroundImage = `url(${slide.backgroundMobile.url})`;
  } else {
    style.backgroundImage = `url(${slide.background})`;
  }

  return (
    <>
      <div
        ref={container}
        style={style}
        className={`h-full w-full bg-no-repeat bg-center bg-cover absolute inset-0
          ${!support && direction === 1 && "transition-forwards"}
          ${!support && direction === -1 && "transition-reverse"}
        `}
      >
        <UI slide={slide} arrows={arrows} navigate={navigate} />
      </div>
      <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
        <clipPath id="wave-mask">
          <path
            d=""
            ref={wave}
            style={{
              transform: "rotate(90deg)",
            }}
          />
        </clipPath>
      </svg>
    </>
  );
};

const UI = ({ slide, arrows, navigate }) => {
  return (
    <Container className="flex flex-col justify-end h-full">
      <div className="h-full w-full relative flex items-center">
        <SPImage
          className="block mx-auto"
          style={{ maxHeight: "calc(40vh - 100px)" }}
          src={slide.logo}
          alt=""
        />
        <Button
          className="absolute bottom-0 right-0 mb-8"
          backgroundColor="white"
          color="purple"
          hoverColor="white"
          title={slide.ctaText}
          hrefTo={slide.hrefTo}
        />
      </div>
      <div className="absolute w-1/2 left-0 bottom-0 z-10 h-20 md:h-auto">
        <Container className="flex mb-6">
          <HeaderSliderArrow
            first={true}
            src={arrows.left}
            alt={arrows.leftAltText}
            onClick={() => navigate(-1)}
          />
          <HeaderSliderArrow
            src={arrows.right}
            alt={arrows.rightAltText}
            onClick={() => navigate(1)}
          />
        </Container>
      </div>
    </Container>
  );
};

const StaticSlide = ({ slide, arrows, navigate, index, current }) => {
  const isMobile = useIsMobile();
  let style = {};
  if (isMobile) {
    style.backgroundImage = `url(${slide.backgroundMobile.url})`;
  } else {
    style.backgroundImage = `url(${slide.background})`;
  }

  return (
    <div
      style={style}
      className={`h-full w-full bg-no-repeat bg-center bg-cover flex-shrink-0 ${
        index !== current && "hidden"
      }`}
    >
      <UI
        slide={slide}
        arrows={arrows}
        navigate={navigate}
        index={index}
        current={current}
      />
    </div>
  );
};

let timeout;
let rest = true;

const HeaderSlider = ({
  items,
  arrows: { left, right },
  arrowsAltText: { leftAltText, rightAltText },
}) => {
  const isMobile = useIsMobile();
  const [props, set] = useSpring(() => ({
    transform: `translate3d(0%, 0px, 0px)`,
    width: 100 / items.length + "%",
  }));
  const didMount = useDidMount();
  const [slides] = useState([items[items.length - 1], ...items, items[0]]);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentSlideIndicator, setCurrentSlideIndicator] = useState(0);
  const [trigger, setTrigger] = useState({ direction: 0 });
  const [onTransition, setOnTransition] = useState(false);

  useEffect(() => {
    supported = checkSupport();
    const handleResize = () => {
      supported = checkSupport();
    };
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (didMount) {
      set({
        transform: `translate3d(${currentSlideIndicator * 100}%, 0px, 0px)`,
        config: {
          duration: supported ? 1500 : 1000,
        },
      });
    }
  }, [currentSlideIndicator]);

  useEffect(() => {
    if (didMount) {
      setOnTransition(true);
    }
  }, [trigger]);

  useEffect(() => {
    if (currentSlide !== 0 && rest) {
      timeout = setTimeout(() => {
        navigate(1, true);
      }, globalVar.galleryTimeout);
    } else {
      clearTimeout(timeout);
    }
  }, [currentSlide]);

  const navigate = (direction, willRest) => {
    if (onTransition) return;
    let result = currentSlide + direction;
    if (result > slides.length - 2) {
      result = 0;
      setCurrentSlide(result);
    } else if (result === 0) {
      result = slides.length - 2;
      setCurrentSlide(result + 1);
    }
    rest = willRest;
    setCurrentSlideIndicator(result > 0 ? result - 1 : 0);
    const dir = { direction };
    setTrigger({ ...dir });
  };

  const preload = () => (
    <div style={{ display: "none" }}>
      {items.map((item) => (
        <img
          key={item.id}
          src={isMobile ? item.backgroundMobile.url : item.background}
        />
      ))}
    </div>
  );

  const currentSlideCallback = (value) => {
    setOnTransition(false);
    setCurrentSlide(value);
  };

  return (
    <div className={`h-80vh w-full overflow-hidden relative`}>
      <div className="h-80vh w-full overflow-hidden relative relative">
        <div className="h-full flex flex-nowrap w-full">
          {slides.map((slide, index) => (
            <StaticSlide
              key={index}
              slide={slide}
              arrows={{ left, leftAltText, right, rightAltText }}
              navigate={navigate}
              index={index}
              current={currentSlide}
            />
          ))}
          {onTransition &&
            React.createElement(AnimatedSlide, {
              slide: slides[currentSlide + trigger.direction],
              arrows: { left, leftAltText, right, rightAltText },
              navigate,
              direction: trigger.direction,
              callback: currentSlideCallback,
              current: currentSlide,
              support: supported,
              isMobile: isMobile,
            })}
        </div>
      </div>
      <div className="h-2 bg-blue-dark w-full absolute bottom-0 left-0 flex z-20">
        <animated.div className="bg-yellow" style={props} />
      </div>
      {preload()}
    </div>
  );
};

AnimatedSlide.propTypes = {
  slide: PropTypes.object,
  arrows: PropTypes.object,
  navigate: PropTypes.func,
  current: PropTypes.number,
  callback: PropTypes.func,
  direction: PropTypes.number,
  support: PropTypes.bool,
};

StaticSlide.propTypes = {
  slide: PropTypes.object,
  arrows: PropTypes.object,
  navigate: PropTypes.func,
  index: PropTypes.number,
  current: PropTypes.number,
};

UI.propTypes = {
  slide: PropTypes.object,
  arrows: PropTypes.object,
  navigate: PropTypes.func,
};

HeaderSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      background: PropTypes.string,
      logo: PropTypes.string,
      title: PropTypes.string,
      hrefTo: PropTypes.string,
      ctaText: PropTypes.string,
      backgroundMobile: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
  arrows: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  arrowsAltText: PropTypes.shape({
    leftAltText: PropTypes.string,
    rightAltText: PropTypes.string,
  }),
};

HeaderSlider.defaultProps = {
  items: [],
  arrows: {
    left: "",
    right: "",
  },
  arrowsAltText: {
    left: "",
    right: "",
  },
};

export default HeaderSlider;
