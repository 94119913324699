import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import CareersCard from "./CareersCard";

const CareersGrid = ({
  title,
  careers,
  viewOffersText,
  offersTextSingular,
  offersTextPlural,
}) => {
  return (
    <Container className="pb-6">
      <div>
        <h2 className="mb-10 mt-6 text-purple uppercase font-ttNorms font-bold">
          {title}
        </h2>
        <div
          data-hax
          className="flex flex-col flex-wrap grid-one-col grid-column-gap-medium grid-row-gap-medium md:grid-three-col"
        >
          {careers.map((career, index) => {
            return (
              <div key={index} className="w-full h-full">
                <CareersCard
                  {...career}
                  viewOffersText={viewOffersText}
                  offersText={
                    career.offersNumber === 1
                      ? offersTextSingular
                      : offersTextPlural
                  }
                  index={index}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

CareersGrid.propTypes = {
  title: PropTypes.string.isRequired,
  viewOffersText: PropTypes.string.isRequired,
  offersTextSingular: PropTypes.string.isRequired,
  offersTextPlural: PropTypes.string.isRequired,
  careers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
      hrefTo: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      offersNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
};

CareersGrid.defaultProps = {};

export default CareersGrid;
