import React, { useRef, useState, useEffect } from "react";
import ReactSVG from "react-svg";
import PropTypes from "prop-types";
import Container from "./Container";
import SPLink from "./SPLink";
import useDidMount from "../hooks/useDidMount";
import useIsMobile from "../hooks/useIsMobile";

let handlingDrag = false;

const Gallery = ({ title, children, showTitle, dragEnabledInMobile }) => {
  const isMobile = useIsMobile();
  const didMount = useDidMount();
  const main = useRef(null);
  const gallery = useRef(null);
  const galleryContent = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [stickyPostion, setStickyPosition] = useState("top");
  const [screenHeight, setScreenHeight] = useState(0);
  const [galleryWidth, setGalleryWidth] = useState(0);
  const [galleryContentWidth, setGalleryContentWidth] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    document.addEventListener("resize", handleResize);

    handleResize();

    if (didMount) {
      if (isMobile && dragEnabledInMobile && handlingDrag === false) {
        handlingDrag = true;
        handleDrag();
      }
    }

    return () => {
      document.removeEventListener("scroll", handleScroll);
      document.removeEventListener("resize", handleResize);
    };
  });

  const handleDrag = () => {
    const Hammer = require("hammerjs");
    const hM = new Hammer.Manager(main.current);
    hM.add(
      new Hammer.Pan({
        threshold: 10,
        pointers: 0,
        direction: Hammer.DIRECTION_HORIZONTAL,
      })
    );
    let oldDeltaX = 0;
    hM.on("panmove", (e) => {
      const { top, bottom } = gallery.current.getBoundingClientRect();
      if (top <= 0 && bottom > screenHeight) {
        const movedDistance = e.deltaX - oldDeltaX;
        oldDeltaX = e.deltaX;
        if (movedDistance !== 0) {
          window.scrollBy(0, -movedDistance * 2);
        }
      }
      handleScroll();
    });
    hM.on("panend", () => (oldDeltaX = 0));
  };

  const handleScroll = () => {
    const { top, bottom } = gallery.current.getBoundingClientRect();

    if (bottom < screenHeight) {
      setStickyPosition("bottom");
    } else if (top <= 0) {
      setStickyPosition("top");
    }

    if (top <= 0 && bottom > screenHeight) {
      setIsSticky(true);
      galleryContent.current.style.transform = `translate3d(${top}px, 0, 0)`;
    } else {
      setIsSticky(false);
    }
  };

  const handleResize = () => {
    setScreenHeight(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    );
    setGalleryWidth(gallery.current.firstChild.offsetWidth);
    setGalleryContentWidth(
      galleryContent.current.getBoundingClientRect().width
    );
  };

  const galleryHeight = galleryContentWidth - galleryWidth + screenHeight;
  const paddingTopWhenPastSticky = galleryContentWidth - galleryWidth;
  const titleText = title ? (
    <>
      {title.text}
      <div className={`fill-${title.color} block relative`} style={{ top: -5 }}>
        <ReactSVG {...title.icon} />
      </div>
    </>
  ) : null;

  return (
    <div className="relative" ref={main}>
      {showTitle && title && (
        <div
          className={`${
            isSticky ? "fixed z-10 right-0 md:w-93p lg:w-95p top-0" : "absolute"
          } w-full leading-none text-${title.color} font-bold ${
            title.size === "big"
              ? "text-medium-big md:small-big"
              : "text-small md:text-small-big"
          } ${!isSticky && stickyPostion === "bottom" ? "bottom-85vh" : ""} ${
            !isSticky && stickyPostion === "top" ? "top-0vh" : ""
          }`}
        >
          <Container className="flex flex-row items-end h-15vh pb-8">
            {title.hrefTo ? (
              <SPLink
                to={title.hrefTo || ""}
                className="z-10 w-full flex justify-between items-end font-ttNorms"
              >
                {titleText}
              </SPLink>
            ) : (
              <div className="z-10 w-full flex justify-between items-end font-ttNorms">
                {titleText}
              </div>
            )}
          </Container>
        </div>
      )}
      <div className="relative min-h-screen" ref={gallery}>
        <Container
          style={
            galleryContentWidth > 0
              ? {
                  height: galleryHeight,
                  paddingTop:
                    !isSticky && stickyPostion === "bottom"
                      ? paddingTopWhenPastSticky
                      : 0,
                }
              : {}
          }
          className="h-full w-full overflow-visible relative"
        >
          <div
            ref={galleryContent}
            className={`h-70vh inline-flex ${isSticky ? "fixed" : "absolute"} ${
              stickyPostion === "top" ? "top-15vh" : ""
            } ${
              !isSticky && stickyPostion === "bottom"
                ? "bottom-15vh right-0"
                : ""
            } ${
              !isSticky && stickyPostion === "top" ? "bottom-15vh left-0" : ""
            } will-change-transform`}
            style={
              !isSticky ? { transform: "translate3d(0px, 0px, 0px)" } : null
            }
            data-width={galleryContentWidth}
          >
            {children}
          </div>
        </Container>
      </div>
    </div>
  );
};

Gallery.propTypes = {
  title: PropTypes.shape({
    size: PropTypes.oneOf(["small", "big"]),
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    hrefTo: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  showTitle: PropTypes.bool,
  dragEnabledInMobile: PropTypes.bool,
};

Gallery.defaultProps = {
  showTitle: true,
  dragEnabledInMobile: false,
};

export default Gallery;
