import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import Title3 from "./Title3";
import htmlStyle from "../lib/htmlStyle";
import SPImage from "./SPImage";
import TitleTextCtaImage from "./TitleTextCtaImage";

const DigitalTalentHub = ({ top, middle, bottom }) => (
  <Container>
    <Title3
      text={top.title}
      color="purple"
      size="small"
      className="max-w-50p md:max-w-40p"
    />
    <div className="pt-12 md:pb-8">
      <TitleTextCtaImage
        htmlText={top.htmlText}
        image={top.image}
        cta={top.cta}
        layout={{
          imagePositionX: "right",
          imagePositionY: "top",
          imageOrientation: "horizontal",
          imageSize: "normal",
          imageSwitchMobile: true,
          imageTinyMobile: true,
          imageTinyPosition: "right",
        }}
        style={{
          textColor: "purple",
          cta: {
            backgroundColor: "green",
            titleColor: "white",
          },
          wrappers: {
            image: "pt-20 md:pt-0",
          },
        }}
      />
    </div>
    <Grid className="grid-gap-small grid md:grid-md md:gap-none mt-6 md:mt-20">
      <div
        data-hax
        className="row-start-1 row-end-7 full-columns md:row-start-1 md:row-end-span-4 block md:col-start-3 md:col-end-span-9 z-10"
      >
        <SPImage className="block w-full" {...middle.image1} />
      </div>
      <div
        className="pt-32 pb-8 md:pb-12 row-start-6 col-start-2 col-end-7 full-columns md:row-start-3 md:row-end-span-12 md:col-start-2 md:col-end-span-4 z-10"
        data-hax
      >
        <SPImage
          {...middle.image2}
          className="block w-full"
          data-bax="-120, 120"
        />
      </div>
      <div
        data-hax
        className="full-columns block md:row-start-6 md:row-end-span-12 md:col-start-7 md:col-end-span-5 lg:col-end-12"
        dangerouslySetInnerHTML={htmlStyle(middle.htmlText, "purple")}
      ></div>
    </Grid>
    <div className="pt-12 md:pt-0">
      <TitleTextCtaImage
        htmlText={bottom.htmlText}
        image={bottom.image}
        layout={{
          imagePositionX: "right",
          imagePositionY: "top",
          imageOrientation: "horizontal",
          imageSwitchMobile: true,
          imageTinyMobile: true,
          imageTinyPosition: "right",
          imageSize: "normal",
        }}
        style={{
          textColor: "purple",
        }}
      />
    </div>
  </Container>
);

DigitalTalentHub.propTypes = {
  top: PropTypes.shape({
    title: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
    imageAltText: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      hrefTo: PropTypes.string,
    }).isRequired,
  }).isRequired,
  middle: PropTypes.shape({
    htmlText: PropTypes.string.isRequired,
    image1: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
    image2: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).is,
  }).isRequired,
  bottom: PropTypes.shape({
    htmlText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

DigitalTalentHub.defaultProps = {};

export default DigitalTalentHub;
