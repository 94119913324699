import React, { useState } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import DragGallery from "./DragGallery";
import MeetTheTeamItem from "./MeetTheTeamItem";
import SPImage from "./SPImage";
import DragInstructions from "./DragInstructions";
import TitleTextCtaImage from "./TitleTextCtaImage";

const MeetTheTeam = ({
  title,
  htmlText,
  cta,
  items,
  image,
  dragInstructions,
}) => {
  const [triggerForward, setTriggerForward] = useState(0);
  return (
    <div className="md:mt-24 bg-blue-light">
      <Container>
        <TitleTextCtaImage
          title={title}
          htmlText={htmlText}
          cta={cta}
          image={image}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "vertical",
            imageSize: "maximum",
            imageTall: true,
          }}
          style={{
            isFirstTitle: false,
            titleSize: "big",
            textColor: "purple",
            cta: {
              titleColor: "purple",
              backgroundColor: "yellow",
            },
            wrappers: {
              image: "hidden md:block",
            },
          }}
        />
        <div data-hax>
          <div
            className="hidden md:flex md:flex-row md:justify-end md:mb-6 md:mt-40 lg:mt-48"
            onClick={() => {
              const next = triggerForward + 1;
              setTriggerForward(next);
            }}
          >
            <DragInstructions
              icon={dragInstructions.icon}
              text={dragInstructions.text}
              color="purple"
            />
          </div>
          <div className="mt-6 md:mt-0">
            <DragGallery triggerForward={triggerForward}>
              {items.map((item, index) => (
                <MeetTheTeamItem
                  key={index}
                  last={index === items.length - 1}
                  {...item}
                />
              ))}
            </DragGallery>
          </div>
        </div>
        <div className="md:hidden block px-4 pt-12" data-hax>
          <SPImage src={image.src} alt={image.alt} data-bax="-80, 80" />
        </div>
      </Container>
    </div>
  );
};

MeetTheTeam.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }).isRequired,
        hrefTo: PropTypes.string.isRequired,
      }),
    })
  ),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  dragInstructions: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
};

MeetTheTeam.defaultProps = {};

export default MeetTheTeam;
