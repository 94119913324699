import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";
import Container from "./Container";
import Title3 from "./Title3";
import Grid from "./Grid";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const Stores = ({ title, htmlText, downloadText, purchaseText, stores }) => {
  const handleClick = (store) => {
    trackCustomEvent({
      category: "Store Link",
      action: window.location.pathname,
      label: store,
    });
  };
  return (
    <Container className="bg-gray">
      <Grid className="grid items-start">
        <Title3
          text={title}
          color="purple"
          size="medium"
          className="col-start-1 col-end-13 md:col-end-6 row-start-1 row-end-2 mb-12"
        />
        <div
          data-hax
          className="full-columns md:col-start-1 md:col-end-7 md:row-start-2 md:row-end-3 mb-12 md:mb-0"
          dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
        />
        <div
          data-hax
          className="full-columns row-start-3 row-end-4 md:row-start-1 md:row-end-3 md:col-start-9 lg:col-end-12 md:col-end-13"
        >
          <div className="full-columns mb-4 font-ttNorms uppercase font-bold text-purple">
            {downloadText}
          </div>
          <Grid className="grid-two-col grid-gap-small">
            {stores.map((store) => (
              <a
                href={store.url}
                key={store.src}
                className="cursor-pointer"
                onClick={handleClick.bind(this, store.alt)}
              >
                <SPImage {...store} />
              </a>
            ))}
          </Grid>
          <div className="full-columns mt-4 font-ttNorms text-purple text-tiny">
            {purchaseText}
          </div>
        </div>
      </Grid>
    </Container>
  );
};

Stores.defaultValue = {};

Stores.propTypes = {
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired
  ),
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  downloadText: PropTypes.string.isRequired,
  purchaseText: PropTypes.string.isRequired,
};

export default Stores;
