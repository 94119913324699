import React from "react";
import PropTypes from "prop-types";
import htmlStyle from "../lib/htmlStyle";
import Video from "./Video";
import Grid from "./Grid";
import Container from "./Container";
import TitleTextCtaImage from "./TitleTextCtaImage";

const GameExplanation = ({
  backgroundColor,
  top,
  middle,
  bottom,
  textColor,
}) => (
  <div className={`bg-${backgroundColor}`}>
    <Video {...top.video} />
    <Container className="pt-16 md:pt-32">
      <Grid className="grid grid-row-gap-small md:grid-row-gap-massive">
        <div className="full-columns md:row-start-1 md:row-end-2 md:pb-20">
          <TitleTextCtaImage
            htmlText={middle.htmlText}
            image={middle.image}
            cta={middle.cta}
            layout={{
              imagePositionX: "right",
              imagePositionY: "top",
              imageOrientation: "vertical",
              imageSwitchMobile: true,
              imageSize: "normal",
              imageTall: true,
            }}
            style={{
              textColor,
              cta: {
                backgroundColor: middle.cta.backgroundColor || "yellow",
                color: middle.cta.color || backgroundColor,
              },
              wrappers: {
                htmlText: "pb-12 md:pt-12",
                image: "pt-20 md:pt-0",
              },
            }}
          />
        </div>
        <div
          data-hax
          className="full-columns md:col-start-8 md:col-end-13 py-16"
          dangerouslySetInnerHTML={htmlStyle(bottom.htmlText, textColor)}
        />
      </Grid>
    </Container>
  </div>
);

GameExplanation.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  top: PropTypes.shape({
    video: PropTypes.shape({
      src: PropTypes.string.isRequired,
      poster: PropTypes.string.isRequired,
      player: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
        backgroundColor: PropTypes.string.isRequired,
        color: PropTypes.string,
      }).isRequired,
    }),
  }),
  middle: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    htmlText: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      hrefTo: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
    }).isRequired,
  }),
  bottom: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    htmlText: PropTypes.string.isRequired,
  }),
  textColor: PropTypes.string,
};

GameExplanation.defaultProps = {
  textColor: "white",
};

export default GameExplanation;
