import React from "react";
import PropTypes from "prop-types";

const VerticalSpacer = ({ height }) => <div style={{ height }} />;

VerticalSpacer.propTypes = {
  height: PropTypes.string.isRequired,
};

VerticalSpacer.defaultProps = {
  height: "10px",
};

export default VerticalSpacer;
