import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import TitleTextCtaImage from "./TitleTextCtaImage";
import Grid from "./Grid";
import Quote from "./Quote";

const MeetTheTeamDetail = ({
  title,
  subtitle,
  htmlText,
  cta,
  image,
  items,
}) => (
  <div className="bg-gray">
    <Container>
      <Grid className="grid">
        <div className="full-columns md:row-start-1 md:row-end-6">
          <TitleTextCtaImage
            subtitle={subtitle}
            title={title}
            htmlText={htmlText}
            image={image}
            cta={cta}
            layout={{
              imagePositionX: "right",
              imagePositionY: "top",
              imageOrientation: "vertical",
              imageSize: "big",
              imageSwitchMobile: true,
            }}
            style={{
              isFirstTitle: true,
              titleSize: "big",
              textColor: "purple",
              cta: {
                backgroundColor: "red",
                titleColor: "white",
              },
              wrappers: {
                subtitle: "pb-6",
                title: "pb-8 md:pb-16",
                htmlText: "pb-8 md:pb-16",
                image: "pt-12 md:pt-0 pb-16 md:pb-0",
              },
            }}
          />
        </div>
        <div className="full-columns md:row-start-5 md:row-end-13 lg:row-start-4">
          <Grid className="grid">
            {items.map((item, index) => {
              if (index % 2 === 0 || index === 0) {
                return (
                  <div
                    key={index}
                    data-hax
                    className={`pb-16 md:pb-0 full-columns md:col-start-1 md:col-end-7 row-start-${
                      index + 1
                    } row-end-${index + 2}`}
                  >
                    <Quote
                      icon={item.icon}
                      image={item.image}
                      htmlText={item.htmlText}
                      name={item.name}
                      position={item.position}
                      city={item.city}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    data-hax
                    className={`pb-16 md:pb-0 full-columns md:col-start-7 md:col-end-13 row-start-${
                      index + 1
                    } row-end-${index + 2}`}
                  >
                    <Quote
                      icon={item.icon}
                      image={item.image}
                      htmlText={item.htmlText}
                      name={item.name}
                      position={item.position}
                      city={item.city}
                    />
                  </div>
                );
              }
            })}
          </Grid>
        </div>
      </Grid>
    </Container>
  </div>
);

MeetTheTeamDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string,
    hrefTo: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      htmlText: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
    })
  ),
};

MeetTheTeamDetail.defaultProps = {};

export default MeetTheTeamDetail;
