import React from "react";
import PropTypes from "prop-types";
import TitleTextCtaImage from "./TitleTextCtaImage";
import Container from "./Container";
import Wrapper from "./Wrapper";
import Separator from "./Separator";
import Grid from "./Grid";
import htmlStyle from "../lib/htmlStyle";
import SPImage from "./SPImage";

const WeWillHelpYou = ({ top, middle, bottom }) => (
  <div className="relative">
    <Wrapper backgroundColor="gray" className="relative">
      <Container className="overflow-hidden md:pb-24">
        <TitleTextCtaImage
          subtitle={top.subtitle}
          title={top.title}
          htmlText={top.htmlText}
          image={top.image}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "vertical",
            imageSize: "huge",
            imageSwitchMobile: true,
            imageTall: true,
            imageSendBack: true,
          }}
          style={{
            isFirstTitle: true,
            titleSize: "big",
            textColor: "purple",
            wrappers: {
              subtitle: "pb-6",
              title: "pb-8 md:pb-16",
              htmlText: "pb-8 md:pb-16",
              image: "mb-8 md:mb-12",
            },
          }}
        />
      </Container>
      <div className="absolute bottom-0 left-0 right-0">
        <Separator topColor="transparent" bottomColor="purple-light" />
      </div>
    </Wrapper>
    <Wrapper backgroundColor="purple-light">
      <Container>
        <div className="md:mb-12">
          <TitleTextCtaImage
            htmlText={middle.htmlText}
            cta={middle.cta}
            image={middle.image}
            layout={{
              imagePositionX: "right",
              imagePositionY: "top",
              imageOrientation: "horizontal",
              imageSize: "normal",
              imageTinyMobile: true,
              imageTinyPosition: "right",
            }}
            style={{
              textColor: "purple",
              cta: {
                backgroundColor: "red",
                titleColor: "white",
              },
              wrappers: {
                htmlText: "pb-12",
                image: "pb-10 md:pb-0",
              },
            }}
          />
        </div>
        <Grid className="grid md:grid-md md:py-24 grid-gap-small md:gap-none">
          <div
            data-hax
            className="pt-20 pb-12 md:py-0 row-start-1 row-end-5 full-columns md:row-start-1 md:row-end-span-4 block md:col-start-1 md:col-end-9"
          >
            <SPImage
              data-bax="-80, 80"
              className="block w-full"
              {...bottom.image1}
            />
          </div>
          <div
            data-hax
            className="pb-12 md:pb-24 mobile-image-layout md:row-start-2 md:row-end-span-12 md:col-start-7 md:col-end-13 lg:row-start-3 lg:col-start-7 lg:col-end-12"
          >
            <SPImage
              data-bax="-80, 80"
              className="block w-full"
              {...bottom.image2}
            />
          </div>
          <div
            data-hax
            className="full-columns row-start-5 md:row-start-10 md:row-end-span-12 md:col-start-1 md:col-end-span-5 lg:col-end-span-4"
            dangerouslySetInnerHTML={htmlStyle(bottom.htmlText, "purple")}
          ></div>
        </Grid>
      </Container>
    </Wrapper>
  </div>
);

WeWillHelpYou.propTypes = {
  top: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    htmlText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }).isRequired,
  middle: PropTypes.shape({
    htmlText: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      text: PropTypes.string,
      hrefTo: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }).isRequired,
  bottom: PropTypes.shape({
    htmlText: PropTypes.string.isRequired,
    image1: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
    image2: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

WeWillHelpYou.defaultProps = {};

export default WeWillHelpYou;
