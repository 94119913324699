import React from "react";
import PropTypes from "prop-types";
import Multiline from "./Multiline";

// H3 -
// (medium) desktop: 50px - mobile: 40px
// (small) desktop: 50px - mobile: 30px
// (tiny) desktop: 40px - mobile: 30px

const Title3 = ({ className, text, color, size }) => {
  let textSize = "";

  switch (size) {
    case "medium":
      textSize = "text-40px md:text-50px";
      break;
    case "small":
      textSize = "text-30px md:text-50px";
      break;
    case "tiny":
      textSize = "text-30px md:text-40px";
      break;
  }

  return (
    <h3
      data-hax
      className={`
        text-${color} font-ttNorms font-bold leading-none
        ${textSize}
        ${className}
      `}
    >
      <Multiline text={text} />
    </h3>
  );
};

Title3.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["medium", "small", "tiny"]),
};

Title3.defaultProps = {
  className: "",
};

export default Title3;
