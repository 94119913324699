import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";

const ButtonApplyLinkedin = ({ desktopText, logo, logoAltText, onClick }) => (
  <a title="LinkedIn" onClick={onClick}>
    <div
      className={`
        flex flex-row justify-center items-center transition-all
        bg-blue-linkedin fill-white
        hover:fill-blue-linkedin hover:bg-transparent text-white hover:text-blue-linkedin
        w-40 md:w-50 h-10 flex border-solid border-blue-linkedin border-2 rounded-s
      `}
    >
      <span className="hidden md:block md:font-bold md:text-tiny md:mr-3">
        {desktopText}
      </span>
      <ReactSVG src={logo} alt={logoAltText} />
    </div>
  </a>
);

ButtonApplyLinkedin.propTypes = {
  linkedinUrl: PropTypes.string.isRequired,
  desktopText: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoAltText: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonApplyLinkedin.defaultProps = {
  logoAltText: "",
};

export default ButtonApplyLinkedin;
