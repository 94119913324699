import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import TitleTextCtaImage from "./TitleTextCtaImage";

const CreatingFun = ({ subtitle, title, htmlText, image }) => (
  <div className="bg-gray">
    <Container>
      <TitleTextCtaImage
        subtitle={subtitle}
        title={title}
        htmlText={htmlText}
        image={image}
        layout={{
          imagePositionX: "right",
          imagePositionY: "top",
          imageOrientation: "vertical",
          imageSize: "normal",
          textSizeBig: true,
          imageTall: true,
        }}
        style={{
          isFirstTitle: true,
          titleSize: "big",
          textColor: "purple",
          wrappers: {
            htmlText: "pb-0",
            image: "hidden md:block",
          },
        }}
      />
    </Container>
  </div>
);

CreatingFun.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

CreatingFun.defaultProps = {};

export default CreatingFun;
