import React, { useState } from "react";
import PropTypes from "prop-types";
import * as components from "../data/components";

const DocsHelper = ({ component }) => {
  const [visible, setVisible] = useState(false);

  const parsed = Object.assign({}, components.default);
  const key = Object.keys(parsed).find(
    (key) => parsed[key].displayName === component
  );
  const info = parsed[key];

  const renderPropInfo = (prop, info) => {
    const name = typeof info.type !== "undefined" ? info.type.name : info.name;
    const value =
      typeof info.type !== "undefined" ? info.type.value : info.value;
    return (
      <div
        key={prop}
        className="p-2 px-4 border-b border-solid border-purple-light"
      >
        <div className="flex justify-between">
          <span className="font-bold">
            {prop}
            {info.required && "*"}
          </span>
          <span className="italic">{name}</span>
        </div>
        {name === "shape" && (
          <div className="mt-2 border-t border-solid border-purple-light bg-gray">
            {Object.keys(value).map((prop2) =>
              renderPropInfo(prop2, value[prop2])
            )}
          </div>
        )}
        {name === "arrayOf" && value.name === "shape" && (
          <div className="mt-2 border-t border-solid border-purple-light bg-gray">
            {Object.keys(value.value).map((prop2) =>
              renderPropInfo(prop2, value.value[prop2])
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="md:w-1/2 right-0 bottom-0 fixed z-50 border-l border-solid border-blue-dark bg-white">
      <div
        className="font-bold bg-blue-dark p-2 px-4 text-white flex justify-between items-center cursor-pointer"
        onClick={() => setVisible(!visible)}
      >
        <span>{info.displayName}</span>
        <span className="font-normal">{visible ? "Close" : "Open"}</span>
      </div>
      <div
        className={`${!visible ? "hidden" : ""} overflow-scroll`}
        style={{ maxHeight: "50vh" }}
      >
        <div className="bg-gray p-2 px-4 font-normal italic">{key}</div>
        {Object.keys(info.props).map((prop) =>
          renderPropInfo(prop, info.props[prop])
        )}
      </div>
    </div>
  );
};

DocsHelper.propTypes = {
  component: PropTypes.string.isRequired,
};

DocsHelper.defaultProps = {};

export default DocsHelper;
