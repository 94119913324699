import React, { useState } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Grid from "./Grid";
import VerticalSpacer from "./VerticalSpacer.js";
import isUrl from "../propTypes/isUrl.js";
import { url } from "../selectors/adpartners.js";
import localizationData from "../data/localization/adpartners.js";

const Selector = ({ onChange, value, langs }) => {
  const props = {
    onChange,
    value,
  };
  const options = langs.map((lang, i) => (
    <option key={i} value={lang.code}>
      {lang.language}
    </option>
  ));
  return <select {...props}>{options}</select>;
};

const urlToLink = (url) => (
  <a className="text-blue-light" href={url}>
    {url}
  </a>
);

const AdPartners = ({
  entries,
  langs,
  selectedLang,
  title,
  partner,
  privacyPolicy,
  userChoice,
}) => {
  const onSelectChange = (event) =>
    (window.location.href = url(localizationData[event.target.value]));
  const selectorProps = {
    onChange: onSelectChange,
    value: selectedLang,
    langs,
    style: {
      fontFamily: "Lato",
    },
  };
  const tableStyle = {
    width: "100%",
    borderWidth: "1",
  };
  const titleStyle = {
    fontSize: "40px",
    fontFamily: "TT Norms",
  };
  const headerCellStyle = {
    padding: "10px",
    fontFamily: "TT Norms",
    borderBottomWidth: "3px",
    whiteSpace: "nowrap",
  };
  const bodyRowStyle = {
    padding: "10px",
    borderBottomWidth: "1px",
  };

  return (
    <div className="bg-purple">
      <Container>
        <Grid className="grid">
          <div className="md:row-start-1md:row-end-11 md:col-start-1 md:col-end-11 full-columns row-start-1 row-end-11">
            <div className="text-center text-base py-12 md:py-16">
              <h1 className="white font-bold" style={titleStyle}>
                {title}
              </h1>
              <VerticalSpacer height="50px" />
              <div>
                <Selector {...selectorProps} />
              </div>
              <VerticalSpacer height="50px" />
              {
                <table className="white" style={tableStyle}>
                  <thead>
                    <tr>
                      {[partner, privacyPolicy, userChoice].map((text, i) => (
                        <th key={i} style={headerCellStyle}>
                          {text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry, i) => (
                      <tr key={i}>
                        <td style={{ ...bodyRowStyle, whiteSpace: "nowrap" }}>
                          {entry.partner}
                        </td>
                        <td style={bodyRowStyle}>
                          {urlToLink(entry.privacyPolicy)}
                        </td>
                        <td style={bodyRowStyle}>
                          {entry.userChoice.map(({ url, comments }, i, arr) => (
                            <span key={i}>
                              {urlToLink(url)}
                              <br />
                              {comments}
                              {i + 1 > arr.length && <br />}
                            </span>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

AdPartners.propTypes = {
  selectedLang: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  privacyPolicy: PropTypes.string.isRequired,
  userChoice: PropTypes.string.isRequired,
  langs: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      language: PropTypes.string.isRequired,
    })
  ),
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      partner: PropTypes.string.isRequired,
      privacyPolicy: isUrl,
      userChoice: PropTypes.arrayOf(
        PropTypes.shape({
          url: isUrl,
          comments: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

AdPartners.defaultProps = {
  selectedLang: "en",
  title: "Socialpoint Ad Partners",
  partner: "Partner",
  privacyPolicy: "Privacy Policy",
  userChoice: "User's Choice",
  langs: [
    {
      code: "en",
      language: "ENGLISH",
    },
  ],
};

export default AdPartners;
