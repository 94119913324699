import { useEffect } from "react";
require("intersection-observer");

const useOnScreen = (ref, callback, rootMargin = "0px") => {
  useEffect(() => {
    if (!ref.current instanceof Element) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        callback(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (ref.current && observer) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
};

export default useOnScreen;
