import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Button from "./Button";
import htmlStyle from "../lib/htmlStyle";

const CareersOpenings = ({ htmlText, cta }) => {
  return (
    <Container>
      <div className="flex flex-col w-full">
        <div className="md:w-1/2 lg:w-1/3 pb-12 text-base text-purple">
          <div data-hax dangerouslySetInnerHTML={htmlStyle(htmlText)} />
        </div>
        <div data-hax>
          <Button
            backgroundColor="red"
            title={cta.text}
            hrefTo={cta.hrefTo}
            color="white"
          />
        </div>
      </div>
    </Container>
  );
};

CareersOpenings.propTypes = {
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string,
    hrefTo: PropTypes.string,
  }),
};

CareersOpenings.defaultProps = {};

export default CareersOpenings;
