import React from "react";
import PropTypes from "prop-types";
import SPLink from "./SPLink";

const MenuPrimaryLink = ({
  id,
  title,
  hrefTo,
  onMouseEnterCallback,
  onMouseLeaveCallback,
  active,
  color,
}) => {
  const className = `select-none cursor-pointer text-base-big md:text-base font-bold text-white inline-block md:block mb-4 md:mb-0 md:mt-8 md:rotate-180 md:vertical-text border-transparent border-b-2 md:transition-all md:hover:text-${color} md:border-b-0 ${
    active ? `text-${color} border-${color} border-b-2` : `md:text-blue-dark`
  }`;
  if (hrefTo.length > 0) {
    return (
      <SPLink
        className={className}
        to={hrefTo}
        title={title}
        onMouseEnter={() => onMouseEnterCallback(id)}
        onMouseLeave={() => onMouseLeaveCallback(id)}
        data-link={id}
      >
        {title}
      </SPLink>
    );
  }
  return (
    <span
      data-link={id}
      className={className}
      onMouseEnter={() => onMouseEnterCallback(id)}
      onMouseLeave={() => onMouseLeaveCallback(id)}
    >
      {title}
    </span>
  );
};

MenuPrimaryLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hrefTo: PropTypes.string.isRequired,
  color: PropTypes.string,
  onMouseEnterCallback: PropTypes.func,
  onMouseLeaveCallback: PropTypes.func,
  active: PropTypes.bool,
};

MenuPrimaryLink.defaultProps = {
  active: false,
  color: "yellow",
  onMouseEnterCallback: () => {},
  onMouseLeaveCallback: () => {},
};

export default MenuPrimaryLink;
