import React from "react";
import PropTypes from "prop-types";
import SPLink from "./SPLink";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const Button = ({
  backgroundColor,
  hoverBackgroundColor,
  color,
  hoverColor,
  title,
  hrefTo,
  className,
  size,
  noLink,
  target,
}) => {
  const handleClick = () => {
    trackCustomEvent({
      category: "Button",
      action: `${title} - ${window.location.pathname}`,
      label: hrefTo,
    });
  };
  return (
    <>
      {!noLink ? (
        <SPLink
          className={`${className} text-${color} hover:text-${
            hoverColor === "none" ? backgroundColor : hoverColor
          } bg-${backgroundColor} hover:bg-transparent border-solid border-2 border-transparent ${
            hoverBackgroundColor
              ? `hover:border-${hoverBackgroundColor}`
              : `hover:border-${backgroundColor}`
          } font-bold font-lato inline-block text-base-big cursor-pointer transition-all ${
            size === "normal"
              ? "py-4 px-12 md:px-16 rounded"
              : `${
                  size === "small"
                    ? "py-2 px-4 md:px-8 rounded-half"
                    : "py-4 px-8 rounded"
                }`
          } font-ttNorms`}
          to={hrefTo}
          title={title}
          onClick={handleClick}
          target={target}
        >
          {title}
        </SPLink>
      ) : (
        <div
          className={`${className} text-${color} hover:text-${
            hoverColor === "none" ? backgroundColor : hoverColor
          } bg-${backgroundColor} hover:bg-transparent border-solid border-2 border-transparent ${
            hoverBackgroundColor
              ? `hover:border-${hoverBackgroundColor}`
              : `hover:border-${backgroundColor}`
          } font-bold font-lato inline-block text-base-big cursor-pointer transition-all ${
            size === "normal"
              ? "py-4 px-12 md:px-16 rounded"
              : `${
                  size === "small"
                    ? "py-2 px-4 md:px-8 rounded-half"
                    : "py-4 px-8 rounded"
                }`
          } font-ttNorms`}
        >
          {title}
        </div>
      )}
    </>
  );
};

Button.propTypes = {
  hoverBackgroundColor: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hrefTo: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "normal"]),
  noLink: PropTypes.bool,
  target: PropTypes.string,
};

Button.defaultProps = {
  hoverColor: "none",
  className: "",
  size: "normal",
  hoverBackgroundColor: "",
  noLink: false,
  target: "",
};

export default Button;
