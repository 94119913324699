import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title3 from "./Title3";
import Video from "./Video";

const GameReel = ({ title, video }) => (
  <div className="pb-24">
    <Container className="mb-8 md:mb-12">
      <Title3 {...title} size="medium" color="purple" />
    </Container>
    <Video {...video} />
  </div>
);

GameReel.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    src: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    player: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
      backgroundColor: PropTypes.string.isRequired,
      color: PropTypes.string,
    }).isRequired,
  }),
};

GameReel.defaultProps = {};

export default GameReel;
