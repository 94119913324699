import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import DragGallery from "./DragGallery";
import DragInstructions from "./DragInstructions";
import SPImage from "./SPImage";

const StepItem = ({ image }, index) => {
  const item = useRef(null);
  useEffect(() => {
    item.current.onmouseenter = () => {
      item.current.style.transform = "scale(1.06)";
    };
    item.current.onmouseleave = () => {
      item.current.style.transform = "none";
    };
  }, []);

  return (
    <div
      key={index}
      className="relative mr-8 relative before:parallel-shadow-black transition-all"
      ref={item}
    >
      <div className="relative transition-all z-10">
        <SPImage
          {...image}
          className="h-180px md:h-300px w-auto max-w-none pointer-events-none"
        />
      </div>
    </div>
  );
};

const GameDetailGallery = ({ steps, dragInstructions, title }) => {
  const [triggerForward, setTriggerForward] = useState(0);

  return (
    <div data-hax className="bg-gray md:pt-24">
      <Container>
        <div className="py-8 md:flex justify-between items-center">
          <h4 className="row-start-1 row-end-2 text-medium text-purple font-bold font-ttNorms">
            {title}
          </h4>
          <div
            onClick={() => {
              const next = triggerForward + 1;
              setTriggerForward(next);
            }}
          >
            <DragInstructions
              icon={dragInstructions.icon}
              text={dragInstructions.text}
              color="purple"
              className="hidden md:flex"
            />
          </div>
        </div>
        <DragGallery triggerForward={triggerForward}>
          {steps.map((step, index) => StepItem(step, index))}
        </DragGallery>
        <div
          className="pt-8 md:hidden"
          onClick={() => {
            const next = triggerForward + 1;
            setTriggerForward(next);
          }}
        >
          <DragInstructions
            icon={dragInstructions.icon}
            text={dragInstructions.text}
            color="purple"
          />
        </div>
      </Container>
    </div>
  );
};

StepItem.propTypes = {
  year: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

GameDetailGallery.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  dragInstructions: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }).isRequired,
  }),
  title: PropTypes.string.isRequired,
};

StepItem.defaultProps = {
  image: {
    alt: "",
  },
};

GameDetailGallery.defaultProps = {
  dragInstructions: {
    icon: {
      alt: "",
    },
  },
};

export default GameDetailGallery;
