import React from "react";
import PropTypes from "prop-types";
import Title1 from "./Title1";
import Container from "./Container";
import Grid from "./Grid";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";
import BenefitsItem from "./BenefitsItem";

const BenefitsTwoImages = ({
  title,
  htmlText,
  image1,
  image2,
  items,
  highlight,
  backgroundColor,
}) => {
  return (
    <div className={`bg-${backgroundColor}`}>
      <Container>
        <Grid className="grid grid-row-gap-big">
          {title && (
            <div className="full-columns row-start-1 row-end-2">
              <div className="w-full md:max-w-60p">
                <Title1 color="purple" text={title} />
                <div
                  data-hax
                  className="md:w-2/3 mt-8"
                  dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
                />
              </div>
            </div>
          )}
          <div className="full-columns md:row-start-2 md:row-end-3 md:col-start-1 md:col-end-6">
            <div data-hax>
              <BenefitsItem layout="big" item={items[0]} />
            </div>
          </div>
          <div
            className={`full-columns row-start-2 md:col-start-7 md:col-end-13 lg:col-start-8 lg:col-end-13`}
            data-hax
          >
            <SPImage
              data-bax="-40, 40"
              className="w-full"
              src={image1.src}
              alt={image1.alt}
            />
          </div>
          <div
            className={`z-10 pb-8 md:pb-0 col-start-2 col-end-13 ${
              highlight
                ? "row-start-6 md:row-start-3 md:row-end-6"
                : "row-start-5 md:row-start-3 md:row-end-5"
            } md:col-start-1 md:col-end-6 lg:col-start-2 lg:col-end-5 ${
              title ? " pt-8" : ""
            }`}
            data-hax
          >
            <SPImage
              data-bax="100, -80"
              className={`${highlight ? "md:pt-32" : "md:pt-0"}`}
              src={image2.src}
              alt={image2.alt}
            />
          </div>
          <div
            data-hax
            className="full-columns md:row-start-3 md:col-start-7 md:col-end-13 lg:col-start-8 lg:col-end-13 md:pt-8"
          >
            <BenefitsItem layout="big" item={items[1]} />
          </div>
          <div
            data-hax
            className="full-columns md:row-start-4 md:col-start-7 md:col-end-13 lg:col-start-8 lg:col-end-13 md:pt-8"
          >
            <BenefitsItem layout="big" item={items[2]} />
          </div>
          {highlight && (
            <h3
              className="full-columns row-start-5 md:col-start-7 md:col-end-13 lg:col-start-8 lg:col-end-13 pb-4 text-small-big font-ttNorms text-purple font-bold leading-tight"
              data-hax
            >
              {highlight}
            </h3>
          )}
        </Grid>
      </Container>
    </div>
  );
};

BenefitsTwoImages.defaultValue = {
  backgroundColor: "gray",
  highlight: null,
  htmlText: null,
  title: null,
};

BenefitsTwoImages.propTypes = {
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  htmlText: PropTypes.string,
  image1: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  image2: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      htmlText: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    })
  ),
  highlight: PropTypes.string,
};

export default BenefitsTwoImages;
