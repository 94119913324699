const colors = {
  red: "#EF5948",
  purple: {
    default: "#4E4B7D",
    medium: "#9796AC",
    light: "#CBCAD9"
  },
  teal: "#2a899c",
  gray: "#F1F1F1",
  green: "#57B75D",
  yellow: "#F9C75C",
  neonBlue: "#287CEA",
  black: "#000",
  blue: {
    dark: "#1B1A2E",
    light: "#78C6D4",
    linkedin: "#2867B2"
  },
  white: "#fff"
};

module.exports = colors;
