import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";

const HeaderSliderArrow = ({ first, src, alt, onClick }) => (
  <span
    className={`${first && "-ml-2 mr-6"}
    py-2 px-2 cursor-pointer select-none transition-all md:hover:opacity-25 active:opacity-25`}
    onClick={onClick}
  >
    <SPImage src={src} alt={alt} />
  </span>
);

HeaderSliderArrow.propTypes = {
  first: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

HeaderSliderArrow.defaultProps = {
  first: false,
  src: "",
  alt: "",
  onClick: () => {},
};

export default HeaderSliderArrow;
