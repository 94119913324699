import React from "react";
import Container from "./Container";
import PropTypes from "prop-types";
import Select from "./Select";
import Input from "./Input";

const Filter = ({
  title,
  titleColor,
  filters,
  activeOffers,
  activeOffersColor,
  activeOffersText,
  filterCallback,
  lists,
}) => {
  const criteria = { roles: false, teams: false };
  const callback = (name, value) => {
    if (value === "all") {
      criteria[name] = false;
      filterCallback(criteria);
      return;
    }
    criteria[name] = value;
    filterCallback(criteria);
  };
  return (
    <Container>
      <div className="py-12 z-10">
        <h2 className={`uppercase font-bold text-${titleColor}`}>{title}</h2>
        <div
          className={`
          flex flex-col
          md:flex-row md:full-w md:mt-8
        `}
        >
          <div className="my-2 md:w-1/3 md:my-0 md:mr-6">
            <Select
              items={lists.teams}
              placeholder={filters.teams.placeholder}
              callback={callback}
              name="teams"
              textColor="purple"
              icon={filters.teams.icon}
            />
          </div>
          <div className="my-2 md:w-1/3 md:my-0 md:mr-6">
            <Select
              items={lists.roles}
              placeholder={filters.roles.placeholder}
              callback={callback}
              name="roles"
              textColor="purple"
              icon={filters.roles.icon}
            />
          </div>
          <div className="my-6 md:w-1/3 md:my-0">
            <Input
              {...filters.search}
              onChange={callback}
              name="search"
              textColor="purple"
            />
          </div>
        </div>
        <div className={`text-${activeOffersColor} mt-8`}>
          {activeOffers} {activeOffersText}
        </div>
      </div>
    </Container>
  );
};

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  activeOffers: PropTypes.number.isRequired,
  activeOffersColor: PropTypes.string.isRequired,
  activeOffersText: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    teams: PropTypes.shape({
      placeholder: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
      items: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    roles: PropTypes.shape({
      placeholder: PropTypes.string,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      items: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    search: PropTypes.shape({
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  filterCallback: PropTypes.func.isRequired,
  lists: PropTypes.shape({
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default Filter;
