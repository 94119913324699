import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import SPImage from "./SPImage";
import htmlStyle from "../lib/htmlStyle";

const Quote = ({ icon, image, htmlText, name, position, city }) => {
  return (
    <div className="flex flex-col w-full">
      <SPImage {...image} className="w-2/3 md:w-1/2" />
      <div className="pt-12 pb-4 flex flex-col md:flex-row">
        <ReactSVG {...icon} className="mb-6 self-start md:mr-12 fill-purple" />
        <div
          className="italic whitespace-pre-line"
          dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
        />
      </div>
      <h3 className="font-ttNorms text-purple font-bold md:text-base-small">
        {name}
      </h3>
      <h3 className="font-ttNorms text-purple font-bold md:text-base-small">
        {position}
      </h3>
      <h3 className="hidden md:block font-ttNorms text-purple font-bold text-base md:text-base-small">
        {city}
      </h3>
    </div>
  );
};

Quote.propTypes = {
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  htmlText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

Quote.defaultProps = {
  imageAltText: "",
  quoteIconAltText: "",
};

export default Quote;
