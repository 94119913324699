import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import useDidMount from "../hooks/useDidMount";
import * as easings from "d3-ease";

let lastX = 0;
let maxX = 0;
let h;
let isScrolling = false;
let totalDeltaX = 0;
let translateX = 0;

const outerWidth = (el) => {
  let width = el.offsetWidth;
  const style = getComputedStyle(el);
  const marginRight = style.marginRight.split("p")[0];
  const marginLeft = style.marginLeft.split("p")[0];
  width += parseInt(marginRight) + parseInt(marginLeft);
  return width;
};

const timer = (ms) => {
  return new Promise((res) => setTimeout(res, ms));
};

const toggleScrolling = async (e) => {
  if (e.type === "touchend") isScrolling = false;
  if (e.type === "touchstart") {
    isScrolling = true;
    await timer(100);
    isScrolling = false;
  }
  if (e.type === "scroll") isScrolling = true;
};

const DragGrallery = ({
  children,
  pagination,
  // activeSlideCallback,
  // activeSlide,
  animationConfig,
  keepContent,
}) => {
  const didMount = useDidMount();
  const gallery = useRef(null);
  const galleryContent = useRef(null);
  const [props, set] = useSpring(() => ({
    transform: `translate3d(0%, 0px, 0px)`,
  }));

  const setSpring = (p) => {
    // set({ transform: `translate3d(${p}%, 0px, 0px)`, config: { duration: animationConfig.duration, easing: easings.easePolyOut, delay: 3000 } })
  };

  // useEffect(() => {
  //   // if (didMount && pagination) {
  //   //   const p = -(100 / galleryContent.current.children.length) * activeSlide
  //   //   setSpring(p)
  //   //   const prev = parseInt(gallery.current.getAttribute('data-current'))
  //   //   gallery.current.setAttribute('data-current', activeSlide)
  //   //   activeSlideCallback(prev, activeSlide)
  //   // }
  // }, [])

  useEffect(() => {
    window.addEventListener("scroll", toggleScrolling, { passive: true });

    window.addEventListener("touchend", toggleScrolling);

    const childWidth = galleryContent.current.children[0].getBoundingClientRect();
    console.log(childWidth);
    const totalWidth = childWidth * children.length;
    const relDis = gallery.current.getBoundingClientRect().left;

    // gallery.current.setAttribute('data-current', activeSlide)
    document.addEventListener("resize", handleResize);
    const Hammer = require("hammerjs");
    h = new Hammer.Manager(gallery.current, {
      touchAction: "auto",
      recognizers: [
        [
          Hammer.Pan,
          {
            threshold: 100,
            pointers: 0,
            direction: Hammer.DIRECTION_HORIZONTAL,
          },
        ],
      ],
    });
    h.on("pan", (e) => {
      // if (e.pointerType === 'touch' && pagination) {
      //   timer(0)
      //   if (isScrolling) return
      // }

      translateX = totalDeltaX + e.deltaX;
      if (translateX < 0 && Math.abs(translateX) < maxX) {
        galleryContent.current.style.transform = `translate3d(${translateX}px, 0, 0)`;
        return;
      }
      if (Math.abs(totalDeltaX) >= maxX) {
        galleryContent.current.style.transform = `translate3d(-${maxX}px, 0, 0)`;
      }
    });
    h.on("panend", (e) => {
      const { deltaX } = e;
      if (
        Math.abs(totalDeltaX) < maxX ||
        (deltaX > 0 && Math.abs(totalDeltaX) - deltaX > 0)
      ) {
        totalDeltaX += deltaX;
        if (totalDeltaX > 0) totalDeltaX = 0;
      }
      console.log(galleryContent.current.children[0].getBoundingClientRect());
      // const largo = totalWidth + totalDeltaX
      // console.log('ahora mi largo es', totalWidth + totalDeltaX)
      // console.log((totalDeltaX + (largo / 11)) + totalDeltaX)

      // console.log(maxX / children.length)
      // console.log('esto', totalWidth + totalDeltaX)
      // console.log('esto', totalDeltaX + totalDeltaX)

      // const nearest = Math.round(totalWidth / (maxX + totalDeltaX - childWidth)) * childWidth
      // console.log(nearest)
      // const nearest = (-maxX - Math.abs(totalDeltaX)) / children.length
      // const nearest = totalDeltaX + childWidth
      // console.log(Math.abs(nearest) - Math.abs(totalDeltaX))
      setTimeout(() => {
        // if (nearest < maxX) {
        //   galleryContent.current.style.transform = `translate3d(-${nearest}px, 0, 0)`
        //   totalDeltaX = nearest
        // } else {
        //   galleryContent.current.style.transform = `translate3d(-${maxX}px, 0, 0)`
        //   totalDeltaX = maxX
        // }
      }, 1000);
    });

    handleResize();

    setTimeout(() => {
      handleResize();
    }, 1000);
    return function cleanup() {
      document.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", toggleScrolling);
      window.removeEventListener("touchend", toggleScrolling);
    };
  }, []);

  const handleResize = () => {
    gallery.current.style.height = `${galleryContent.current.offsetHeight}px`;
    console.log("child", galleryContent.current.offsetWidth);
    console.log("parent", galleryContent.current.parentNode.offsetWidth);
    maxX =
      galleryContent.current.offsetWidth -
      galleryContent.current.parentNode.offsetWidth;
    console.log("max", maxX);
  };

  return (
    <div className="relative" ref={gallery}>
      <div className="relative overflow-visible h-full">
        <animated.div
          ref={galleryContent}
          className="inline-flex cursor-ew-resize active:cursor-grab will-change-transform"
          style={{
            transform: "translate3d(0px, 0, 0)",
          }}
          // style={pagination ? props : {}}
        >
          {children}
        </animated.div>
      </div>
    </div>
  );
};

DragGrallery.propTypes = {
  children: PropTypes.node.isRequired,
  pagination: PropTypes.bool,
  activeSlideCallback: PropTypes.func,
  activeSlide: PropTypes.number,
  animationConfig: PropTypes.shape({
    duration: PropTypes.number,
    delay: PropTypes.number,
  }),
  changeSlideFromOutside: PropTypes.bool,
};

DragGrallery.defaultProps = {
  pagination: false,
  activeSlideCallback: () => {},
  animationConfig: {
    duration: 1000,
    delay: 0,
  },
  keepContent: true,
};

export default DragGrallery;
