import React from "react";
import { Helmet } from "react-helmet";
import { JobPosting } from "schema-dts";

const JobOfferSeo = ({ title, description, datePosted }) => {
  return (
    <Helmet defer={false}
      script={[
        {
          type: "application/ld+json",
          innerHTML: `{
          "@context": "http://schema.org",
          "@type": "JobPosting",
          "title": "${title}",
          "description": "${description}",
          "hiringOrganization" : {
            "@type": "Organization",
            "name": "Socialpoint"
          },
          "datePosted": "${datePosted}",
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Carrer de la Llacuna, 166",
              "addressRegion": "Barcelona",
              "addressLocality": "Barcelona",
              "postalCode": "08018",
              "addressCountry": "ES"
            }
          }
        }`,
        },
      ]}
    />
  );
};

export default JobOfferSeo;
