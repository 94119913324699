import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import SPImage from "./SPImage";
import Grid from "./Grid";
import Button from "./Button";
import htmlStyle from "../lib/htmlStyle";

const Error404 = ({ titleImage1, titleImage2, image, htmlText, cta }) => {
  return (
    <div className="bg-blue-light">
      <Container>
        <Grid className="grid">
          <div className="md:row-start-3 md:row-end-8 md:col-start-1 md:col-end-4 mobile-image-layout row-start-5 row-end-6 mt-16 md:mt-0">
            <SPImage src={image.src} alt={image.alt} />
          </div>
          <div className="md:row-start-1 md:row-end-4 md:col-start-5 md:col-end-9 col-start-3 col-end-11 row-start-1 row-end-2">
            <SPImage src={titleImage1.src} alt={titleImage1.alt} />
          </div>
          <div className="md:row-start-4 md:row-end-5 md:col-start-3 md:col-end-11 full-columns row-start-2 row-end-3">
            <SPImage src={titleImage2.src} alt={titleImage2.alt} />
          </div>
          <div className="md:row-start-5 md:row-end-6 md:col-start-5 md:col-end-9 full-columns row-start-3 row-end-4">
            <div
              className="text-center text-base py-12 md:py-16"
              dangerouslySetInnerHTML={htmlStyle(htmlText, "white")}
            />
          </div>
          <div className="md:row-start-6 md:row-end-7 md:col-start-5 md:col-end-9 text-center full-columns row-start-4 row-end-5">
            <Button
              backgroundColor="yellow"
              hoverBackgroundColor="purple"
              color="purple"
              hoverColor="purple"
              title={cta.title}
              hrefTo={cta.hrefTo}
              size="normal"
            />
          </div>
        </Grid>
      </Container>
    </div>
  );
};

Error404.propTypes = {
  titleImage1: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  titleImage2: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }).isRequired,
};

Error404.defaultProps = {};

export default Error404;
