import React from "react";
import PropTypes from "prop-types";
import SPImage from "./SPImage";

const TextArea = ({
  name,
  icon,
  iconAltText,
  label,
  className,
  value,
  onChange,
}) => (
  <div className={className}>
    {label && (
      <label className="uppercase text-purple font-bold tracking-wide text-tiny font-ttNorms">
        {label}
      </label>
    )}
    <div className={`flex border-b border-purple`}>
      {icon && (
        <SPImage
          className="fill-current w-6 m-2"
          src={icon}
          alt={iconAltText}
        />
      )}
      <textarea
        id={name}
        name={name}
        value={value}
        className={`bg-transparent py-2 w-full h-56 md:h-40 text-purple`}
        onChange={(e) => onChange(name, e.target.value)}
      />
    </div>
  </div>
);

TextArea.defaultValue = {
  label: "",
  className: "",
};

TextArea.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  iconAltText: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea;
