const meta = {
  title: "Socialpoint",
  description:
    "Socialpoint is a world-renowned game developer and publisher, specialized in mobile gaming. Join our team and be part of something amazing.",
  keywords:
    "socialpoint, social point, mobile games, free to play, f2p, free 2 play, dragon city, monster legends, word life, social games, top Facebook games, mobile games developer",
  image:
    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/slider-header.jpg",
  favicon:
    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/favicon.gif",
};
export default meta;
