import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import SPLink from "./SPLink";
import SocialNetworks from "./SocialNetworks";
import SPImage from "./SPImage";
import Lottie from "react-lottie";
import * as animationData from "../lib/animation-header.json";

const Footer = ({ logo, logoAltText, contact, social, links }) => {
  const [logoAnimating, setLogoAnimating] = useState(false);
  const [focused, setFocused] = useState(false);
  const container = useRef(null);

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData.default,
    rendererSettings: {
      // preserveAspectRatio: 'xMidYMid slice'
    }
  };
  useEffect(() => {
    container.current.firstChild.removeAttribute("role");
  }, []);
  return (
    <div className="w-full bg-blue-dark pt-10 pb-8 pl-3 md:pt-16 md:pb-20 md:pl-0">
      <Container>
        <div className="md:flex md:flex-row md:justify-between">
          <div>
            <div className="pb-3 flex items-center -ml-2 md:ml-0 md:pl-2">
              <SPLink to="/">
                <div
                  className="block"
                  onMouseEnter={() =>
                    !logoAnimating ? setLogoAnimating(true) : null
                  }
                  onFocus={() => {
                    setLogoAnimating(true);
                    setFocused(true);
                  }}
                  onBlur={() => setFocused(false)}
                  aria-label="Socialpoint animated logo"
                  ref={container}
                >
                  <Lottie
                    options={lottieOptions}
                    height={50}
                    // width={60}
                    isStopped={!logoAnimating}
                    speed={1.5}
                    eventListeners={[
                      {
                        eventName: "complete",
                        callback: () => setLogoAnimating(false)
                      }
                    ]}
                  />
                </div>
              </SPLink>
            </div>
            <SocialNetworks
              itemsArray={social}
              svgColor="blue-dark"
              backgroundColor="white"
              marginsDesktop={{ left: "3", right: "0" }}
              marginsMobile={{ left: "0", right: "3" }}
              className="pb-6 md:pb-4 md:flex hidden"
            />
            <div className="pb-6 md:pb-0 md:pl-3">
              <p className="text-white font-ttNorms font-normal text-sm md:text-base">
                {contact.address}
              </p>
              <p className="text-white font-ttNorms font-normal text-sm md:text-base">
                {contact.city}
              </p>
              <p className="text-white font-ttNorms font-normal text-sm md:text-base">
                {contact.phone}
              </p>
            </div>
          </div>
          <div className="md:self-end">
            <SocialNetworks
              itemsArray={social}
              svgColor="blue-dark"
              backgroundColor="white"
              marginsDesktop={{ left: "3", right: "0" }}
              marginsMobile={{ left: "0", right: "3" }}
              className="pb-6 md:justify-end md:hidden"
            />
            <div className="mb-3 justify-end hidden md:flex">
              <img
                className="w-50 h-16"
                src="//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/zynga-logo.png"
                alt="zynga-logo"
              />
            </div>
            <div className="md:flex md:flex-row">
              {links.map((item, index) => (
                <div className="pb-1 md:pb-0" key={index}>
                  {index !== 0 ? (
                    <p className="hidden md:inline md:font-ttNorms md:text-white md:ml-2 md:mr-1">
                      {" "}
                      |{" "}
                    </p>
                  ) : null}
                  <SPLink
                    className="text-white hover:border-b-2 font-ttNorms font-bold text-sm md:text-base"
                    title={item.text}
                    to={item.hrefTo}
                  >
                    {item.text}
                  </SPLink>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-5 w-[100%] md:hidden">
            <img
              className="w-50 h-16"
              src="//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/zynga-logo.png"
              alt="zynga-logo"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
Footer.propTypes = {
  logo: PropTypes.string.isRequired,
  logoAltText: PropTypes.string,
  contact: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  }),
  social: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired
    })
  )
};

Footer.defaultProps = {
  logoAltText: ""
};

export default Footer;
