import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title1 from "./Title1";
import Button from "./Button";
import Share from "./Share";
import JobOfferSeo from "./SEO/JobOfferSeo";
import htmlStyle from "../lib/htmlStyle";

import { StickyContainer, Sticky } from "react-sticky";

const OfferDetail = ({ title, htmlText, cta, shareText, createdAt }) => {
  const apply = (style = {}) => (
    <div style={style} className="self-start h-auto mt-16 md:mt-24">
      <Button
        backgroundColor="red"
        color="white"
        hoverColor="red"
        title={cta.title}
        hrefTo={cta.hrefTo}
        className="mb-4"
        target="_blank"
      />
      <Share
        text={shareText}
        titleColor="purple-medium"
        svgColor="white"
        backgroundColor="purple-medium"
      />
    </div>
  );
  return (
    <Fragment>
      <JobOfferSeo
        title={title}
        description={htmlText}
        datePosted={createdAt}
      />
      <div className="bg-gray pt-40 md:pt-64">
        <Container>
          <div className="mb-10 md:mb-0">
            <Title1 text={title} color="purple" className="w-full" />
          </div>
          <div
            data-hax
            className="w-full flex flex-col md:flex-row md:justify-between"
          >
            <div
              dangerouslySetInnerHTML={htmlStyle(htmlText, "purple")}
              className="w-full md:w-3/4 md:pr-16 md:mt-24 job-offer-description"
            />
            <StickyContainer className="md:w-1/4 hidden md:block">
              <Sticky>{({ style }) => apply(style)}</Sticky>
            </StickyContainer>
            <div className="w-full block md:hidden pb-4">{apply()}</div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

OfferDetail.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  shareText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }).isRequired,
  createdAt: PropTypes.string.isRequired,
};

OfferDetail.defaultProps = {};

export default OfferDetail;
