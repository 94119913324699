import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import SPImage from "./SPImage";
import CtaWithArrows from "./CtaWithArrows";
import htmlStyle from "../lib/htmlStyle";
import Button from "./Button";
import Grid from "./Grid";

const Cookies = ({ htmlText, image, accept, edit }) => (
  <div className="bg-green">
    <Container>
      <div data-hax>
        <Grid className="grid">
          <div
            className="my-6 full-columns md:col-start-1 md:col-end-9 row-start-1 row-end-2"
            dangerouslySetInnerHTML={htmlStyle(htmlText, "purple text-small")}
          />
          <div className="col-start-1 col-end-8 md:col-end-9 row-start-2 row-end-3 flex flex-col md:flex-row mb-6">
            <Button
              backgroundColor="yellow"
              hoverBackgroundColor="purple"
              color="purple"
              hoverColor="purple"
              title={accept.cta.title}
              hrefTo={accept.cta.hrefTo}
              className="text-center mb-8 md:mb-0 md:mr-8"
            />
            <CtaWithArrows
              hrefTo={edit.cta.hrefTo}
              icon={edit.cta.icon}
              title={edit.cta.title}
              color="white"
              size="normal"
            />
          </div>
          <div className="col-start-9 col-end-13 md:col-start-11 md:col-end-13 md:row-start-1 row-start-2 row-end-3 md:-mt-16">
            <SPImage src={image.src} alt={image.alt} />
          </div>
        </Grid>
      </div>
    </Container>
  </div>
);

Cookies.propTypes = {
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  accept: PropTypes.shape({
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  edit: PropTypes.shape({
    cta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

Cookies.defaultProps = {
  image: {
    alt: "",
  },
  edit: {
    cta: {
      icon: {
        alt: "",
      },
    },
  },
};

export default Cookies;
