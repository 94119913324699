import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title3 from "./Title3";
import NewsItem from "./NewsItem";
import SocialNetworks from "./SocialNetworks";

const News = ({ title, socialTitle, items, social }) => {
  const news = items.map((item) => <NewsItem key={item.id} {...item} />);
  const sn = (
    <>
      <div className="mt-12 mb-6 w-3/4 leading-none">
        <span className="text-yellow md:text-white uppercase font-bold font-ttNorms">
          {socialTitle}
        </span>
      </div>
      <SocialNetworks
        itemsArray={social}
        svgColor="red"
        backgroundColor="yellow"
        marginsDesktop={{ left: "0", right: "3" }}
        marginsMobile={{ left: "0", right: "3" }}
      />
    </>
  );

  return (
    <div className="bg-red">
      <Container className="md:flex md:justify-end">
        <div className="md:w-11/12">
          <div className="flex justify-end pb-12">
            <Title3
              className="text-right"
              color="white"
              text={title}
              size="medium"
            />
          </div>
          <div className="block md:hidden" data-hax>
            {news}
            {sn}
          </div>
          <div className="hidden md:flex">
            <div className="w-7/12 flex flex-col items-end pr-8" data-hax>
              {typeof items[1] !== "undefined" && (
                <div className="w-3/5">
                  <NewsItem key={items[1].id} {...items[1]} />
                </div>
              )}
              {typeof items[2] !== "undefined" && (
                <div className="w-4/5">
                  <NewsItem key={items[2].id} {...items[2]} />
                </div>
              )}
            </div>
            <div className="w-5/12" data-hax>
              {typeof items[0] !== "undefined" && (
                <NewsItem key={items[0].id} {...items[0]} />
              )}
              <div data-hax>{sn}</div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

News.propTypes = {
  title: PropTypes.string.isRequired,
  socialTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      htmlText: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
      }),
    })
  ),
  social: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

News.defaultProps = {
  items: [],
  social: [],
};

export default News;
