import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Title1 from "./Title1";
import useIsMobile from "../hooks/useIsMobile";
import Video from "../components/Video";

const SectionHeader = ({ image, title, imageMobile, video }) => {
  const isMobile = useIsMobile();
  let style = {};
  if (isMobile) {
    style.backgroundImage = `url(${imageMobile.url})`;
  } else {
    style.backgroundImage = `url(${image})`;
  }
  if (video) {
    return (
      <div>
        <Video
          src={video.src}
          poster={video.poster}
          player={{
            src: video.player.src,
            alt: video.player.alt,
            backgroundColor: "purple",
            color: "white"
          }}
          fullWidth={video.fullWidth}
          videoAsHeader={video.videoAsHeader}
        />
      </div>
    );
  } else {
    return (
      <div
        style={style}
        className="w-full h-80vh bg-center bg-cover bg-no-repeat"
      >
        <div
          className="relative"
          style={{
            backgroundColor: "rgba(27, 26, 46, 0.33)",
            width: "100%",
            height: "100%"
          }}
        >
          <Container className="absolute bottom-0 pb-8 md:pb-16 pl-4 md:pl-24">
            <Title1 text={title} color="white" />
          </Container>
        </div>
      </div>
    );
  }
};

SectionHeader.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageMobile: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

SectionHeader.defaultProps = {};

export default SectionHeader;
