import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CWrapper from "./CWrapper";

const types = {};

function importAll(r) {
  r.keys().forEach((key) => {
    const filename = key.match(/([a-zA-Z0-9]+)\.js$/);
    if (filename) {
      types[filename[1]] = r(key).default;
    }
  });
}

importAll(require.context("./", true));

const ComponentIterator = ({ pageName, components }) => {
  let keyIndex = 0;

  return (
    <Fragment>
      {components.map((component, index) => {
        if (types[component.name]) {
          const ComponentType = types[component.name];
          const iterator = {
            isFirst: component.name !== "Wrapper" && index === 0,
            isLast:
              component.name !== "Wrapper" && index === components.length - 1,
          };
          return (
            <CWrapper
              key={`${component.name}_${keyIndex++}`}
              pageName={pageName}
              name={component.name}
              iterator={iterator}
            >
              <ComponentType {...component.props}>
                {component.components && (
                  <ComponentIterator
                    key={`${component.name}_${keyIndex++}`}
                    pageName={pageName}
                    components={component.components}
                  />
                )}
              </ComponentType>
            </CWrapper>
          );
        }
      })}
    </Fragment>
  );
};

ComponentIterator.propTypes = {
  pageName: PropTypes.string,
  components: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

ComponentIterator.defaultProps = {
  components: [],
  pageName: "",
};

export default ComponentIterator;
