import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import Counter from "./Counter";
import Grid from "./Grid";
import TitleTextCtaImage from "./TitleTextCtaImage";

const WhatWeDo = ({ title, htmlText, image, highlight, counters }) => {
  const { items } = counters;
  return (
    <Container className="bg-purple">
      <TitleTextCtaImage
        title={title}
        htmlText={htmlText}
        highlight={highlight}
        image={image}
        layout={{
          imagePositionX: "right",
          imagePositionY: "top",
          imageOrientation: "vertical",
          imageSizeBig: true,
          imageSwitchMobile: true,
          imageSize: "big",
        }}
        style={{
          titleSize: "huge",
          textColor: "white",
          wrappers: {
            htmlText: "pb-24 md:pb-12",
            highlight: "md:pb-32 pt-12 md:pt-0",
          },
        }}
      />
      <div className="flex pt-16 md:pt-0 w-full md:justify-start md:max-w-4xl">
        <Grid className="grid grid-row-gap-medium md:gap-row-gap-small md:grid-three-col">
          {items.map((counter, index) => (
            <div
              className="full-columns mobile-image-partial-layout md:clear-full-columns "
              key={`${counter.number}-${index}`}
              data-hax
            >
              <Counter
                index={index}
                {...counter}
                textColor="white"
                numberColor="blue-light"
                className="max-w-60p"
              />
            </div>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

WhatWeDo.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  highlight: PropTypes.string.isRequired,
  counters: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
      })
    ),
  }).isRequired,
};

WhatWeDo.defaultProps = {};

export default WhatWeDo;
