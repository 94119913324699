import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Gallery from "./Gallery";
import GamesItem from "./GamesItem";
import useOnScreen from "../hooks/useOnScreen";
import DragGallery from "./DragGallery";
import useIsMobile from "../hooks/useIsMobile";

const Games = ({ title, items, showTitle }) => {
  const main = useRef(null);
  const [isIn, setIsIn] = useState(false);
  const [didResize, setDidResize] = useState(false);
  const isMobile = useIsMobile();
  const handleResize = () => {
    setDidResize(!didResize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useOnScreen(
    main,
    (intersecting) => {
      if (intersecting) setIsIn(intersecting);
    },
    "-50%"
  );

  return (
    <div ref={main}>
      {isMobile ? (
        <DragGallery childIsAbsolute={true}>
          {items.map((item, index) => (
            <div className="ml-4 my-6" key={item.id}>
              <GamesItem {...item} last={false} isIn={isIn} index={index} />
            </div>
          ))}
        </DragGallery>
      ) : (
        <Gallery title={title} items={items} showTitle={showTitle}>
          {items.map((item, index) => (
            <GamesItem
              key={item.id}
              {...item}
              last={index === items.length - 1}
              isIn={isIn}
              index={index}
            />
          ))}
        </Gallery>
      )}
    </div>
  );
};

Games.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["small", "big"]),
    color: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    hrefTo: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      hrefTo: PropTypes.string.isRequired,
      button: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        hrefTo: PropTypes.string.isRequired,
        hoverColor: PropTypes.string,
        className: PropTypes.string,
      }),
    })
  ),
  showTitle: PropTypes.bool,
};

Games.defaultProps = {
  title: {
    text: "See all games",
    size: "small",
    color: "white",
    hrefTo: "/games",
    icon: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
      alt: "",
    },
  },
  showTitle: true,
};

export default Games;
