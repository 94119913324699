import React, { useRef, useEffect } from "react";
import { useTransitionState } from "gatsby-plugin-transition-link/hooks";

let body;

const TransitionAware = () => {
  const { mount } = useTransitionState();
  const container = useRef(null);

  useEffect(() => {
    body = document.getElementsByTagName("body")[0];
    body.style.position = "relative";
    if (mount) {
      container.current.style.transform = "translate3d(0, 110vh, 0)";
    } else if (mount === false) {
      container.current.style.transform = "translate3d(0, 0vh, 0)";
    } else {
      container.current.style.transform = "translate3d(0, 110vh, 0)";
    }
  }, [mount]);

  return (
    <div
      style={{
        transform: "none",
        transition: "all 0.6s",
        willChange: "transform",
      }}
      ref={container}
      className={`fixed top-0 left-0 right-0 bg-blue-dark z-50 h-screen`}
    ></div>
  );
};

export default TransitionAware;
