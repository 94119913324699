import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import TitleTextCtaImage from "./TitleTextCtaImage";

const SignUpForAlerts = ({ title, cta, htmlText, image, layout }) => {
  return (
    <div className="bg-yellow">
      <Container>
        <TitleTextCtaImage
          title={title}
          htmlText={htmlText}
          image={image}
          cta={cta}
          layout={{
            imagePositionX: "right",
            imagePositionY: "top",
            imageOrientation: "vertical",
            imageSizeBig: true,
            imageSwitchMobile: true,
            imageSize: "big",
            textSizeBig: true,
            imageTall: true,
            imageHugeMobile: true,
          }}
          style={{
            isFirstTitle: false,
            titleSize: "big",
            textColor: "purple",
            cta: {
              backgroundColor: "green",
              titleColor: "white",
            },
            wrappers: {
              title: "pb-12",
              htmlText: "pb-10",
              image: "pt-20 md:pt-0",
            },
          }}
        />
      </Container>
    </div>
  );
};

SignUpForAlerts.propTypes = {
  title: PropTypes.string.isRequired,
  htmlText: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hrefTo: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  layout: PropTypes.shape({}),
};

SignUpForAlerts.defaultProps = {};

export default SignUpForAlerts;
