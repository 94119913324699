import "array-flat-polyfill";

const flat = (input, depth = 1, stack = []) => {
  for (let item of input) {
    if (item instanceof Array && depth > 0) {
      flat(item, depth - 1, stack);
    } else {
      stack.push(item);
    }
  }

  return stack;
};

export default flat;
